div.editableAccordion {
  width: 100%;
  border-bottom: 1px solid var(--panel-border_transparent);
  gap: 12px;
  padding: 5px 0;
  color: var(--text-grey);
  font-size: var(--font-size-s);

  div.header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    svg {
      -webkit-transition: -webkit-transform 0.3s ease-out;
      -moz-transition:    -moz-transform 0.3s ease-out;
      -o-transition:      -o-transform 0.3s ease-out;
      -ms-transition:     -ms-transform 0.3s ease-out;
      transition:         transform 0.3s ease-out;
      &.rotated {
        transform: rotate(90deg);
      }
    }

    div.headerLabel {
      cursor: pointer;
      display: flex;
      gap: 4px;
      align-items: center;
    }

    div.eyeAndValue {
      font-weight: bold;
      color: white;
      display: flex;
      justify-content: space-between;
      width: 86px;
    }
  }
  div.body {
    position: relative;
    width: 100%;
    padding-left: 24px;
    overflow: auto;
    transition: max-height 0.3s ease-out;

    div.row {
      padding: 8px 0;
      display: flex;
      justify-content: flex-end;
      gap: 8px;
      align-items: center;

      div.rowContent {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .label {
          text-transform: capitalize;
        }

        .editingInput {
          padding: 4px 6px;
          background-color: var(--panel-light);
          width: 50%;

          input {
            text-align: end;
          }

          /* Chrome, Safari, Edge, Opera */
          input::-webkit-outer-spin-button,
          input::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
          }

          /* Firefox */
          input[type=number] {
            -moz-appearance: textfield;
          }
        }

        span.highlighted {
          font-weight: bold;
          color: white;
        }
      }
    }
  }
}
