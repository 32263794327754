.viewButton {
  border: 0;
  border-radius: var(--default-border-radius);
  height: 38px;
  font-size: var(--font-size-xs);
  background-color: var(--table-background);
  color: var(--button-secondary-text-color);
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;

  &.active {
    color: var(--button-secondary-background);
    background-color: var(--button-secondary-text-color);

    &:hover {
      background-color: rgb(222, 211, 211);
    }
  }

  &:not(.active):hover {
    background-color: rgb(30, 27, 27);
  }
}

.viewButton {
  width: 100%;
  padding: 10px
}

.iconContainer {
  padding-right: 5px;
}

.optionsModal {
  background-color: rgb(var(--color-mid-grey));
  box-shadow: 0 3px 6px rgba(0, 0, 0, .5);

  .optionsContainer {
    padding: 6px 0;

    li {
      display: flex;
      align-items: center;
      gap: 8px;
      padding: 6px 10px;
      border-radius: var(--default-border-radius);
      font-size: var(--font-size-xs);
      line-height: var(--font-size-xs);

      > svg {
        width: 16px;
        height: 16px;
      }

      &.active {
        background-color: rgba(255, 255, 255, 0.08);
      }

      &:hover {
        background-color: rgba(255, 255, 255, 0.16);
        cursor: pointer;
      }
    }

    .viewsTitle {
      text-transform: uppercase;
      font-weight: 600;
      color: var(--modal-property-label-color);
      font-size: var(--font-size-xxs);
      line-height: var(--font-size-xxs);
      padding: 10px 0;
      margin-left: 12px;
    }
  }
}
