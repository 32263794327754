.treeInfo {
  position: absolute;
  width: 300px;
  left: 30px;
  top: 60px;
  background: var(--table-background);
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  border-radius: 7px;
  z-index: 1;
  color: var(--text-secondary);
  overflow: hidden;

  .large {
    position: relative;
  }

  .largeCloseButton, .smallCloseButton {
    background-color: var(--table-background);
    position: absolute;
    top: 8px;
    right: 8px;
    cursor: pointer;
    color: white;
    border-radius: 5px;
  }

  .largeCloseButton {
    padding: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .largeCloseButton svg {
    width: 20px;
    height: 20px;
  }

  .smallCloseButton {
    padding: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .smallCloseButton svg {
    height: 16px;
    width: 16px;
  }

  .image {
    height: 180px;
    background-position: center center;
    background-size: cover;
    border-radius: 7px 7px 0 0;
    background-color: rgba(255, 255, 255, 0.05);
    cursor: pointer;
  }

  .imageGallery {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    overflow-x: scroll;
    gap: 4px;
    padding: 16px 20px 10px;
  }

  .imageGallery::-webkit-scrollbar-track {
    display: none;
  }

  .imageGalleryItem {
    background-color: rgba(255, 255, 255, 0.1);
    width: 108px;
    height: 72px;
    border-radius: 2px;
    background-position: center center;
    background-size: cover;
    cursor: pointer;
  }

  .small .imageGallery {
    padding: 0;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
  }

  .mainContainer {
    padding: 12px 20px;
    position: relative;
    color: white;
    background-color: var(--table-background);
  }

  .mainHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 8px;

    .mainExternalId {
      font-size: var(--font-size-m);
      margin: 0;
      font-weight: bold;
    }

    .detailsButton {
      display: flex;
      align-items: center;
      font-size: var(--font-size-s);
      padding: 5px 14px;
      gap: 6px;
      color: white;
      background-color: transparent;
      border-radius: 5px;
      border: 1px solid rgba(255, 255, 255, 0.15);
      cursor: pointer;

      &:hover {
        background-color: rgba(255, 255, 255, 0.05);
      }

      svg {
        width: 16px;
        height: 16px;
      }
    }
  }


  .mainSpecies {
    font-size: var(--font-size-xs);
    line-height: 1.125rem;
    height: 1.125rem;
    font-weight: bold;
    margin-bottom: 8px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  .mainManagedArea {
    font-size: var(--font-size-xs);
    opacity: 0.72;
    line-height: 1.125rem;
    height: 1.125rem;
  }

  .mainCoordinates {
    font-size: var(--font-size-xxs);
    opacity: 0.72;
    line-height: 0.875rem;
    height: 0.875rem;
  }

  .accordion {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 12px 20px;
    background: var(--table-background);
    color: white;
    border: none;
    align-self: flex-start;
    cursor: pointer;
    width: 300px;
    height: 42px;
  }

  section:not(.open):not(:last-child) {
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  }

  .accordion:hover {
    background: var(--accordion-background_hover);
  }

  section.open .panel, section.open .accordion {
    background: var(--accordion-background_open);
  }

  .accordion svg {
    width: 14px;
    height: 14px;
  }

  .accordion div {
    display: flex;
    align-items: center;
    gap: 8px;
  }

  .accordionCaretDown {
    display: block;
  }

  section.open .accordion .accordionCaretDown {
    display: none;
  }

  .accordionCaretUp {
    display: none;
  }

  section.open .accordion .accordionCaretUp {
    display: block;
  }

  .accordionTitle {
    font-style: normal;
    font-weight: 400;
    font-size: var(--font-size-s);
    line-height: 1.125rem;
  }

  .panel {
    max-height: 0;
    overflow: hidden;
  }

  section.open .panel {
    max-height: 100%;
    padding-bottom: 20px;
  }

  .row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 4px;
    font-size: var(--font-size-xs);
    color: white;
    padding: 0 22px 4px 42px;

    .leftContainer {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .propertyValue {
        text-transform: capitalize;
        text-align: right;
      }
    }

    .rightContainer {
      min-width: 35px;
      text-align: left;
    }
  }
}
