.imageContainer {
  height: 100%;
  width: auto;
  cursor: pointer;
  background-position: center;
  background-size: cover;

  .blur {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    backdrop-filter: blur(30px);
  }
}

.image {
  object-fit: contain;
  max-height: 92vh;
  width: 100%;
}

.arrowButton {
  visibility: hidden;
  border: none;
  background: transparent;
  cursor: pointer;
  color: rgba(255, 255, 255, .8);

  &.visible, &:hover {
    visibility: visible;
  }

  svg {
    width: 70px;
    height: 70px;
  }
}
