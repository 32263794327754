.formGroup {
  appearance: none;
  border: none;
  padding: 0;
  margin: 0;
}

.formGroup label {
  display: block;
}

.formGroup label + label {
  margin-top: 25px;
}

.formGroup + .formGroup {
  margin-top: 15px;
}

.col2 {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 25px;
}

.col2 label + label {
  margin-top: 0;
}
