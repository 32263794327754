h2 {
  font-weight: 600;
  font-size: 24px;
  font-family: var(--main-font);
  color: var(--text-primary);
}

.backButton {
  display: flex;
  align-items: center;
  gap: 24px;
  text-decoration: none;
  font-weight: 600;
}

.emptyState {
  background-color: var(--page-background);
  color: var(--text-primary);
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: column;
  column-gap: 12px;
}

.wrapper {
  color: var(--text-primary);
  background-color: var(--page-background);
  height: 100%;
  display: flex;
  flex-direction: column;
}

.title {
  margin: 0;
}

.main {
  display: flex;
  padding: 0 20px 20px 20px;
  column-gap: 20px;
  height: 100%;

  .treeDisplayTileContainer {
    display: flex;
    gap: 20px
  }
}

.viewSelector {
  background-color: var(--details-view-selector-background);
  border-radius: 7px;
  margin: 24px 0 0 0;
  padding: 6px 12px;
  text-align: center;
  display: flex;
  justify-content: space-between;
}

.viewSelectorButton {
  flex: 0 0;
  appearance: none;
  border: none;
  border-radius: var(--default-border-radius);
  cursor: pointer;
  padding: 6px 12px;
  background-color: rgba(0, 0, 0, 0);
  transition: background-color .12s;
  text-transform: uppercase;
  color: inherit;
  font-size: var(--font-size-xs);
  font-weight: bold;
  display: flex;
  flex-flow: column;
  row-gap: 4px;
  align-items: center;
}

.viewSelectorButton:hover, .viewSelectorButton.active {
  background-color: var(--details-view-selector-button_hover);
}

.viewSelectorButton:disabled {
  opacity: 0.5;
  cursor: not-allowed;
  pointer-events: none;
}

.rotatedIcon {
  transform: rotate(90deg);
}

.safetyAlert:after {
  color: var(--text-error);
  content: '!';
  width: 16px;
  height: 16px;
  font-size: var(--font-size-xs);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  border: 1px solid var(--text-error);
}

.detailsGallery {
  padding-left: 0;
  padding-right: 0;
}
