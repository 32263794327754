.mapWrapper {
  position: relative;
  margin: 16px 0 48px 0;

  .mapContainer {
    width: 100%;
    height: 256px;
  }

  .resetButtonContainer {
    z-index: 999;
    position: absolute;
    right: 16px;
    bottom: 110px;
  }
}

.tileButton {
  border: 0;
  width: 38px;
  height: 38px;
  font-size: var(--font-size-xs);
  background-color: var(--table-background);
  color: var(--button-secondary-text-color);
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;

  > svg {
    width: 24px;
    height: 24px;
  }

  &.active {
    color: var(--button-secondary-background);
    background-color: var(--button-secondary-text-color);

    &:hover {
      background-color: rgb(222, 211, 211);
    }
  }

  &:not(.active):hover {
    background-color: rgb(30, 27, 27);
  }
}
