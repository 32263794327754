.label {
  display: flex;
  align-items: center;
  cursor: pointer;
  justify-content: space-between;
}

.text {
  display: flex;
  align-items: center;
}

.checkbox {
  display: none;
}

.track {
  flex: 0 0 48px;
  width: 48px;
  height: 24px;
  display: inline-block;
  border-radius: 24px;
  background-color: var(--switch-track-background);
  position: relative;
  transition: background-color .12s;
}

.active .track {
  background-color: var(--switch-track-background_active);
}

.handle {
  position: absolute;
  top: 4px;
  left: 4px;
  display: inline-block;
  border-radius: 50%;
  width: 16px;
  height: 16px;
  background-color: var(--switch-handle-background);
  transition: background-color .12s, left .24s cubic-bezier(0.87, 0, 0.13, 1);
}

.active .handle {
  left: 28px;
  background-color: var(--switch-handle-background_active);
}

.disabled {
  pointer-events: none;
  opacity: 0.5;
}
