.firstColumn {
  max-width: 250px;
}

.firstColumn > div + div {
  margin-top: 4px;
}

.interests {
  list-style: none;
  margin: 6px 0 0;
  padding: 0;
}

.interests li {
  display: inline-block;
}

.interests li + li {
  margin-left: 6px;
}

.contextMenuTrigger {
  appearance: none;
  border: none;
  background: transparent;
  cursor: pointer;
  color: inherit;
  font-size: var(--font-size-l);
  display: flex;
  align-items: center;
  justify-content: center;
}

.contextMenu {
  background-color: var(--modal-background-color);
  color: var(--text-primary);
  padding: 16px;
  border-radius: var(--default-border-radius);
  border: 1px solid var(--modal-border-color);
  font-size: var(--font-size-m);
  max-width: 80%;
  width: 300px;
  margin: 6px 0 0 auto;
  list-style: none;
}

.contextMenu li + li {
  margin-top: 10px;
}

.contextMenuButton {
  display: block;
  width: 100%;
  border: none;
  background: transparent;
  text-align: left;
  font-size: inherit;
  color: inherit;
  cursor: pointer;
}

.fixedModal {
  position: fixed;
  min-height: 250px;
}
