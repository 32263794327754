.typeSelector {
  width: fit-content;
  position: relative;
  z-index: 2;
}

.selectedType {
  background-color: var(--table-background);
  display: flex;
  align-items: center;
  font-size: var(--font-size-xs);
  font-weight: bold;
  gap: 8px;
  border-radius: var(--default-border-radius);
  border: none;
  color: white;
  padding: 8px;
  cursor: pointer;

  &:hover {
    background-color: rgb(45, 56, 58);
  }

  svg {
    width: 22px;
    height: 18px;
    pointer-events: none;
  }
}

.dropDown {
  position: absolute;
  box-shadow: 0 3px 3px rgba(0,0,0,0.2);
  padding: 10px 12px;
  top: 100%;
  min-width: fit-content;
  font-size: var(--font-size-xs);
  line-height: var(--line-height-s);
  background-color: rgb(var(--color-mid-grey));
  color: white;
  border-radius: var(--default-border-radius);

  &:hover {
    cursor: pointer;
  }
}
