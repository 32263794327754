.superOwnerPage {
  display: flex;
  flex-direction: column;
  background: var(--panel-dark);
  color: white;
  height: 100vh;
  width: 100vw;
  overflow: auto;

  h1 {
    margin-top: 0;
  }

  .backButton {
    display: flex;
    align-items: center;
    margin-top: 20px;
    margin-left: 20px;

    &:hover {
      opacity: .8;
    }
  }

  .tabSelector ul {
    font-size: 1.2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 16px;

    li {
      text-transform: capitalize;
      cursor: pointer;
      padding: 8px 12px;
      border-radius: 5px;
      background-color: var(--panel-light);
    }

    .selectedTab {
      background-color: #408F71;
      text-decoration: underline;
    }
  }

  .title {
    width: 100%;
    text-align: center;
  }

  main {
    margin: 20px;
    padding: 10px;
    border-radius: 10px;

    table {
      background-color: var(--table-background);
    }
  }

  button {
    &:hover {
      opacity: .8;
    }
  }
}
