.container {
  position: relative;
  width: 100%;
  cursor: pointer;
}

.label {
  margin-bottom: 4px;
  &:first-letter {
    text-transform: capitalize;
  }
}

.field {
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding: 10px;
  border: 1px solid var(--panel-border);
  background: transparent;
  border-radius: 3px;

  > svg {
    stroke-width: 3px;
    width: 16px;
    height: 16px;
  }

  input {
    border: none;
    background: transparent;
    max-width: 120px;

    &:focus {
      outline: none;
    }
  }
}

.dropdown {
  opacity: 0.01;
  position: absolute;
  z-index: 12;
  top: 100%;
  left: 0;
  width: 100%;
  max-height: 200px;
  padding: 8px 16px;
  border: 1px solid var(--panel-border);
  border-top: none;
  background: var(--panel-dark);
  border-radius: 0 0 3px 3px;
  overflow-y: auto;
  transition: opacity 1ms ease-in-out;
}

.opacityFull {
  opacity: 1;
}

.dropup {
  top: 0;
  border: 1px solid var(--panel-border);
  transform: translateY(-100%);
}

.dropdownItem {
  padding: 4px 8px;
  &:first-letter {
    text-transform: capitalize;
  }

  &.selected {
    background: var(--input-select-option_focus-background);
  }
}

.dropdownItem:hover {
  background: var(--panel-light);
}

.disabled {
  cursor: default;
  opacity: 0.5;
  pointer-events: none;
}
