.container {
  display: flex;
  width: 100%;
  flex-direction: column;
  position: relative;
  border-radius: var(--panel-border-radius);
  background-color: rgb(var(--color-mid-grey));
  height: 100%;
}

.content {
  border-radius: var(--panel-border-radius);
  overflow: hidden;
  height: 100%;
  width: 100%;
}

.tileButton {
  border-radius: 50%;
  width: 38px;
  height: 38px;
  font-size: var(--font-size-xs);
  background-color: #3F464B;
  border: 1px solid #60737A;
  color: var(--button-secondary-text-color);
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;

  > svg {
    width: 24px;
    height: 24px;
  }

  &.active {
    color: var(--button-secondary-background);
    background-color: var(--button-secondary-text-color);

    &:hover {
      background-color: rgb(222, 211, 211);
    }
  }

  &:not(.active):hover {
    background-color: #383E42;
  }
}

.tileButtonGroup {
  border-radius: 50px;
  background-color: var(--table-background);

  > .tileButton {
    border-radius: unset;

    &:not(:last-of-type) {
      border-bottom: 1px solid rgba(255, 255, 255, .1);
    }

    &:first-of-type {
      border-radius: 50% 50% 0 0;
    }

    &:last-of-type {
      border-radius: 0 0 50% 50%;
    }
  }
}

.buttonsOverlay {
  z-index: 100;
  position: absolute;
  width: 100%;
  height: 100%;
  background: transparent;
  pointer-events: none;
  display: flex;

  .bottomRight {
    align-self: end;
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 9px;
    margin: 0 20px 20px 20px;
  }

  .bottomLeft {
    position: absolute;
    bottom: 20px;
    left: 20px;
  }

  .rightBottom {
    position: absolute;
    bottom: 70px;
    right: 20px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;
    gap: 10px;
  }

  .leftCenter {
    position: absolute;
    bottom: 48%;
    left: 20px;
  }

  .rightCenter {
    position: absolute;
    bottom: 48%;
    right: 20px;
  }

  .topRight {
    position: absolute;
    top: 20px;
    right: 20px;
    display: flex;
    gap: 10px;
  }

  .viewSelector {
    position: absolute;
    bottom: 20px;
    right: 20px;
  }

  .bottomRight, .rightBottom, .viewSelector, .rightCenter, .leftCenter, .topRight, .bottomLeft {
    pointer-events: auto;
  }
}
