.login {
  display: flex;
  width: 100vw;
  height: 100vh;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: var(--panel-dark);
  gap: 80px;
}

.form {
  display: grid;
  width: 80%;
  max-width: 350px;
  grid-auto-columns: auto;
  grid-gap: 15px;
  grid-template-columns: 1fr;
}

.controls {
  display: grid;
  grid-template-columns: auto auto;
  justify-content: space-between;
  grid-gap: 15px;
  font-size: var(--font-size-s);
}

.error {
  color: var(--text-error);
}

.success {
  color: var(--text-success);
}

.languageSelector {
  position: absolute;
  top: 30px;
  right: 40px;
  width: fit-content;
  margin: 0 auto;
  color: white;
}
