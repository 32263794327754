section.historyTrackingWrapper {
  position: relative;
  background: var(--table-background);
  border-radius: 5px;
  margin: 0 12px 12px;
  width: calc(100% - 24px);

  &.closed {
    margin-bottom: 0;
  }

  .dashboard {
    margin: 10px 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    ul {
      margin-top: 5px;
      display: flex;
      gap: 12px;

      li {
        display: flex;
        align-items: center;
        font-size: var(--font-size-s);
        gap: 4px;
        padding: 0 4px;
        border: 1px solid rgba(255, 255, 255, 0.2);
        background: rgba(255, 255, 255, 0.1);
        border-radius: 5px;

        svg {
          vertical-align: bottom;
          width: 20px;
          height: 20px;
        }
      }
    }

    .dashboardButton {
      font-size: var(--font-size-xxs);
      text-transform: uppercase;
      font-weight: bold;
      svg {
        stroke-width: 2px;
        width: 18px;
        height: 18px;
      }
    }
  }
}

div.closed {
  display: none;
}
