.container {
  min-width: 370px;
  max-width: 770px;
  color: white;
  background-color: var(--table-background);
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  white-space: nowrap;
  padding-left: 15px;
  gap: 12px;
}

.centerContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  font-size: var(--font-size-xs);
}

.legendLabel {
  font-weight: bold;
}

.rangesContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 5px 0;
}

.rangeLabel {
  padding: 5px 20px;
  display: flex;
  gap: 5px;
  align-items: center;
  transition: opacity 250ms ease;
  cursor: pointer;
}

.capitalizeFirstLetter:first-letter {
  text-transform: capitalize;
}

.safetyFactor {
  align-items: center;
  display: flex;
  button {
    padding: 8px;
  }
  & > div {
    display: none;
    &.visible {
      display: flex;
      align-items: center;
    }
  }
}

.backButton {
  border-left: 1px solid rgba(255, 255, 255, 0.1);
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.moreButtonContainer {
  position: relative;
}

.moreButton {
  border-left: 1px solid rgba(255, 255, 255, 0.1);
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.backButton svg {
  width: 20px;
  height: 20px;
}

.circle {
  width: 8px;
  height: 8px;
  border-radius: 50%;
}
