.container {
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;

  .ballGridPulse {
    display: flex;
    flex-wrap: wrap;
  }

  .loadingText {
    font-weight: 600;
    font-size: var(--font-size-l);
  }

  .ball {
    flex-shrink: 0;
    animation-delay: 0s;

    animation-fill-mode: both;
    animation-iteration-count: infinite;
    animation-name: ball-grid-pulse;
    border-radius: 100%;
  }

  @keyframes ball-grid-pulse {
    0% {
      transform: scale(1);
    }
    50% {
      opacity: 0.7;
      transform: scale(0.5);
    }
    100% {
      opacity: 1;
      transform: scale(1);
    }
  }
}
