.text {
  font-family: var(--main-font);
  font-size: var(--font-size-xs);
  line-height: 1.5;
  user-select: none;
  white-space: nowrap;
}

.small {
  font-size: var(--font-size-xxs);
}

.medium {
  font-size: var(--font-size-m);
}

.large {
  font-size: 1.125rem;
}

.title {
  font-size: 0.875rem;
  font-weight: 600;
  line-height: 1;
}

.weight-400 {
  font-weight: 400;
}
.weight-500 {
  font-weight: 500;
}
.weight-600 {
  font-weight: 600;
}

.primary {
  color: var(--text-primary);
}
.secondary {
  color: var(--text-secondary);
}
.ternary {
  color: var(--text-ternary);
}
.alternate {
  color: var(--text-alternate);
}
.highlight {
  color: var(--accent-primary);
}
.error {
  color: var(--accent-red);
}

.uppercase {
  text-transform: uppercase;
}

.capitalize {
  text-transform: capitalize;
}

.clickable {
  cursor: pointer;
}

.inline {
  display: inline-flex;
}

.disabled {
  color: var(--text-primary);
  opacity: 0.2;
  pointer-events: none;
}
