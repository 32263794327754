:root {
  --table-dashboard-height: 100px;
}

.container {
  height: 100%;
  position: relative;
  background: var(--table-background);
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
}

.toggleContainer {
  position: absolute;
  transform: translateY(-100%) translateX(-50%);
  left: 50%;
  text-align: center;

  .toggle {
    background-color: var(--table-background);
    color: var(--text-primary);
    font-size: var(--font-size-s);
    transform: translateY(8px);
    user-select: none;
    appearance: none;
    border: none;
    border-radius: var(--default-border-radius);
    padding: 8px 12px;

    &.open {
      background-color: var(--panel-light);
    }

    &:hover {
      cursor: pointer;
      background: linear-gradient(0deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.1)), var(--panel-light);
    }
  }

  .toggleMetadata {
    cursor: pointer;
    padding: 12px 40px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    background-color: var(--table-background);
    color: var(--text-primary);
    font-size: var(--font-size-xs);
    line-height: var(--line-height-s);
  }

  .toggleMetadata[hidden] {
    display: none;
  }
}

.treeListTableContainer {
  position: relative;
  height: 100%;
}

span.highlighted {
  color: var(--table-icon-green);
  font-weight: bold;

  svg {
    height: 1em;
    vertical-align: -0.17em;
  }
}

.treeListTable {
  display: grid;
  grid-template-rows: auto;
  color: var(--text-primary);
  border-collapse: collapse;
  font-size: var(--font-size-xs);
  overflow-x: scroll;
  margin: 0 0 0 50px;
  max-width: calc(100vw - 100px);
  height: calc(100% - var(--table-dashboard-height) - 5px);
  overflow-y: scroll;
  position: relative;
}

.treeListTable.fullWidth {
  max-width: none;
  padding-right: 50px;
}

.headers {
  display: grid;
  background: var(--table-background);
  position: sticky;
  top: 0;
  z-index: 1;
  height: 65px;
}

.header {
  font-weight: 700;
  padding: 12px;
  background-color: rgb(31, 40, 44);
  vertical-align: middle;
  font-size: var(--font-size-xxs);
  border-bottom: 1px solid var(--table-border-color);
  border-left: 1px solid var(--table-border-color);
  min-width: 200px;
  max-height: 65px;
  box-shadow: 0 -8px 0 0 var(--table-background);
}

.selectedHeader {
  background-color: rgba(0, 0, 0, 0.25);
}

.header:first-child {
  box-shadow: -8px -8px 0 0 var(--table-background);
}

.header:nth-child(2) {
  border-left: none !important;
}

.headerPlaceholder[hidden] {
  display: none;
}

.header:first-child {
  border-right: 1px solid var(--table-cell-border-color);
  border-left: none;
  border-top-left-radius: 9px;
  position: sticky;
  left: 0;
  z-index: 1;
}

.header:last-child {
  border-right: none;
  border-top-right-radius: 9px;
}

.functionButton {
  color: var(--color-transparent-white);
  font-size: var(--font-size-xxs);
  border-radius: var(--default-border-radius);
  background-color: transparent;
  font-weight: 700;
}

.functionButton svg {
  width: 1.125rem;
  height: 1.125rem;
  background-color: transparent;
}
.functionButton svg path {
  fill: none !important;
}

.filterDropdown {
  border: none;
  width: fit-content;
  align-items: center;
  gap: 5px;
  margin-right: 2rem;
}

.filterDropdownField {
  border: none;
  width: fit-content;
  align-items: center;
  gap: 5px;
}

.filterDropdownMenu {
  background-color: rgb(50,62,65);
  border: none;
  border-radius: .3rem;
  padding: 12px 8px;
  overflow: visible;
  width: fit-content;
}

.secondaryButtonWithIcon {
  font-weight: 700;
  color: rgb(151, 156, 157);
  font-size: var(--font-size-m);
  padding: 6px 10px 6px 8px;
  border-color: rgb(102,110,112);
}

.secondaryButtonWithIcon svg {
  width: 1.15rem;
  height: 1.15rem;
  margin-right: 4px;
}

.dropdownItem {
  font-size: var(--font-size-s);
}

.body[hidden] {
  visibility: hidden;
}

.pinnedCounter {
  font-size: var(--font-size-xxs);
  display: flex;
  align-items: center;
  gap: 5px;
  margin-top: 9px;
  cursor: pointer;
}

.pinnedCounter svg{
  width: 14px;
  height: 14px;
}

.headerContent {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}

.titleContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-basis: 100%;
  max-width: 100%;
}
.tooltip {
  font-size: var(--font-size-s);
}

.headerTitle {
  color: var(--table-icon-green);
  font-size: var(--font-size-s);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.headerTitlePostFix {
  font-size: var(--font-size-xxs);
}

.headerControls {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex: 1;
  font-size: 0.5rem;
}

.aggregateSelector {
  font-weight: normal !important;
  margin-right: 2px;
}

.aggregatedValue {
  font-size: var(--font-size-xxs);
}

.sortingControl {
  margin-left: 2px;
}


.jumperButton {
  border: none;
  border-radius: var(--default-border-radius);
  opacity: 0.85;
  background-color: white;
  position: absolute;
  right: 16px;
  bottom: 16px;
  width: 28px;
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.jumperButton svg{
  color: black;
  width: 22px;
  height: 22px;
}

.jumperButton:hover {
  opacity: 1;
}

// ADVANCED FILTERING AND COHORT COLORING

.advancedFilteringButton, .cohortColoringButton {
  padding: 5px;
  transform: translateX(15%);
  svg {
    width: 16px;
    height: 16px;
    color: var(--table-icon-green);
    path {
      fill: transparent !important;
    }
  }
  &.active {
    background-color: rgba(39, 185, 136, 0.75);
    svg {
      color: var(--icon-active);
    }
  }
}


.advancedModal {
  background-color: rgb(var(--color-mid-grey));
  width: 175px;
  border-radius: 5px;
  max-height: 400px;

  .multiSelectContainer {
    padding: 10px 14px 8px;
    border-top: 1px solid rgba(255, 255, 255, 0.1);
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    white-space: nowrap;
  }

  .greenTitle, .greenModalTitle {
    padding-bottom: 8px;
    text-transform: uppercase;
    font-weight: 600;
    color: var(--modal-property-label-color);
    font-size: var(--font-size-xxs);
    line-height: var(--font-size-xs);
  }

  .greenModalTitle {
    padding: 15px 15px 7px 15px;
  }

  .sortButton {
    width: 100%;
    flex-direction: row;
    justify-content: flex-start;
    font-size: var(--font-size-xs);
    svg {
      width: 16px;
      height: 16px;
    }
    &:hover {
      background-color: rgba(255, 255, 255, 0.2);
    }
  }
  .selectedSortButton {
    background-color: rgba(255, 255, 255, 0.1);
  }

  .advancedModalButton {
    width: 100%;
    text-align: center;
    font-size: var(--font-size-s);
    padding: 10px 0;
    border: none;
    background-color: transparent;
    color: white;
    cursor: pointer;
    &:hover {
      background-color: rgba(255, 255, 255, 0.1)
    }

    &.applyButton {
      font-weight: bold;
    }

    &.deleteButton {
      color: rgb(var(--color-error));
    }
  }
}

.error {
  margin-top: 10px;
  color: rgb(var(--color-error));
  font-size: var(--font-size-xs);
}
