.tooltip {
  position: relative;
  display: inline-block;
}

.tooltip .tooltiptext {
  visibility: hidden;
  position: absolute;
  background-color: var(--cds-background-inverse);
  color: var(--cds-text-inverse);
  text-align: center;
  padding: 5px 10px;
  border-radius: 2px;
  z-index: 1;
  opacity: 0;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}

.tooltipTop {
  bottom: 125%;
  left: 50%;
  transform: translate(-50%, -25%);
}

.tooltipTop::after {
  content: "";
  position: absolute;
  top: 99%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: var(--cds-background-inverse) transparent transparent transparent;
}

.mapboxTooltip {
  z-index: 9999;
}
.mapboxTooltip > div:nth-child(2) {
  padding: 5px 10px;
  background-color: var(--cds-background-inverse);
  color: var(--cds-text-inverse);
  border-radius: 2px;
}
.mapboxTooltip > div:first-child {
  border-top-color: var(--cds-background-inverse);
  border-width: 5px;
}