.row {
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: 200px;
  cursor: pointer;
  height: 40px;
  background-color: var(--table-background);
}

.row.active {
  background-color: var(--table-hover-background);
  border-radius: var(--default-border-radius);
}

.row.pinned {
  background-color: var(--table-pinned-background);
}

.row.filtered .column > * {
  color: rgba(255, 255, 255, 0.3);
}

.row.filtered {
  cursor: default;
}

.row.triggerRow.first {
  background: linear-gradient(269.99deg, rgba(255, 255, 255, 0.05) 0.01%, rgba(255, 255, 255, 0.2) 99.99%), var(--panel-mid);
  background-size: 200%;
}

.row.triggerRow.second {
  background: linear-gradient(269.99deg, rgba(255, 255, 255, 0.2) 0.01%, rgba(255, 255, 255, 0.05) 51.04%, rgba(255, 255, 255, 0.2) 99.99%), var(--panel-mid);
  background-size: 200%;
}

.row.triggerRow.third {
  background: linear-gradient(269.99deg, rgba(255, 255, 255, 0.2) 0.01%, rgba(255, 255, 255, 0.05) 99.99%), var(--panel-mid);
  background-size: 200%;
}

.row.triggerRow {
  background-size: 200%;
}

.row.triggerRow:last-child .column:first-child {
  border-bottom-left-radius: 5px;
}

.row.triggerRow:last-child .column:last-child {
  border-bottom-right-radius: 5px;
}

.row.triggerRow:first-child .column:first-child {
  border-top-left-radius: 5px;
}

.row.triggerRow:first-child .column:last-child {
  border-top-right-radius: 5px;
}

.row.triggerRow :global {
  animation: moveRows ease 2s infinite;
}

.row:hover {
  background: var(--table-hover-background);
}

.column {
  display: block;
  padding: 12px;
  text-align: right;
  vertical-align: middle;
  height: 40px;
  max-height: 40px;
  border-top: 1px solid var(--table-border-color);
}

.column .firstColumn {
  display: flex;
  justify-content: space-between;
  font-weight: bold;
}

.row:not(:hover) .column:first-child {
  background-color: var(--table-background);
  border-right: 1px solid var(--table-cell-border-color);
}

.row .column:first-child {
  box-shadow: -8px 0 0 0 var(--table-background), 4px 0 8px rgba(0, 0, 0, 0.15);
}

.column:nth-child(2) {
  border-left: none !important;
}

.row:hover .column:first-child {
  border-right: 1px solid var(--table-cell-border-color);
  background-color: var(--table-hover-background);

}

.row:not(:hover) .column:first-child.pinned {
  background-color: var(--table-pinned-background);
}

.row.active:not(:hover) .column:first-child.pinned {
  background-color: var(--table-pinned-background);
}

.row.active:not(:hover) .column:first-child {
  background-color: var(--table-hover-background);
}

.row.active .column:first-child {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  border-top: 1px solid var(--table-selected-row-border-color);
  border-left: 1px solid var(--table-selected-row-border-color);
  border-bottom: 1px solid var(--table-selected-row-border-color);
}

.row.active .column:last-child {
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  border-top: 1px solid var(--table-selected-row-border-color);
  border-right: 1px solid var(--table-selected-row-border-color);
  border-bottom: 1px solid var(--table-selected-row-border-color);
}

.row.active .column {
  border-top: 1px solid var(--table-selected-row-border-color);
  border-bottom: 1px solid var(--table-selected-row-border-color);
}

.row.active +.row .column {
  border-top: none;
}

.column .firstColumn .buttonsContainer {
  display: flex;
  visibility: hidden;
  transform: translateY(-10%);
  cursor: pointer;
}

.column .firstColumn .buttonsContainer div {
  margin: 0 5px;
}

.row:hover .column .firstColumn .buttonsContainer {
  visibility: visible;
}

.pinnedState {
  color: var(--table-pin-color);
  visibility: visible;
}

.column.trigger {
  background: transparent;
}

.column:first-child {
  text-align: left;
  border-left: none;
  position: sticky;
  left: 0;
}

.column:last-child {
  border-right: none;
}

.column + .column,
.header + .header {
  border-left: 1px solid var(--table-cell-border-color);
}
.columnValue {
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 100%;
  display: block;
  overflow: hidden;

  &.italic {
    font-style: italic;
  }
}

.alignLeft {
  text-align: left;
}

.columnPlaceholder[hidden] {
  display: none;
}

@keyframes :global(moveRows) {
  0% { background-position: 0 0 }
  50% { background-position: 100% 0 }
  100% { background-position: 0 0 }
}

.animateHiding {
  height: 40px;
  opacity: 1;
  animation: animateHide .25s ease-in-out;
}

.animateShowing {
  height: 0;
  opacity: 0;
  animation: animateShow .25s ease-in-out;
}

@keyframes animateHide {
  0% {
    height: 40px;
    opacity: 1;
  }
  100% {
    height: 0;
    opacity: 0;
  }
}

@keyframes animateShow {
  0% {
    height: 0;
    opacity: 0;
  }
  100% {
    height: 40px;
    opacity: 1;
  }
}
