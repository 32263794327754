.container {
  display: grid;
  width: 80%;
  max-width: 380px;
  grid-auto-columns: auto;
  grid-gap: 15px;
  grid-template-columns: 1fr;
  color: var(--text-primary);
}

.form {
  border: 1px solid var(--panel-border);
  border-radius: 9px;
  padding: 0 40px 40px;
  box-shadow: var(--auth-panel-shadow);
}

@media only screen and (max-height: 800px) {
  .form {
    margin: 60px 0 20px;
  }
}

.organizationLogo {
  width: 96px;
  height: 96px;
  margin: 0 auto -23px;
  background-color: var(--panel-light);
  border-radius: 50%;
  transform: translateY(-50%);
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  box-shadow: var(--organization-logo-shadow);
}

.title {
  margin: 0;
  font-size: var(--font-size-xl);
  text-align: center;
}

.subTitle {
  font-size: var(--font-size-s);
  text-align: center;
  margin: 8px 0 21px;
}

.largeInput {
  margin-bottom: 8px;
  display: block;
}

.largeInput input {
  padding: 14px 12px;
}

.error {
  font-size: var(--font-size-s);
  color: var(--text-error);
}
