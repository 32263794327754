.container {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.overflowAuto {
  overflow: auto;
}

.overlay {
  position: fixed;
  z-index: 9999;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: var(--modal-overlay-background-color);
  animation: .3s ease-in fadeOverlay;

  &.hideOverlay {
    opacity: 0;
  }
}

@keyframes fadeOverlay {
  0% {
    background-color: transparent;
  }
  100% {
    background-color: var(--modal-overlay-background-color);
  }
}

.title {
  font-weight: bold;
  font-size: var(--font-size-m);
}

.content {
}

.title + .content {
  margin-top: 15px;
}

.footer {
}

.title + .footer {
  margin-top: 15px;
}

.content + .footer {
  margin-top: 15px;
}

.controls {
  display: flex;
  justify-content: flex-end;
  column-gap: 15px;
}
