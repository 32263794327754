.screenContainer {
  margin: auto;

  .toggleContainer {
    display: flex;
    gap: 20px;
    align-items: center;
  }

  .inputContainer {
    padding: 3px;
    font-size: var(--font-size-l);
  }

  .inputContainer, .toggleContainer {
    margin: 18px 0;
  }

  .buttonsContainer {
    display: flex;
    justify-content: space-between;
  }
}

.createNewOrgButton {
  margin-top: 10px;
}
