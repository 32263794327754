@import '../common';

.modal {
  position: absolute;
  max-width: none;
  max-height: none;
  width: fit-content;
  z-index: 99999;
  background-color: var(--modal-background-color);
  color: var(--text-primary);
  border-radius: var(--default-border-radius);
  padding: 0;
  font-size: var(--font-size-m);
  height: fit-content;
  overflow: hidden;
}
