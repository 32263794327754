.infoTabContainer {
  width: fit-content;
  margin: auto;

  .tableContainer {
    overflow-y: auto;
    max-height: 60vh;
  }
}

.manageMembersModal {
  height: 70vh;
  width: 70vw;
}
