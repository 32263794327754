.propertySelector {
  color: white;

  .propertySelectorHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 30px 0 20px 0;

    svg {
      width: 20px;
      height: 20px;
    }

    button {
      padding: 5px;
    }
  }

  .propertyTitle {
    font-size: var(--font-size-l);
    font-weight: bold;
    padding: 0;
  }

  .propertyLabel {
    margin: 5px 0;
  }

  .scaleSelectorContainer {
    position: relative;
    z-index: 9999;
    button{
    font-size: var(--font-size-xxs);
    font-weight: 600;
    }
  }

  .scaleSelectorLabel {
    display: flex;
    font-size: var(--font-size-xs);
    flex-direction: row-reverse;
    margin-bottom: 25px;
    padding: 5px 10px;
    transform: translateX(-10px);

    svg {
      width: 16px;
      height: 16px;
    }
  }

  .scaleSelector {
    display: none;
    position: absolute;
    bottom: 0;
    padding: 5px;
    z-index: 2;
    background-color: rgb(var(--color-mid-grey));
    border-radius: 5px;

    button {
      font-size: var(--font-size-xs);
      padding: 5px;
    }
  }

  .scaleSelector.open {
    display: block;
  }

  .listItemHeader {
    display: flex;
    justify-content: space-between;

    svg {
      width: 16px;
      height: 16px;
    }

    .deleteButton {
      padding: 8px;

      svg {
        height: 16px;
        width: 16px;
      }
    }
  }

  .propertyListItemContainer {
    margin-bottom: 55px;
  }
}

.propertiesModal {
  background-color: rgb(var(--color-mid-grey));
  height: fit-content;
  max-height: 400px;
}

.propertyModalHeader {
  color: white;
  font-size: var(--font-size-xs);
  font-weight: bold;
  padding: 15px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  max-height: 50px;
}

.propertiesModal ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.propertyListTitle {
  text-transform: uppercase;
  font-weight: 600;
  color: var(--modal-property-label-color);
  font-size: var(--font-size-xxs);
  line-height: var(--font-size-xxs);
  padding: 10px 0;
  margin-left: 14px;
}

.propertyListScrollContainer {
  overflow-y: scroll;
  max-height: 350px;

  &::-webkit-scrollbar-track {
    background-color: rgb(var(--color-mid-grey))
  }
}


.propertyListContainer {
  border-bottom: 1px solid var(--panel-border);
  width: 100%;
}

.propertyListContainer:not(:nth-child(2)) .propertyListTitle {
  padding-top: 21px !important;
}

.propertyListContainer:last-child {
  border: none;
}

.propertiesModal ul li {
  font-size: var(--font-size-xs);
  line-height: 1.125rem;
  width: 100%;
  padding: 8px 15px;
  cursor: pointer;
  align-items: center;
  display: flex;
  gap: 15px;
}

.propertiesModal ul li svg {
  width: 18px;
  height: 18px;
}

.placeholder {
  width: 18px;
}

.activeProperty {
  background-color: rgba(255, 255, 255, 0.05);
}

.propertiesModal ul li:hover {
  background-color: var(--analytics-view-selector-button_hover);
}
