.container {
  width: 70%;
  margin: auto;
}

.title {
  line-height: 20px;
  font-size: var(--font-size-l);
}

.description {
  opacity: 0.7;
  font-size: var(--font-size-s);
  line-height: 20px;
  margin-bottom: 40px;
  width: 480px;
}

.groupTitle {
  text-transform: uppercase;
  font-weight: 600;
  color: var(--modal-property-label-color);
  font-size: var(--font-size-xs);
  line-height: var(--font-size-xs);
  padding: 4px 0;
}

.propertyGroup:not(:last-of-type) {
  margin-bottom: 56px;
}

.circle {
  width: 8px;
  height: 8px;
  border-radius: 50%;
}

.row {
  color: var(--color-transparent-white);
  font-size: var(--font-size-s);
  line-height: 20px;
  display: flex;
  justify-content: space-between;
  width: 100%;
  transform: translateX(-8px);
  padding: 8px 12px 8px 12px;
  border-radius: var(--default-border-radius);
  cursor: pointer;
  align-items: center;
}

.rowTitle {
  display: flex;
  gap: 8px;
}

.row:hover {
  background: rgba(255, 255, 255, 0.05);
}

.colorContainer {
  display: flex;
  gap: 8px;
  align-items: center;
}

.colorContainer svg {
  opacity: 0;
  width: 18px;
  height: 18px;
  vertical-align: middle;
}

.row:hover .edit svg {
  opacity: 1;
}

.resetToDefault {
  flex-direction: row;
  gap: 10px;
  padding: 10px 15px;
  transform: translateX(-15px);
  margin-top: 30px;
  font-weight: 400;
  font-size: var(--font-size-s);
  svg {
    width: 16px;
    height: 16px;
    path {
      fill: transparent !important;
    }
  }
}

.editingModalContainer {
  position: fixed;
}

.editingModal {
  width: 440px;
  border: none;
  padding: 30px;
  background-color: rgb(var(--color-mid-grey));
  overflow: hidden;

  input {
    font-size: var(--font-size-xs);
  }

  .extraBottomPadding {
    padding-bottom: 30px;
  }

  .modalContent {
    .modalHeader {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-weight: bold;
      font-size: var(--font-size-l);
      button {
        padding: 5px;
        svg {
          width: 22px;
          height: 22px;
        }
      }
    }

    .propertyDescription {
      font-size: var(--font-size-l);
    }

    .minimum {
      font-size: var(--font-size-xs);
      margin: 15px 0 5px 0;
    }

    .editingRowContainer {
      position: relative;
    }

    .editingRow {
      width: 100%;
      display: flex;
      justify-content: space-between;
      gap: 12px;
      align-items: center;
      margin: 20px 0;
    }
    .circle {
      width: 8px;
      height: 8px;
      border-radius: 50%;
    }
    .rangeValueInput, .rangeLabelInput {
      padding: 6px 8px;
      border: none;
      border-radius: 5px;
      color: white;
      background-color: rgba(255, 255, 255, 0.05);
    }

    .rangeLabelInput {
      width: 216px;
    }

    .rangeValueInput {
      width: 72px;
    }
    .rangeValueInput[disabled] {
      opacity: .6;
      background-color: rgb(var(--color-mid-grey));
    }

    .scaleOrderContainer {
      display: flex;
      white-space: nowrap;
      align-items: center;
      gap: 12px;
      font-size: var(--font-size-s);

      .dropdownPrefix {
        font-weight: bold;
      }

      .dropdown {
        align-items: center;
        gap: 5px;
        border: none;
        width: fit-content;
        svg {
          width: 20px;
        }
      }
      .dropdownMenu {
        border: none;
        width: fit-content;
        padding: 10px;
        border-radius: 5px;
      }
    }

    .deleteButton {
      padding: 7px;
      svg {
        width: 16px;
        height: 16px;
        opacity: .6;
      }
    }

    .addNewButton {
      flex-direction: row;
      padding: 10px;
      margin-top: 20px;
      margin-bottom: 30px;
      font-size: var(--font-size-xs);
    }

    .newRowContainer {
      position: relative;
    }

    .newRow {
      display: none;
      .circle {
        width: 8px;
        height: 8px;
      }
      &.visible {
        display: flex;
        width: 100%;
        gap: 12px;
        align-items: center;
        margin: 10px 0;
      }
    }
    .confirmButton {
      padding: 16px 24px;
      font-size: var(--font-size-m);
    }
    .errorMessage {
      position: absolute;
      bottom: -17px;
      left: 20px;
      color: rgb(var(--color-error));
      font-size: var(--font-size-xs);
    }
    .error {
      padding-top: 10px;
      color: rgb(var(--color-error));
      font-size: var(--font-size-xs);
    }
    .deleteButtonPlaceholder {
      width: 30px;
      height: 23px;
    }
    .modalFooter {
      position: relative;
      .resetToDefault {
        transform: none;
        margin-top: 0;
      }
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    .modalFooter:before {
      content: "";
      width: 150%;
      height: 1px;
      background-color: rgba(255, 255, 255, 0.1);
      position: absolute;
      top: -20px;
      left: -25%;
    }
  }
}
