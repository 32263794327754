.overlay {
  position: absolute;
  z-index: 9999;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  color: #2b0700;
}

.defaultOverlay {
  position: absolute;
  bottom: 0;
}

.selectionContainer {
  display: flex;
  flex-direction: column;
  gap: 5px;
  background-color: var(--panel-mid);
  position: absolute;
  top: 100%;
  left: -65%;
  z-index: 99999;
  width: fit-content;
  white-space: nowrap;
  padding: var(--dropdown-container-padding);
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0,0,0,0.2);
}

.selectionContainer button {
  border: none;
  font-size: var(--font-size-xs);
  color: var(--color-transparent-white);
  background-color: transparent;
  padding: var(--dropdown-button-padding);
  border-radius: var(--default-border-radius);
  width: 100%;
  text-align: left;
}

.selectionContainer button:hover {
  background-color: rgba(255, 255, 255, 0.1);
  cursor: pointer;
}

.downloadContainer {
  position: relative;
}

.functionButton {
  color: var(--color-transparent-white);
  font-size: var(--font-size-xxs);
  background-color: transparent;
  border-radius: var(--default-border-radius);
  font-weight: 700;
}

.functionButton svg {
  width: 1.125rem;
  height: 1.125rem;
  background-color: transparent;
}
