.container {
  position: absolute;
  background: #222D31;
  border: 1px solid rgba(255, 255, 255, 0.15);
  overflow: hidden;
  right: 60px;
  bottom: 120px;
  z-index: 10;

  div {
    width: 140px;
    height: 140px;
    > a {
      margin-top: 5px;
      margin-left: 15px;
    }
  }
}
