.imageCounter {
  justify-self: flex-end;
  display: flex;
  transform: translateX(calc(-40% + 115px));
  gap: 10px;
  width: 60%;

  .marker {
    height: 10px;
    background: transparent;
  }

  .innerMarker {
    transform: translateY(3.5px);
    height: 3px;
    background-color: rgba(255, 255, 255, .6);
    border-radius: 5px;
  }

  .marker:hover {
    cursor: pointer;
    .innerMarker {
      background-color: rgba(var(--accent-tertiary-color), .6);
    }
  }

  .activeMarker {
    background-color: rgb(var(--accent-tertiary-color));
  }
}
