.header {
  width: 70%;
  margin: auto;
  .headerTitle {
    font-size: var(--font-size-xl);
    font-weight: 800;
  }

  .headerDescription {
    font-size: var(--font-size-l);
    font-weight: 400;
  }
}

.divider {
  margin: 32px auto;
  width: 70%;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}
