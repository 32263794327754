.screenContainer {
  width: 60%;

  .toggleContainer {
    display: flex;
    gap: 20px;
    align-items: center;
  }

  .inputContainer {
    padding: 3px;
    font-size: var(--font-size-l);
  }

  .inputContainer, .toggleContainer {
    margin: 18px 0;
  }

  .createButton {
    display: flex;
    align-items: center;
    gap: 5px;
    border: none;
    background-color: rgb(var(--color-default-green));
    padding: 10px 15px;
    margin: 15px 15px 10px 0;
    cursor: pointer;
    border-radius: var(--default-border-radius);
    color: white;
    font-weight: bold;
    font-size: var(--font-size-l);

    &:hover {
      opacity: .8;
    }
  }
}
