.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  .label {
    font-size: var(--font-size-m);
  }

  .dropdownContainer {
    width: fit-content;

    .dropdownMenu {
      border: none;
      width: fit-content;
      padding: 10px;
      border-radius: 5px;
      flex-direction: column;
    }

    .dropdown {
      align-items: center;
      gap: 15px;
      background-color: var(--filter-input-background);
      border-radius: 5px;
      border: none;

      svg {
        width: 20px;
      }
    }
  }
}
