.imageGallery {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  overflow-x: auto;
  gap: 8px;

  .image {
    height: 180px;
    background-position: center center;
    background-size: cover;
    border-radius: 7px 7px 0 0;
    background-color: rgba(255, 255, 255, 0.05);
    cursor: pointer;
  }


  .imageGallery::-webkit-scrollbar-track {
    display: none;
  }

  .imageGalleryItem {
    background-color: rgba(255, 255, 255, 0.1);
    width: 108px;
    height: 72px;
    border-radius: 2px;
    background-position: center center;
    background-size: cover;
    cursor: pointer;
  }

  .small .imageGallery {
    padding: 0;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
  }
}
