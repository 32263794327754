.navBar {
  position: relative;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  width: 100%;
  height: var(--legacy-nav-bar-height);
  align-items: center;
  padding: 0 30px;
  background: var(--page-background);
  justify-content: space-between;
  color: var(--text-primary);
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.navBar.details {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 11;
  background-color: transparent;
  border-bottom: none;
}

.navBar.borderless {
  border-bottom: none;
}

.rightContainer {
  display: flex;
  gap: 20px;
  justify-content: flex-end;
  align-items: center;
}

.leftContainer {
  display: flex;
  align-items: center;
  gap: 30px;
}

.centerContainer {
  height: 100%;
  align-items: center;
  display: flex;
  justify-content: center;
}

.homeButton svg {
  width: 20px;
  height: 20px;
}

.homeButton {
  padding: 10px;
}

.homeButton svg path {
  fill: transparent !important;
}
