.container {
  display: flex;
  flex-flow: column;
  height: 100%;
}

.data {
  position: relative;
  z-index: 2;
  font-size: var(--font-size-m);
  width: 80%;
  max-width: 600px;
  display: flex;
  column-gap: 12px;
  margin: 0 auto;
}

.data > div {
  flex: 0 0 50%;
}

.row {
  display: flex;
  column-gap: 12px;
  justify-content: space-between;
}

.row + .row {
  margin-top: 4px;
}

.value {
  flex: 0 0 58%;
  position: relative;
}

.value[data-tooltip]:before {
  content: attr(data-tooltip);
  position: absolute;
  top: -3px;
  left: 50%;
  transform: translate(-50%, -100%);
  max-width: 200px;
  width: 100vw;
  z-index: 2;
  padding: 8px;
  font-size: var(--font-size-xs);
  background-color: var(--panel-dark);
  border: 1px solid var(--panel-border);
  border-radius: var(--default-border-radius);
  pointer-events: none;
  opacity: 0;
  transition: opacity .12s linear;
}

.value[data-tooltip]:hover::before {
  opacity: 1;
}

.id {
  display: inline-block;
  max-width: 125px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.content {
  position: relative;
  flex: 1 1 100%;
}
