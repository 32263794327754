.searchHints {
  color: white;
}

.titleRow {
  display: flex;
  padding: 15px 15px 8px 15px;
}

.iconContainer {
  padding-right: 8px;
}

.hintList {
  padding: 0 26px 8px 26px;
}

.hintItem {
  padding: 6px;
  display: flex;
  align-items: center;
  font-size: var(--font-size-m);
  gap: 15px;
  &:hover {
    cursor: pointer;
    background: rgba(255, 255, 255, 0.1);
    border-radius: 3px;
  }
  .managedAreaCode {
    font-size: var(--font-size-s);
    color: var(--text-ternary);
    display: flex;
    align-items: center;
  }
  .inlineIcon {
    margin: 0 12px;
  }

  &.highlighted {
    outline: 1px solid rgba(255, 255, 255, 0.5);
    background-color: rgba(255, 255, 255, 0.05);
  }
}
