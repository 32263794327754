.toggleContainer {
  display: flex;
  align-items: center;
  gap: 8px;
  z-index: 100;
  position: absolute;
  transform: translateY(-100%) translateX(-50%);
  left: 50%;
  text-align: center;
  cursor: pointer;
  padding: 8px 12px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  background-color: var(--table-background);
  color: var(--text-primary);
  font-size: var(--font-size-s);
}
