/*TREE MARKERS*/

:root {
  --current-background: 17, 160, 107;
}
.label {
  position: absolute;
  font-weight: 600;
  left: 50%;
  text-shadow: 0 2px 4px rgba(49, 61, 65, 0.15);
  transform: translate(-50%, 10%);
}

/*default state*/

.markerDefault {
  position: relative;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  transform: translateY(-30%);
  border: 2px solid rgba(237, 244, 242, 1);
  cursor: pointer;
}

.markerDefault:after {
  content: '';
  position: absolute;
  bottom: -44%;
  left: 25%;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 6px 4px 0 4px;
  border-color: rgba(237, 244, 242, 1) transparent transparent transparent;
  pointer-events: none;
}

/*hover states*/

.markerDefault:hover {
  position: relative;
  width: 30px;
  height: 30px;
  transform: translate(calc(-30% + 5px), -5px);
  border: 2px solid rgba(237, 244, 242, 1);
  transition: all .2s ease-in-out;
  cursor: pointer;
}

.markerDefault:hover:after {
  content: '';
  position: absolute;
  bottom: 50%;
  left: 50%;
  transform: translate(-50%, 50%);
  border-radius: 50%;
  background-color: rgba(17, 160, 107, 1);
  width: 6px;
  height: 6px;
  border-style: none;
}

.markerDefault.coloredMarker:hover:after, .markerSelected.coloredMarker:before {
  background-color: white;
}

/*CLICK STATE*/

.markerDefault:active {
  position: relative;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  transform: translateY(-30%);
  border: 3px solid rgba(237, 244, 242, 1);
  background-color: #EDF4F2;
  cursor: grabbing;
  transition: none;
}

.markerDefault:active:after {
  content: '';
  position: absolute;
  bottom: 50%;
  left: 50%;
  transform: translate(-50%, 50%);
  border-radius: 50%;
  background-color: rgba(17, 160, 107, 1);
  width: 6px;
  height: 6px;
}

.markerDefault:active:before {
  content: '';
  position: absolute;
  bottom: -58%;
  left: 23%;
  width: 0;
  transform: none;
  height: 0;
  border-style: solid;
  border-width: 6px 4px 0 4px;
  border-color: white transparent transparent transparent;
}

/*SELECTED STATE*/

.markerSelected {
  position: relative;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  transform: translateY(-30%);
  border: 2px solid rgba(237, 244, 242, 1);
  cursor: pointer;
  transition: box-shadow .3s ease-in-out;
}

.markerSelected:before {
  content: '';
  position: absolute;
  bottom: 50%;
  left: 50%;
  transform: translate(-50%, 50%);
  border-radius: 50%;
  background-color: rgba(17, 160, 107, 1);
  width: 6px;
  height: 6px;
}

.markerSelected:after {
  content: '';
  position: absolute;
  bottom: -44%;
  left: 25%;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 6px 4px 0 4px;
  border-color: rgba(237, 244, 242, 1) transparent transparent transparent;
}

/*DISABLED STATE*/

.markerDisabled {
  position: relative;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  transform: translateY(-30%);
  background-color: white;
  cursor: pointer;
  opacity: 0.2;
}

.markerDisabled:after {
  content: '';
  position: absolute;
  bottom: -22%;
  left: 31%;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 6px 4px 0 4px;
  border-color: white transparent transparent transparent;
  cursor: pointer;
}

.markerHidden {
  visibility: hidden;
}

/*COLORS*/

.markerColorWhite {
  background-color: white;
}

/*BOX SHADOW*/

.markerShadow {
  box-shadow: 0 0 0 10px rgba(var(--current-background), 0.4);
}

/*END OF TREE MARKERS*/

.mapMarkerContainer {
  width: 24px;
  text-align: center;

  &.decreasedOpacity {
    opacity: 0.3;
  }
}

.mapMarkerContainer:hover {
  z-index: 2000 !important;
}

.mapMarkerContainer .label {
  color: white;
}

.mapMarker {
  cursor: pointer;
}

.mapMarker img {
  width: 100%;
}
