::-webkit-scrollbar {
  width: 5px !important;
  height: 5px !important;
}

/* Track */
::-webkit-scrollbar-track {
  background: var(--cds-background) !important;
  background-clip: padding-box !important;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--cds-border-strong) !important;
  background-clip: padding-box !important;
  border-radius: 6px !important;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #aeaeae !important;
  background-clip: padding-box !important;
  border-radius: 6px !important;
}

::-webkit-scrollbar-corner {
  background: rgba(0,0,0,0) !important;
}

.scrollbar-width-none {
  scrollbar-width: none !important;
}
