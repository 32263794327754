.mapStyleSelectorContainer {
  background-color: var(--table-background);
  position: relative;
  overflow: visible;
  cursor: pointer;
  border-radius: var(--default-border-radius);
  margin: 10px;
  display: flex;
  color: white;
  white-space: nowrap;

  .greenTitle {
    text-transform: uppercase;
    font-weight: 600;
    color: var(--modal-property-label-color);
    font-size: var(--font-size-xxs);
    padding: 15px 14px 8px;
  }

  .currentView {
    cursor: pointer;
    display: flex;
    gap: 8px;
    align-items: center;
    height: 100%;
    margin: auto;
    color: white;
    padding: 0 10px;
    background-color: transparent;
    border: none;
    font-size: var(--font-size-s);
    border-radius: var(--default-border-radius);
    svg {
      width: 20px;
      height: 20px;
    }
    &:hover {
      background-color: #434c50;
      transition: background-color .2s ease-in;
    }
  }

  .dropupContainer {
    overflow: hidden;
    border-radius: var(--default-border-radius);
    position: absolute;
    background-color: rgb(var(--color-mid-grey));
    top: 0;
    left: 0;
    transform: translateY(-100%);
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.2);
    min-width: 150px;
    max-width: 200px;
    .viewOption {
      display: flex;
      align-items: center;
      gap: 8px;
      padding: 8px 12px;
      border: none;
      background-color: transparent;
      color: white;
      cursor: pointer;
      width: 100%;
      border-radius: var(--default-border-radius);
      font-size: var(--font-size-xs);
      &:hover {
        background-color: rgba(255, 255, 255, 0.1);
      }
      &.disabled {
        opacity: .4;
      }
      svg {
        width: 16px;
        height: 16px;
      }
      &:not(:only-child):last-child {
        border-radius: 0;
        border-top: 1px solid rgba(255, 255, 255, 0.1);
      }
    }
  }
}
