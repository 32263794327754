.profileButton {
  display: flex;
  align-items: center;
  border: none;
  appearance: none;
  background-color: transparent;
  outline: none;
  cursor: pointer;
}

.profileMenuLogo {
  color: var(--text-primary);
  font-size: var(--font-size-m);
  font-weight: bold;
  border-radius: 50%;
  border: 1px solid #EDF4F2;
  width: 35px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--panel-light);
  background-position: center center;
  background-size: contain;
  background-repeat: no-repeat;
}

.userProfile {
  padding: 15px;
  border-bottom: 1px solid var(--panel-border);
}

.userName {
  display: grid;
  grid-template-columns: 1fr auto;
  grid-column-gap: 15px;
  font-size: var(--font-size-xs);
}

.userIcon {
  display: flex;
  justify-content: center;
  align-items: center;
}

.userEmail {
  color: var(--text-secondary);
  margin-top: 5px;
  font-size: var(--font-size-s);
}

ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.divider {
  height: 1px;
  background-color: var(--panel-border);
}

.profileMenuButton {
  border: none;
  color: var(--text-primary);
  cursor: pointer;
  background: transparent;
  width: 100%;
  appearance: none;
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 10px 16px;
  transition: background-color .12s;
  text-decoration: none;
}

.profileMenuButton:hover {
  background-color: var(--panel-light);
}

.profileMenuButton svg {
  height: 14px;
  width: 14px;
}

.profileMenuLabel {
  font-size: var(--font-size-xs);
}
