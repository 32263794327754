.listDashboardContainer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 35px 60px;
  gap: 10px;
  max-height: 100px;
}

.dashboardMetadata {
  flex: 1;
  color: var(--text-primary);
  font-size: var(--font-size-l);
  line-height: var(--font-size-xl);
}

.advancedSummaryButton {
  cursor: pointer;
}

.advancedSummaryModal {
  overflow-x: visible !important;
  border: none;
  background-color: rgb(var(--color-mid-grey));
  width: 362px;
  max-height: 600px;
  overflow-y: scroll !important;
  padding: 0;
  &::-webkit-scrollbar {
    display: none;
  }
}

.functionButton {
  color: var(--color-transparent-white);
  font-size: var(--font-size-xxs);
  border-radius: var(--default-border-radius);
  background-color: transparent;
  font-weight: 700;
}

.functionButton svg {
  width: 1.125rem;
  height: 1.125rem;
  background-color: transparent;
}
.functionButton svg path {
  fill: none !important;
}

.mapAnalyticsToggle {
  width: 160px;
  font-size: var(--font-size-s);
}
