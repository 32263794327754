.container {
  height: 100%;
  display: flex;
  flex-flow: column;
  row-gap: 12px;
  color: var(--text-primary);
}

.title {
  text-transform: uppercase;
}

.previews {
  flex: 1 1 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-auto-rows: 100%;
  column-gap: 6px;
}

.embededContainer .previews {
  display: flex;
  width: 100%;
  justify-content: center;
  max-height: 100px;
}

.preview {
  border-radius: 4px;
  max-height: 82px;
  background-size: cover;
  background-position: center center;
  cursor: pointer;
  filter: #{"grayscale()"};
}

.embededPreviewContainer {
  padding: 4px;
  border-radius: 3px;
  background-color: rgba(0, 0, 0, 0);
  transition: background-color .12s;
  display: flex;
  flex-flow: column;
}

.embededPreviewContainer.active {
  background-color: rgba(var(--panel-row_background_rgb), 0.8);
}

.embededPreviewContainer:hover {
  background-color: var(--panel-border_transparent)
}

.embededContainer .preview {
  flex: 1 1 100%;
  width: 60px;
}

.preview.active {
  filter: none;
}

.additionals {
  border-radius: 4px;
  max-height: 82px;
  background-color: var(--text-alternate);
  display: flex;
  justify-content: center;
  align-items: center;
  user-select: none;
  cursor: pointer;
}

.full {
  z-index: 100;
  background-color: var(--page-background);
  position: fixed;
  left: 0;
  top: var(--legacy-nav-bar-height);
  right: 0;
  bottom: 0;
  color: var(--text-primary);
  font-size: var(--font-size-m);
}

.fullContainer {
  padding: 30px;
  display: flex;
  flex-flow: column;
  height: 100%;
}

.embededContainer {
  height: 100%;
  display: flex;
  flex-flow: column;
  font-size: var(--font-size-m);
}

.closeContainer {
  text-align: right;
  padding-bottom: 6px;
}

.close {
  appearance: none;
  outline: none;
  background: transparent;
  border: none;
  font-size: var(--font-size-xl);
  color: inherit;
  cursor: pointer;
}

.content {
  flex: 1 1 100%;
  display: flex;
  flex-flow: column;
  height: 100%;
  overflow: hidden;
}

.imageAndControlsContainer {
  height: 100%;
  display: flex;
  column-gap: 12px;
}

.controlContainer {
  flex: 1 1 10%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.controlContainer:first-child {
  justify-content: flex-end;
}

.control {
  appearance: none;
  outline: none;
  font-size: var(--font-size-m);
  width: 24px;
  height: 24px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: inherit;
  background-color: var(--text-alternate);
  border: none;
  cursor: pointer;
}

.imageContainer {
  height: 100%;
  max-width: 80vw;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.embededContainer .imageContainer {
  border-radius: var(--default-border-radius);
}

.imageContainer.zoomed {
  overflow: auto;
}

.imageContainer.zoomed::-webkit-scrollbar {
  display: none;
}

.image {
  max-height: 100%;
  max-width: 100%;
}

.image.zoomed {
  max-width: unset;
  max-height: unset;
}

.caption {
  text-align: center;
  padding: 12px 0;
  margin-bottom: 32px;
}

.embededContainer .caption {
  margin-bottom: 12px;
}

.groups {
  display: flex;
  justify-content: center;
  font-size: var(--font-size-s);
  color: var(--text-faded);
}

.groupedPreviews {
  display: flex;
  column-gap: 2px;
}

.group {
  border-right: 1px dashed var(--panel-border_transparent);
  padding: 0 12px;
}

.group:last-child {
  border: none;
}

.group .preview {
  width: 3vw;
  height: 5vw;
  min-width: 50px;
  min-height: 52px;
  max-width: 60px;
  max-height: 78px;
}

.groupedPreviewContainer {
  cursor: pointer;
  padding: 4px;
  border-radius: 3px;
  background-color: rgba(0, 0, 0, 0);
  transition: background-color .12s;
}

.groupedPreviewContainer.active {
  background-color: rgba(var(--panel-row_background_rgb), 0.8);
}

.groupedPreviewContainer:hover {
  background-color: var(--panel-border_transparent)
}

.groupedPreviewCaption {
  margin-top: 6px;
  text-align: center;
}

.groupTitle {
  font-weight: bold;
  margin-bottom: 10px;
}

.embededPreviewCaption {
  margin-top: 6px;
  text-align: center;
}
