@import '../common';

.modal {
  position: relative;
  z-index: 99999;
  background-color: var(--cds-background);
  color: var(--text-primary);
  font-size: var(--font-size-m);
  width: 500px;
  max-width: 90%;
  max-height: 90%;
}

.size-fullScreen {
  max-width: none;
  max-height: none;
  width: calc(100vw - 60px);
  height: calc(100vh - 30px);
  position: absolute;
  bottom: 0;
  border-bottom: none;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
