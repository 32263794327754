.container {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.overlay {
  position: fixed;
  z-index: 9999;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: var(--modal-overlay-background-color);
  animation: .3s ease-in fadeOverlay;

  &.hideOverlay {
    opacity: 0;
  }
}

.content {
  z-index: 9999;
  max-width: 300px;
  padding: 25px;
  text-align: center;
  color: white;
  background: rgb(var(--color-mid-grey));
  border-radius: var(--default-border-radius);

  .controls {
    margin-top: 25px;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    gap: 10px;

    button {
      color: white;
      border: 1px solid rgba(255, 255, 255, 0.1);
      border-radius: var(--default-border-radius);
      padding: 6px 12px;
      cursor: pointer;

      &:first-of-type {
        background: rgb(var(--color-error));

        &.successButton {
          background: var(--button-primary-background);
        }
      }

      &:nth-of-type(2) {
        color: rgba(255, 255, 255, 0.8);
        background: transparent;
      }
    }
  }
}

@keyframes fadeOverlay {
  0% {
    background-color: transparent;
  }
  100% {
    background-color: var(--modal-overlay-background-color);
  }
}
