$spinner-bg: rgba(255, 255, 255, 0.2);
$spinner-color: #CED7D4;
$rotate-speed: 960ms;

.spinner {
  top: 50%;
  left: 50%;
  border-radius: 50%;
  border-color: $spinner-bg;
  border-style: solid;
  border-bottom-color: $spinner-color;
  animation: rotateSpinner $rotate-speed linear infinite;
}

.overlay {
  position: absolute;
  z-index: 9999;
  top: var(--legacy-nav-bar-height);
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(27, 36, 39, 0.8);
}

@keyframes rotateSpinner {
  to {
    transform: rotate(360deg);
  }
}
