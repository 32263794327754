.orgContainer {
    border-bottom: 1px solid var(--panel-border);
}

.list {
    list-style-type: none;
    padding: 0;
    margin: 0;
    overflow: auto;
    max-height: 40vh;
}

.list li {
    padding: 10px 16px;
    border-radius: var(--default-border-radius);
}

.list li a {
    text-decoration: none;
}

.list li:hover {
    background-color: var(--panel-light);
}

.activeAccount {
    background-color: var(--panel-light);
}

.currentOrg {
    padding: 10px 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    border-radius: var(--default-border-radius);
}

.caretBtn {
    background: transparent;
    cursor: pointer;
    width: 18px;
    height: 18px;
}

.selectedOrganizationLogo {
    display: inline-block;
    border-radius: 50%;
    width: 25px;
    height: 25px;
    background-color: var(--panel-light);
    background-position: center center;
    background-size: contain;
    background-repeat: no-repeat;
}

.organization {
    display: flex;
    align-items: center;
    gap: 10px;
    font-size: var(--font-size-xs);
}

.orgName {
    text-decoration: none !important;
    border-bottom: none;
}
