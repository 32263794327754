.filterSearchBar {
  display: flex;
  gap: 10px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.6);
  .backButton {
    padding: 5px;
    margin: 5px;
    svg {
      width: 24px;
      height: 24px;
    }
  }
  .saveIcon {
    padding: 5px;
    margin: 5px;
    svg {
      width: 20px;
      height: 20px;
    }

  }

  .input {
    border: none;
    padding: 0;
    font-size: var(--font-size-l);
  }
}

.speciesSelector {
  color: white;

  .speciesLabel {
    font-size: var(--font-size-l);
    font-weight: bold;
    padding: 0;
  }
  .speciesSelectorBar, .speciesListItem {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .speciesSelectorBar {
    padding: 30px 0 5px 0;
    svg {
      width: 20px;
      height: 20px;
    }
    button {
      padding: 5px;
    }
  }

  .speciesListItem {
    font-size: var(--font-size-s);
    button { padding: 6px; }
    svg {
      width: 18px;
      height: 18px;
    }
  }
}

.speciesModal {
  width: 250px;
  background-color: rgb(var(--color-mid-grey));
  max-height: 400px;

  .speciesModalHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 16px;
  }
  .speciesModalHeader span:nth-child(1) {
    font-size: var(--font-size-xs);
    font-weight: bold;
  }
  .speciesModalHeader span:nth-child(2) {
    font-size: var(--font-size-xxs);
  }

  .speciesModalSearchContainer {
    max-height: 350px;
    overflow-y: scroll;
    padding: 0 15px;

    .speciesItem {
      cursor: pointer;
      border-radius: 5px;
      display: flex;
      align-items: center;
      gap: 6px;
      padding: 5px 8px;
      opacity: 0.8;
      svg, .placeholder {
        width: 16px;
        height: 16px;
      }
      &:last-of-type {
        margin-bottom: 10px;
      }
    }
    .selectedSpecies, .speciesItem:hover {
      background-color: rgba(255, 255, 255, 0.1);
    }
  }

  .speciesModalSearchContainer::-webkit-scrollbar-track {
    background-color: rgb(var(--color-mid-grey));
  }

  .speciesModalSearchContainer::-webkit-scrollbar-thumb {
    background-color: rgba(255, 255, 255, 0.2);
  }

  .speciesModalSearchInput {
    padding: 5px 10px;
    background-color: var(--table-background);
    margin-bottom: 10px;
  }
}

.dividerLine {
  margin-top: 25px;
  width: 120%;
  transform: translateX(-10%);
  height: 1px;
  background-color: rgba(255, 255, 255, 0.1);
}

.modal {
  width: 300px;
  border: none;
  padding: 30px;
  border-radius: 9px;
  background-color: rgb(var(--color-mid-grey));
}

.modalTitle {
  font-size: var(--font-size-l);
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.modalTitle button {
  padding: 3px;
}

.modalTitle svg {
  width: 18px;
  height: 18px;
}

.modalDescription {
  font-size: var(--font-size-s);
  margin: 15px 0 20px 0;
}

.modalControls {
  display: flex;
  justify-content: space-between;
  font-size: var(--font-size-s);
}

.modalControls button {
  color: white;
  border: none;
  border-radius: 5px;
  padding: 5px 10px;
  background-color: transparent;
}

.modalControls button:hover {
  background-color: rgba(255, 255, 255, 0.1);
  cursor: pointer;
}

.modalControls button {
  display: flex;
  align-items: center;
  gap: 8px;

  &.overwriteButton {
    color: rgb(var(--color-error));
  }

  &.disabled {
    opacity: .5;
  }
}

.overwriteButton svg {
  width: 16px;
  height: 16px;
}

.speciesEditButton {
  svg {
    width: 20px;
    height: 20px;
  }
  button {
    padding: 5px;
  }
}

.privateToggleContainer {
  margin-top: 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: white;
  font-size: var(--font-size-m);
}
