.modal {
  border: none;
  padding: 0;
  width: calc(min(118vh, 83vw));

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    padding: 20px 25px;

    .title {
      margin: 0;
      font-size: var(--font-size-l);
    }

    .closeButton {
      padding: 4px;

      svg {
        stroke-width: 2px;
        width: 24px;
        height: 24px;
      }
    }
  }

  .content {
    padding: 30px;
    height: calc(100% - 73px);
    overflow-y: auto;

    .radioButtonRow {
      display: flex;
      justify-content: space-between;

      .radioButtonsOnTheLeft {
        display: flex;
        gap: 24px;
      }
    }
  }
}

.chartContainer {
  margin-top: 48px;
}
