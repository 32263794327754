
.northPointer {
  width: 26px;
  height: 26px;
  background-color: rgb(var(--color-mid-grey));
  font-size: var(--font-size-m);
  border-radius: 50%;
  border: 1px solid white;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
}

.badgeAndSliderContainer {
  position: absolute;
  display: flex;
  gap: 17px;
  left: 8px;
  top: 54px;
  width: 100%;
}

.windSpeedSliderContainer {
  background-color: rgb(var(--color-mid-grey));
  border: 1px solid var(--panel-border);
  border-radius: var(--default-border-radius);
  padding: 14px;
  z-index: 100;
  width: fit-content;
}

.sliderHeader {
  font-size: var(--font-size-m);
  text-align: center;
  text-shadow: 0 0 4px var(--page-background);
  display: flex;
  margin-bottom: 10px;
}

.sliderTitle {
  white-space: nowrap;
  display: flex;
  font-size: var(--font-size-m);
  align-items: center;
}

.sliderLabels {
  display: flex;
  justify-content: space-between;
  margin-bottom: 6px;
  user-select: none;
  pointer-events: none;
  font-size: var(--font-size-m);
  margin-top: 5px;
}

.windSpeedContainer {
  padding: 2px 4px;
  width: 40px;
  border-radius: var(--default-border-radius);
  background-color: rgba(74, 84, 88, 1);
  box-shadow: 0 0 4px var(--page-background);
  margin: 0 6px;
}

.centroidLegends {
  position: absolute;
  background-color: rgb(var(--color-mid-grey));
  border: 2px solid var(--panel-border);
  border-radius: var(--default-border-radius);
  padding: 7px 11px;
  top: 54px;
  right: 8px;

  li {
    font-size: var(--font-size-xs);
    display: flex;
    align-items: center;
    gap: 6px;
    padding: 6px 0;
  }

  .pinkCircle, .cyanCircle, .greenCircle {
    display: block;
    width: 12px;
    height: 12px;
    border-radius: 50%;
  }

  .pinkCircle {
    background-color: #EA42E3;
  }

  .cyanCircle {
    background-color: #68CBFA;
  }

  .greenCircle {
    background-color: #7BFC4C;
  }
}

.propertyList {
  list-style: none;
  position: relative;
  z-index: 999;
  margin: 8px 0 0 8px;
  padding: 0;
  text-align: left;
  font-size: var(--font-size-m);
  width: fit-content;
}

.propertyListItem {
  display: inline-block;
  margin-bottom: 8px;
}

.propertyListItem + .propertyListItem {
  margin-left: 12px;
}

.propertyListItemButton {
  appearance: none;
  font-size: inherit;
  color: inherit;
  cursor: pointer;
  border: 1px solid var(--panel-border_transparent);
  border-radius: var(--default-border-radius);
  padding: 8px 10px;
  background-color: var(--page-background);
  transition: border-color .12s;
}

.propertyListItemButton.active {
  background-color: var(--panel-border_transparent);
}

.propertyListItemButton:hover {
  background-color: var(--panel-border);
  border-color: var(--panel-border);
}

.propertyListItemButton.disabled {
  pointer-events: none;
  opacity: 0.5;
  cursor: not-allowed;
}

.safetyFactorBadge {
  text-align: center;
  z-index: 11;
  border-radius: 5px;
  background-color: var(--page-background);
  color: #408F71;
  border: 2px solid #408F71;
  padding: 10px;
  width: 120px;
  height: 105px;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
}

.safetyFactorBadge span:first-child {
  font-size: var(--font-size-m);
  color: var(--button-primary-text-color);
  margin-bottom: 15px;
}

.safetyFactorBadge span:last-child {
  font-size: var(--font-size-xl);
  justify-content: flex-end;
  font-weight: 500;
}

.safetyFactorBadgeDanger {
  color: var(--text-error);
  border: 2px solid var(--text-error);
}

.streetViewRiskOverlayContainer {
  position: absolute;
  width: calc(100% - 24px);
  height: calc(100% - 24px);
  top: 12px;
  left: 12px;
}
