.infoTable {
  thead {
    position: sticky;
    top: 0;
    font-size: var(--font-size-l);
    background-color: #408F71;
    display: table-row-group;

    .button {
      appearance: none;
      color: inherit;
      background: transparent;
      border: none;
      outline: none;
      cursor: pointer;
      display: flex;
      align-items: center;
      text-align: left;
    }

    .button svg path {
      stroke: var(--text-faded);
    }

    .icons {
      margin-left: 10px;
    }

    .icons div + div {
      margin-top: -6px;
      margin-left: -0.5px;
    }
  }

  table {
    border-collapse: collapse;
    text-align: center;
    margin-bottom: 35px;

    th, td, .th {
      border: 1px solid var(--table-border-color);
      padding: 10px;
      display: table-cell;
      font-weight: normal;
    }

    tbody td {
      font-size: var(--font-size-m);
    }
  }

  .orgIdCell {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 8px;

    button {
      margin: 0;
      padding: 8px;
    }
  }

  .manageMembersButton {
    margin: auto;
  }

  .addMeButton {
    display: flex;
    margin: 0;
    padding: 5px;
    svg {
      stroke-width: 2px;
    }
  }
}
