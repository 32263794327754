.container {
  height: 100vh;
  width: 100vw;
  color: white;
  background: var(--panel-dark);
  overflow: auto;

  .backButton {
    display: flex;
    align-items: center;
    margin-top: 20px;
    margin-left: 20px;

    &:hover {
      opacity: .8;
    }
  }

  h1 {
    text-align: center;
    margin-bottom: 30px;
  }

  h3 {
    margin: 0;
    font-weight: 500;
  }

  .languageSelector {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 24px;

    button {
      border: 1px solid rgba(255, 255, 255, 0.2);
    }

    .selected {
      background: var(--accent-green-border);
    }
  }

  .headerContainer {
    display: flex;
    width: 80%;
    margin: auto;

    div {
      flex-basis: 33%;
    }

    .buttonContainer {
      margin-bottom: 30px;
      display: flex;
      align-items: end;
      justify-content: end;
    }
  }

  .infoContainer {
    margin: 30px 0;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;

    & > div {
      margin: 8px 0;
    }

    button {
      border: 1px solid rgba(255, 255, 255, 0.2);
    }
  }

  ol {
    padding: 0;
    margin: 0 auto;
    width: 80%;
    border: 1px solid rgba(255, 255, 255, 0.2);

    .row {
      display: flex;
      align-items: center;
      gap: 100px;
      padding: 8px 14px;
      border: 1px solid rgba(255, 255, 255, 0.2);

      & > div {
        flex: 1;
      }

      .translation {
        display: flex;
        gap: 10px;
        align-items: center;
        justify-content: center;
      }

      textarea {
        resize: none;
        overflow: hidden;
        width: 100%;
        border: none;
        background: transparent;
        color: white;
        padding: 8px;

        &:hover {
          outline: white 1px solid;
        }
      }
    }
  }
}
