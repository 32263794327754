.marker {
  background: white;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  display: inline-block;
  border: 1.5px solid var(--tree-marker-border-color);
}
.marker:hover {
  cursor: pointer;
}

.marker.selected {
  border: 2px solid var(--tree-marker-selected-border-color);
  box-shadow: 0 0 4px 8px var(--tree-marker-selected-box-shadow-color);
}

.marker.disabled {
  background: var(--tree-marker-disabled-color);
  border: 2px solid var(--tree-marker-disabled-border-color);
}

.marker:not(.disabled):hover {
  width: 24px;
  height: 24px;
}

.namecardContainer {
  position: absolute;
}

.hidden {
  display: none;
}

.propertyDisplay {
  background-color: var(--ruler-label-background);
  color: var(--ruler-label-text-color);
  font-size: var(--font-size-xs);
  border-radius: 50px;
  padding: 5px 8px;
  text-align: center;
  position: absolute;
}

.marker.stateDefault {
  background-color: var(--ruler-label-background);
  border-color: var(--tree-marker-disabled-border-color);
}

.marker.stateMatching {
  background-color: var(--tree-marker-matching-background-color);
  border-color: var(--tree-marker-disabled-border-color);
}

.marker.stateDifferent {
  background-color: var(--tree-marker-different-background-color);
  border-color: var(--tree-marker-disabled-border-color);
}