.header {
  margin-bottom: 40px;
}

.chartContainer {
  margin-top: 30px;
  display: flex;
}

.chartContainer > div {
  width: 50%;
}

.graphLegendWrapper {
  width: 50%;
  padding-left: 50px;

  h4 {
    padding: 5px 0 5px 28px;
    margin: 5px 0;
    border-bottom: 1px solid;
    display: flex;
    justify-content: space-between;

    > span {
      width: 50%;
    }
  }
}

.graphLegendContainer ul {
  height: 100%;
  margin: 0;
  padding: 0;
}

.graphLegendContainer ul li {
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  padding-top: 10px;
  padding-bottom: 10px;
  gap: 4px;

  div {
    display: flex;
    align-items: center;

    span {
      height: 8px;
      width: 8px;
      min-width: 8px;
      margin-right: 10px;
      border-radius: 50%;
    }

    p.textContainer {
      font-size: var(--font-size-l);
      margin: 0;
      padding: 0;
      opacity: 100%;
      transition: opacity .12s;
      color: var(--cds-text-primary);
    }
  }

  & > * {
    width: 50%;
  }
}

.graphLegendContainer ul li.passive .textContainer {
  opacity: 60%;
}

.graphLegendContainer ul li.passive .percentageContainer {
  opacity: 60%;
}

.graphLegendContainer ul li .percentageContainer {
  font-size: var(--font-size-l);
  margin: 0;
  padding: 0 0 0 8px;
}
