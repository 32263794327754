.toolbar {
  position: absolute;
  right: 50px;
  bottom: 50px;
  z-index: 20;
}

.bottomRow {
  display: flex;
}

.topRow {

}

.btnSection {
  border-radius: var(--default-border-radius);
  margin: 10px;
  overflow: hidden;
  display: flex;
}

.btn {
  padding: 8px;
  background-color: var(--table-background);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.btn svg {
  color: white;
}

.btn:hover {
  background-color: #434c50;
}

.btn:active {
  background-color: #434c50;
}

.btn.checked {
  background-color: white;
}

.btn.checked svg {
  color: #222D31;
}

.hidden {
  visibility: hidden;
  position: absolute;
}

.switchHidden {
  visibility: hidden;
}

.mapFilterControls {
  display: flex;
  align-items: center;
  z-index: 1;
}
