.header {
  margin-bottom: 24px;
}

.subheader {
  margin-bottom: 15px;
  margin-top: 40px;
}

.table {
  background-color: var(--panel-mid);
  width: 100%;
  table-layout: fixed;
  overflow: hidden;
  border-radius: var(--default-border-radius);
  border-collapse: collapse;
}
.table td {
  padding: 10px 20px;
  white-space: nowrap;
  font-size: var(--font-size-xs);
}

.table tr:not(:last-child) {
  border-bottom: 1px solid rgb(91, 113, 121);
}

.table td.numeric {
  text-align: right;
}

.table.benefit td:first-child {
  width: 38%;
}

.table.benefit td:nth-child(2), .table.benefit td:nth-child(3) {
  width: 12%;
}

.printableTable {
  background-color: white;
  outline: 1px solid rgb(91, 113, 121);
}
