@import 'src/measurement/Measurement.module';

.container {
  height: 100%;
  position: relative;
  z-index: 9;
}

.spinnerContainer {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
}

.measurementMarker {
  pointer-events: none;
}
