.legacyContainer {
  width: 100%;
}

.legacyLabel {
  margin-bottom: 8px;
  text-transform: uppercase;
  white-space: break-spaces;
}

.legacyInput {
  overflow: hidden;
  width: 100%;
  padding: 12px 10px;
  border: 1px solid var(--panel-border);
  -webkit-appearance: none;
  background: transparent;
  border-radius: var(--default-border-radius);
  color: var(--text-primary);
  font-size: var(--font-size-m);
  line-height: 1;
  text-overflow: ellipsis;
}

.legacyInput:-webkit-autofill,
.legacyInput:-webkit-autofill:hover,
.legacyInput:-webkit-autofill:focus,
.legacyInput:-webkit-autofill:active {
  -webkit-text-fill-color: var(--text-primary) !important;
  -webkit-box-shadow: 0 0 0 30px rgb(41, 51, 54) inset !important;
}

.legacyInput:focus {
  outline: none;
}

::placeholder {
  color: var(--text-ternary);
}

.container {
  width: 100%;
  overflow: hidden;
  padding: 11px;
  border: 1px solid var(--panel-border);
  border-radius: var(--default-border-radius);
  font-size: var(--font-size-m);
  color: var(--text-primary);
  line-height: 1;
  text-overflow: ellipsis;
  display: flex;
  column-gap: 6px;
  position: relative;
}

.container.dense {
  padding: 6px;
}

.input {
  overflow: hidden;
  width: 100%;
  text-overflow: ellipsis;
  appearance: none;
  background: transparent;
  border: none;
  outline: none;
  display: block;
  color: var(--text-primary);
  font-size: inherit;
}

.disabled {
  opacity: 50%;

  .dateInputContainer {
    input {
      cursor: default;
    }
  }
}

.input:-webkit-autofill,
.input:-webkit-autofill:hover,
.input:-webkit-autofill:focus,
.input:-webkit-autofill:active {
  -webkit-text-fill-color: var(--text-primary) !important;
  -webkit-box-shadow: 0 0 0 30px rgb(41, 51, 54) inset !important;
}

.input::-webkit-calendar-picker-indicator {
  opacity: 0;
  cursor: pointer;
}

.passwordToggle {
  border: none;
  background: transparent;
  cursor: pointer;
  font-weight: bold;
  font-size: var(--font-size-xs);
  color: inherit;
}

.dateInputContainer {
  position: relative;

  input {
    cursor: pointer;
  }
}

.calendarIcon {
  right: 11px;
  position: absolute;
  pointer-events: none;
  svg {
    width: 18px;
    height: 18px;
  }
}

.label {
  font-weight: bold;
  text-transform: uppercase;
  padding: 8px;
  color: var(--input-label-color);
  display: block;
  font-size: var(--font-size-s);
}

.errorMessage {
  position: absolute;
  bottom: -25px;
  left: 0;
  color: rgba(var(--color-error), 1);
  font-size: var(--font-size-xs);
}

.error {
    border: 1px solid rgba(var(--color-error), 0.7);
}
