.container {
  display: flex;
  width: 100vw;
  height: 100vh;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: var(--panel-dark);
  gap: 80px;
  overflow-y: auto;
}

@media only screen and (max-height: 800px) {
  .container {
    justify-content: unset;
  }
}
