.settings {
  background: var(--table-background);
  color: var(--text-primary);
  width: 100%;
  height: 100%;
  overflow-y: auto;
  position: relative;
}

.breadCrumbContainer {
  display: flex;
  gap: 50px;
  align-items: center;
  color: white;
}

.breadCrumb {
  text-decoration: none;
  padding: 5px 10px;
  border-radius: var(--default-border-radius);
  button {
    padding: 5px;
    svg {
      width: 27px;
      height: 27px;
    }
  }
}

.title {
  grid-area: title;
  top: 0;
  font-size: var(--font-size-l);
  font-weight: bold;
  margin: 0;
}

.container {
  margin: 40px auto 0 auto;
  padding-bottom: 50px;
  max-width: var(--page-max-width);
  width: var(--page-width);
  display: grid;
  grid-template-areas:
    "title title"
    "tab-bar content";
  grid-template-columns: 1fr 3.8fr;
  grid-template-rows: auto auto;
  grid-column-gap: 120px;
  grid-row-gap: 70px;
}

.tabBar {
  grid-area: tab-bar;
  position: relative;
  white-space: nowrap;
}

.content {
  position: relative;
  grid-area: content;
}
