.windSpeedSliderContainer {
  position: absolute;
  bottom: 24px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 100;
}

.sliderContainer {
  width: 150px;
  font-size: var(--font-size-s);
  text-align: center;
  text-shadow: 0 0 4px var(--page-background);
}

.sliderTitle {
  font-weight: bold;
  text-transform: uppercase;
  margin-bottom: 12px;
}

.sliderLabels {
  display: flex;
  justify-content: space-between;
  margin-bottom: 6px;
  user-select: none;
  pointer-events: none;
  font-weight: bold;
}

.windSpeedInputContainer {
  margin-top: 12px;
  display: inline-block;
  appearance: none;
  padding: 12px;
  border-radius: var(--default-border-radius);
  background-color: var(--text-alternate);
  box-shadow: 0 0 4px var(--page-background);
}

.windSpeedInput {
  display: inline-block;
  background-color: transparent;
  appearance: none;
  outline: none;
  border: none;
  color: inherit;
  font-size: inherit;
}

.windSpeedInput::-webkit-outer-spin-button,
.windSpeedInput::-webkit-inner-spin-button {
  display: none;
}

.safetyFactorBadge {
  border-radius: 50%;
  background-color: var(--page-background);
  z-index: 1;
  color: var(--chart-positive-value);
  border: 2px solid var(--chart-positive-value);
  padding: 2px;
  width: 100px;
  height: 100px;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 20%;
  left: 25%;
}

.safetyFactorBadgeRight {
  left: unset;
  right: 25%;
}

.safetyFactorBadge span {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.safetyFactorBadge span:first-child {
  font-size: var(--font-size-xl);
  justify-content: flex-end;
}

.safetyFactorBadge span:last-child {
  font-size: var(--font-size-xs);
  color: var(--button-primary-text-color);
}

.safetyFactorBadgeDanger {
  color: var(--text-error);
  border: 2px solid var(--text-error);
}
