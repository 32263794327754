.groupHeader {
  margin-bottom: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
  height: 30px;
  font-weight: 600;

  .editableTitle {
    display: flex;
    align-items: center;
    font-size: var(--font-size-l);
  }

  .cancelAndSave {
    display: flex;
    align-items: center;
    gap: 8px;

    button {
      width: 65px;
      height: 30px;
      font-size: 13px;
      font-weight: 700;
    }
  }
}
