.advancedSummaryHeader {
  padding: 20px 28px 10px 28px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  div {
    display: flex;
    align-items: center;
    gap: 5px;
  }
  button {
    padding: 5px;
    svg {
      width: 20px;
      height: 20px;
    }
  }
}

.advancedSummaryMain {
  padding: 10px 28px;
  .advancedSummaryRowContainer {
    display: flex;
    gap: 5px;
    .inputContainer {
      width: 100%;
    }
    .individualDeleteButton {
        padding: 10px;
        height: fit-content;
    }
    .summaryNumeric {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      gap: 6px;
      & > div {
        flex-basis: calc(50% - 3px);
      }
      & > div:last-child {
        flex-basis: 100%;
      }
    }
    .rowPropertySelector {
      background-color: var(--filter-input-background);
      margin-bottom: 10px;
      border-radius: 5px;
      border: none;
    }
    .rowPropertySelectorMenu {
      border: none;
    }
    .andRowSeparator {
      opacity: 0.5;
      width: 100%;
      text-align: center;
      font-size: var(--font-size-xxs);
      margin: 14px 0 8px 0;
    }
  }

  .summaryEnum {
    .selectedRowsContainer {
      margin: 10px 0;
      padding: 0 5px;
    }
    .selectedRow  {
      display: flex;
      align-items: center;
      justify-content: space-between;
      button {
        padding: 5px;
        svg {
          width: 16px;
          height: 16px;
        }
      }
    }
  }
}

.addNewRule {
  flex-direction: row-reverse;
  font-size: var(--font-size-s);
  font-weight: 400;
  padding: 5px;
  margin: 10px 0 10px auto;
  svg {
    width: 20px;
    height: 20px;
  }
}

.advancedSummaryFooter {
  padding: 10px 28px 10px 28px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
}
