.container {
  text-align: center;

  table {
    margin: auto;
    padding: 30px;
    border-radius: 5px;

    td.languageCode {
      padding: 20px 30px 20px 0;
    }

    .selectContainer {
      width: 500px;
    }
  }
}
