.header {
  position: relative;
}

.header::after {
  content: '';
  display: block;
  position: absolute;
  width: 150vw;
  z-index: 1;
  height: 1px;
  background: var(--panel-border_transparent);
  left: -30px;
}

.reportDescription {
  padding: 1.5rem 7vw 2.5rem;
}

.header .reportDescription .organization {
  display: flex;
  align-items: center;
  margin-bottom: 1.5rem;
  font-size: 1.25rem;
}

.header .reportDescription .organization img {
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  margin-right: 0.75rem;
}

.header .reportDescription .description {
  display: flex;
  justify-content: space-between;
  font-size: var(--font-size-xs);
}

.header .reportDescription .description .namesContainer {
  flex-basis: 36%;
  overflow: hidden;
}

.header .reportDescription .names svg {
  margin-right: 0.625rem;
  flex-shrink: 0;
}

.header .reportDescription .names {
  display: flex;
  align-items: center;
  margin-bottom: 0.25rem;
}

.header .reportDescription .names > div {
  display: flex;
  align-items: center;
  text-overflow: ellipsis;
}

.header .reportDescription .names strong {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.header .reportDescription .numOfTrees {
  flex: 1;
}

.header .reportDescription .numOfTrees strong {
  font-size: 0.563rem;
  text-transform: uppercase;
  display: block;
  margin-bottom: 0.375rem;
}

.header .reportDescription .numOfTrees span {
  display: block;
  font-size: var(--font-size-l);
}

.exit {
  position: absolute;
  top: 0;
  right: 0;
  padding: 5px;
  border-radius: 8px;
}
.exit svg {
  width: 22px;
  height: 22px;
  color: white;
}

.printButton {
  height: 47px;
  width: 47px;
  border-radius: 8px;
}

.printButton svg {
  width: 22px;
  height: 22px;
  color: white;
}

.contentContainer {
  display: flex;
  width: calc(100% + 25px);
  max-height: calc(100vh - 20px - 10.7rem - 40px);
}

.bulletPointContainer {
  height: 100%;
  max-height: 100%;
  width: 35%;
  padding-top: 80px;
  padding-left: 7vw;
}

.bulletPointList {
  list-style: none;
  padding: 0;
}

.bulletPointList li {
  margin-bottom: 0.75rem;
  font-size: var(--font-size-xs);
  font-weight: normal;
  opacity: 0.85;
}

.bulletPointList li a {
  opacity: 0.85;
  text-decoration: none;
}

.bulletPointList li a:hover {
  opacity: 1;
}

.bulletPointList li a[active="true"] {
  opacity: 1;
}

.reportsContainer {
  max-height: 100%;
  width: 65%;
  overflow-y: scroll;
  position: relative;
}

.reportSection {
  padding: 0 4vw 70px 50px;
  margin-right: 3vw;
}

.printable {
  margin-right: 0;
  padding: 0 15px;
}

.reportSection:not(:last-child) {
  border-bottom: 1px solid var(--panel-border);
}

.reportSection .placeholder {
  height: 500px;
}

.reportSection h2 {
  font-size: 1.125rem;
  margin-top: 70px;
}

.reportSection h3 {
  font-size: var(--font-size-xs);
}

td.cellValue {
  text-align: end;
  padding-right: 5px;
}

td.cellUnit {
  padding-left: 0;
}
