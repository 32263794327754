.dataPanelTabSelector {
  position: sticky;
  display: flex;
  align-items: center;
  top: 0;
  background-color: rgb(var(--color-mid-grey));
  overflow: hidden;
  white-space: nowrap;
  cursor: pointer;
  border-radius: 10px;
  z-index: 1;

  &.open {
    padding-bottom: 0;
    cursor: unset;
    background-color: var(--panel-dark);
    border-radius: 0;

    ul {
      overflow-x: auto;
      position: relative;

      .navItemWrapper {
        background-color: var(--panel-dark);
      }

      li {
        z-index: 1;
        border-radius: 0 0 10px 10px;
      }

      span {
        border: none;
        padding: 10px;
      }

      .chevronButton {
        padding: 15px;
      }

      .activeTabBackground {
        position: absolute;
        height: 100%;
        top: 0;
        left: 0;
        transition: left .3s ease-in-out;
        background-color: rgb(var(--color-mid-grey));
        border-radius: 10px 10px 0 0;
        transition-duration: 200ms;
        transition-property: all;

        &::before {
          content: "";
          position: absolute;
          background-color: var(--panel-dark);
          box-shadow: 5px 0 0 0 rgb(var(--color-mid-grey));
          left: -10px;
          bottom: 0;
          height: 10px;
          width: 10px;
          border-bottom-right-radius: 10px;
        }


        &::after {
          content: "";
          position: absolute;
          background-color: var(--panel-dark);
          box-shadow: -5px 0 0 0 rgb(var(--color-mid-grey));
          right: -10px;
          bottom: 0;
          height: 10px;
          width: 10px;
          border-bottom-left-radius: 10px;
        }
      }
    }
  }

  ul {
    display: flex;
    width: 100%;

    li {
      font-size: var(--font-size-l);
      padding: 20px 10px;
      color: var(--text-grey);
      cursor: pointer;
      height: 100%;
      position: relative;

      &.selectedTab {
        color: var(--accent-tertiary);
        text-shadow: 0 0 1px var(--accent-tertiary);
      }

      span {
        padding: 5px 20px 5px 0;
      }
    }
  }

  &:not(.open) ul li span {
    border-right: 1px solid var(--panel-border_transparent);
  }

  .disabled {
    cursor: default;
    color: rgba(206, 215, 212, .3);
    pointer-events: none;
  }

  .chevronButton {
    padding: 15px;

    &:hover {
      cursor: pointer;
    }
  }
}
