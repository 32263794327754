.container {
  width: 100%;
  overflow: hidden;
  padding: 5.21px;
  border: 1px solid var(--panel-border);
  border-radius: var(--default-border-radius);
  font-size: var(--font-size-m);
  color: var(--text-primary);
  line-height: 1;
  text-overflow: ellipsis;
  display: flex;
  column-gap: 6px;
}

.input {
  overflow: hidden;
  width: 100%;
  text-overflow: ellipsis;
  appearance: none;
  background: transparent;
  border: none;
  outline: none;
  display: block;
  color: var(--text-primary);
  font-size: inherit;
}

.input:-webkit-autofill,
.input:-webkit-autofill:hover,
.input:-webkit-autofill:focus,
.input:-webkit-autofill:active {
  -webkit-text-fill-color: var(--text-primary) !important;
  -webkit-box-shadow: 0 0 0 30px rgb(41, 51, 54) inset !important;
}
