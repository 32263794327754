.languageSelector {
  width: fit-content;
  .languageChangeTitle {
    margin-bottom: 20px;
  }

  .dropdownContainer {
    width: fit-content;
  }

  .dropdown {
    align-items: center;
    gap: 10px;
    padding: 10px 15px;
    background-color: var(--filter-input-background);
    border-radius: 5px;
    margin-bottom: 5px;

    svg {
      width: 20px;
    }
  }

  .dropdownMenu {
    border: none;
    width: fit-content;
    padding: 10px;
    border-radius: 5px;
    flex-direction: column;
  }
}
