.container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 36px;
  height: 100%;
}

.chartWrapper {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.chartContainer {
  max-height: 60%;
  height: 100%;
  width: 100%;
}

.galleryContainer {
  height: 100%;
  overflow: hidden;
  padding: 36px 0;
}
