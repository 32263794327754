.container {
  width: 70%;
  margin: 25px auto 50px auto;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  padding: 25px 0 50px 0;

  .title {
    line-height: 20px;
    font-size: var(--font-size-l);
  }

  .description {
    opacity: 0.7;
    font-size: var(--font-size-s);
    line-height: 20px;
    margin-bottom: 40px;
    width: 480px;
  }

  .buttonContainer {
    display: flex;
    justify-content: flex-end;

    .applyButton {
      border: none;
      background-color: rgb(var(--color-default-green));
      padding: 10px 15px;
      margin-top: 25px;
      cursor: pointer;
      border-radius: var(--default-border-radius);
      color: white;
      font-weight: bold;
      font-size: var(--font-size-l);

      &:hover {
        opacity: .8;
      }
      &.disabled {
        opacity: .3;
      }
    }
  }

  .spinner {
    position: relative;
    width: 64px;
    height: 64px;
  }
}
