.header {
  z-index: 10;
  padding-bottom: 16px;
}

.title {
  position: relative;
  z-index: 2;
  margin: 0;
  padding: 0;
  padding-bottom: 36px;
  text-align: center;
  font-size: var(--font-size-xxl);
  background-color: var(--page-background);
}

.headerControls {
  position: absolute;
  right: 24px;
  z-index: 11;
}

.propertyList {
  list-style: none;
  margin: 0;
  padding: 0;
  text-align: center;
  font-size: var(--font-size-m);
}

.propertyListItem {
  display: inline-block;
  margin-bottom: 8px;
}

.propertyListItem + .propertyListItem {
  margin-left: 12px;
}

.propertyListItemButton {
  appearance: none;
  font-size: inherit;
  color: inherit;
  cursor: pointer;
  border: 1px solid var(--panel-border_transparent);
  border-radius: var(--default-border-radius);
  padding: 8px 10px;
  background-color: var(--page-background);
  transition: border-color .12s;
}

.propertyListItemButton.active {
  background-color: var(--panel-border_transparent);
}

.propertyListItemButton:hover {
  background-color: var(--panel-border);
  border-color: var(--panel-border);
}

.propertyListItemButton.disabled {
  pointer-events: none;
  opacity: 0.5;
  cursor: not-allowed;
}

.propertyChartContainer,
.referenceChartContainer {
  padding: 24px 0;
  height: 100%;
  flex-flow: column;
  display: grid;
  align-items: center;
  grid-template-columns: 1fr;
  width: 100%;
  overflow-y: auto;
  grid-auto-rows: 1fr auto;
  position: relative;
  grid-template-areas: "chart-1" "controls";
}

.propertyChartContainer {
  width: 70vw;
  max-width: 1200px;
  margin: 0 auto;
}

.propertyChartContainer.compare {
  max-width: 1600px;
  padding: 0 48px;
  display: grid;
  grid-template-areas: "chart-1 chart-2" "controls controls";
  grid-template-columns: 1fr 1fr;
  column-gap: 72px;
}

.propertyChartContainer > div:first-child {
  grid-area: chart-1;
}

.propertyChartContainer.compare > div:nth-child(2) {
  grid-area: chart-2;
}

.propertyChartContainer > div:first-child,
.propertyChartContainer.compare > div:nth-child(2) {
  min-width: 0;
  height: 80%;
  max-height: 700px;
  display: flex;
  flex-flow: column;
}

.propertyChartContainer.compare > div:first-child > div,
.propertyChartContainer.compare > div:nth-child(2) > div {
  height: 100%;
}

.propertyChartContainer > div > div:nth-child(2) {
  flex: 1 1 100%;
}

.referenceChartContainer > div:first-child {
  margin: 0 auto;
  width: 100%;
  max-width: 60vw;
}

.container {
  display: flex;
  flex-flow: column;
  height: 100%;
}

.content {
  flex: 1 1 100%;
  overflow-y: auto;
  position: relative;
  overflow-x: hidden;
}

.content.showsTree {
  overflow-y: unset;
  overflow-x: unset;
}

.content:after {
  display: block;
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  left: -48px;
  right: -48px;
  pointer-events: none;
  box-shadow: inset 0 0 24px 24px var(--page-background), inset 0 0 12px 12px var(--page-background);
}

.content.showsTree:after {
  display: none;
}

.emptyState {
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  max-width: 300px;
  margin: 0 auto;
  height: 100%;
}

.historyButtonContainer {
  margin-top: 0;
  margin-right: 23px;
  margin-left: 23px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.historyButtonContainer > button svg path {
  fill: transparent !important;
}

.historyControlsTitle {
  flex: 1 1 100%;
}

.chartControls {
  position: sticky;
  bottom: 0;
  right: 0;
  padding: 12px 48px;
  grid-area: controls;
  z-index: 1;
  font-size: var(--font-size-m);
}

.propertyChartContainer.compare .chartControls {
  padding-bottom: 36px;
}

.legendContainer {
  display: flex;
  column-gap: 12px;
  justify-content: center;
}

.legend {
  padding: 4px 8px;
  border-radius: 3px;
  background-color: var(--page-background);
  box-shadow: 0 0 24px var(--page-background);
}

.legend.negativeValue:before,
.legend.positiveCompared:before,
.legend.positiveValue:before {
  display: inline-block;
  content: '';
  width: 8px;
  height: 8px;
  border-radius: 50%;
  margin-right: 8px;
  transform: translateY(-25%);
}

.legend.negativeValue:before {
  background-color: var(--chart-negative-value);
}

.legend.positiveValue:before {
  background-color: var(--chart-positive-value);
}

.legend.positiveCompared:before {
  background: linear-gradient(315deg, var(--chart-positive-value) 50%, var(--chart-alternate-value) 50.1%, var(--chart-alternate-value) 100%);
}

.dateSelectorContainer {
  display: flex;
  column-gap: 170px;
  font-size: var(--font-size-m);
}

.dateSelectorContainer.floating {
  column-gap: 0;
  position: absolute;
  left: 0;
  bottom: 40px;
  right: 0;
  z-index: 10;
}

.dateSelector {
  flex: 1 1 50%;
  text-align: center;
}

.dateSelectorToggle {
  display: inline-flex;
  align-items: center;
  column-gap: 6px;
  background-color: var(--text-alternate);
  border-radius: var(--default-border-radius);
  padding: 6px 16px;
  cursor: pointer;
  box-shadow: 0 0 24px var(--page-background);
}

.dateSelectorIndicator {
  flex: 0 0 8px;
  width: 8px;
  height: 8px;
  display: inline-block;
  border-radius: 50%;
}

.dateSelectorIndicator.reference {
  background-color: var(--chart-negative-value);
}

.dateSelectorIndicator.current {
  background-color: var(--chart-alternate-value);
}

.dateSelectorOptions {
  font-size: var(--font-size-m);
  color: var(--text-primary);
  list-style: none;
  margin: 0;
  padding: 0;
  margin-bottom: 8px;
  border: 1px solid var(--panel-border);
  background-color: var(--panel-background);
  border-radius: 3px;
}

.dateSelectorOption {
  cursor: pointer;
  padding: 12px;
}

.dateSelectorOption.selected {
  cursor: not-allowed;
  pointer-events: none;
  opacity: 0.5;
}

.dateSelectorOption:hover {
  background-color: var(--panel-border_transparent);
}

.treeComparison {
  display: grid;
  grid-template-columns: 1fr 1fr;
  height: 100%;
}

:root {
  --tree-overflow: 150px;
}

.tree {
  overflow: hidden;
  margin-top: calc(-1 * var(--tree-overflow) / 2);
  position: relative;
  height: calc(100% + var(--tree-overflow) / 2);
  background-size: 40px 40px;
  background-image: linear-gradient(to right, var(--panel-border_transparent) 1px, transparent 1px),
  linear-gradient(to bottom, var(--panel-border_transparent) 1px, transparent 1px);
  background-position: right;
}

.tree + .tree {
  background-position: left;
}

.tree:before {
  left: calc(-1 * var(--tree-overflow) * 2);
  right: calc(-1 * var(--tree-overflow) * 2);
  top: 0;
  bottom: calc(-1 * var(--tree-overflow) * 2 / 3);
  position: absolute;
  display: block;
  pointer-events: none;
  content: '';
  box-shadow: inset 0 0 var(--tree-overflow) var(--tree-overflow) var(--page-background),
  inset 0 0 calc(var(--tree-overflow) * 1 / 4) calc(var(--tree-overflow) * 1 / 4) var(--page-background);
  z-index: 1;
}

.tree.pointCloud:before {
  left: calc(-1 * var(--tree-overflow) / 2);
  right: calc(-1 * var(--tree-overflow) / 2);
  border-radius: 50%;
  box-shadow: inset 0 0 var(--tree-overflow) var(--tree-overflow) var(--page-background),
  inset 0 0 calc(var(--tree-overflow) * 1 / 4) calc(var(--tree-overflow) * 1 / 4) var(--page-background),
  0 0 var(--tree-overflow) calc(var(--tree-overflow) * 2) var(--page-background);
}

.detailsButton {
  position: absolute;
  bottom: 40px;
  left: 40px;
  border: 0;
  border-radius: 5px;
  height: 38px;
  width: 38px;
  font-size: var(--font-size-xs);
  background-color: var(--button-secondary-background);
  color: var(--button-secondary-text-color);
  cursor: pointer;
  z-index: 20;
  display: flex;
  align-items: center;
  justify-content: center;
}

.detailsButton.active {
  color: var(--button-secondary-background);
  background-color: var(--button-secondary-text-color);
}

.extraDetailsButton {
  position: absolute;
  bottom: 40px;
  left: 90px;
  border: 0;
  border-radius: var(--default-border-radius);
  height: 38px;
  width: 38px;
  font-size: var(--font-size-xs);
  background-color: var(--button-secondary-background);
  color: var(--button-secondary-text-color);
  cursor: pointer;
  z-index: 20;
  display: flex;
  align-items: center;
  justify-content: center;
}

.extraDetailsButton.active {
  color: var(--button-secondary-background);
  background-color: var(--button-secondary-text-color);
}

.resetViewButton {
  position: absolute;
  bottom: 40px;
  right: 40px;
  border: 0;
  border-radius: 5px;
  height: 38px;
  width: 38px;
  font-size: var(--font-size-xs);
  background-color: var(--button-secondary-background);
  color: var(--button-secondary-text-color);
  cursor: pointer;
  z-index: 20;
  display: flex;
  align-items: center;
  justify-content: center;
}

.resetViewButton:hover {
  color: var(--button-secondary-background);
  background-color: var(--button-secondary-text-color);
}
