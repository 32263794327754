.list {
  display: table;
  font-size: var(--font-size-s);
  line-height: var(--line-height-s);
  border-collapse: separate;
  width: 100%;
}

.headers {
  display: table-header-group;
  position: relative;
}

.header {
  display: table-cell;
  font-weight: bold;
  color: var(--text-faded);
  padding-bottom: 19px;
  vertical-align: middle;
  text-transform: uppercase;
  border-left: 11px solid transparent;
  border-right: 11px solid transparent;
}

.content {
  display: table-row-group;
}

.row {
  display: table-row;
  position: relative;
}

.row.inactive {
  background-color: var(--inactive-row-background);
}

.header:after,
.row:after {
  display: block;
  content: '';
  height: 1px;
  background-color: var(--panel-border);
  position: absolute;
  left: 0;
  bottom: 0;
  right: 0;
}

.column {
  display: table-cell;
  padding: 15px 0;
  vertical-align: middle;
  border-left: 11px solid transparent;
  border-right: 11px solid transparent;
}
