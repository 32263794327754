.tabSelectorContainer {
  display: flex;
  background-color: rgb(var(--color-mid-grey));
  top: 0;
  border-radius: 10px;

  &.open {
    background-color: var(--panel-dark);
    border-radius: 0;
  }

  span {
    border: none;
    padding: 10px;
  }

  li span {
    padding: 10px 20px;
  }

  &.open span {
    padding: 10px;
  }

  .chevronButtonContainer {
    width: 57px;
    display: flex;
    height: 100%;
    cursor: pointer;
    background-color: rgb(var(--color-mid-grey));
    border-radius: 10px;

    &.darkBackground {
      background-color: var(--panel-dark);
    }

    &.open {
      border-radius: 0 10px 0 0;
    }

    .chevronButton {
      background-color: rgb(var(--color-mid-grey));
      padding: 15px;
      border-radius: 10px;

      &.open {
        background-color: var(--panel-dark);
        border-radius: 10px 0 10px 0;
      }
    }
  }

  .dataPanelTabSelector {
    position: sticky;
    display: flex;
    white-space: nowrap;
    cursor: pointer;
    width: calc(100% - 92px);

    ul {
      overflow: auto;
    }

    &.open {
      padding-bottom: 0;
      cursor: unset;
      background-color: var(--panel-dark);
      border-radius: 0;

      ul {
        overflow-x: auto;
        position: relative;

        .navItemWrapper {
          background-color: var(--panel-dark);
        }

        li {
          z-index: 1;
          border-radius: 0 0 10px 10px;
        }

        .activeTabBackground {
          position: absolute;
          height: 100%;
          top: 0;
          left: 0;
          transition: left .3s ease-in-out;
          background-color: rgb(var(--color-mid-grey));
          border-radius: 10px 10px 0 0;
          transition-duration: 200ms;
          transition-property: all;

          &::before {
            content: "";
            position: absolute;
            background-color: var(--panel-dark);
            box-shadow: 5px 0 0 0 rgb(var(--color-mid-grey));
            left: -10px;
            bottom: 0;
            height: 10px;
            width: 10px;
            border-bottom-right-radius: 10px;
          }


          &::after {
            content: "";
            position: absolute;
            background-color: var(--panel-dark);
            box-shadow: -5px 0 0 0 rgb(var(--color-mid-grey));
            right: -10px;
            bottom: 0;
            height: 10px;
            width: 10px;
            border-bottom-left-radius: 10px;
          }
        }
      }
    }

    ul {
      display: flex;
      width: 100%;

      li {
        font-size: var(--font-size-l);
        padding: 20px 10px;
        color: var(--text-grey);
        cursor: pointer;
        height: 100%;
        position: relative;

        &.selectedTab {
          color: var(--accent-tertiary);
          text-shadow: 0 0 1px var(--accent-tertiary);
        }
      }
    }

    &:not(.open) ul li:not(.lastTab) span {
      border-right: 1px solid var(--panel-border_transparent);
    }

    &:not(.open) ul li {
      padding: 20px 0;
    }

    .disabled {
      cursor: default;
      color: rgba(206, 215, 212, .3);
      pointer-events: none;
    }

    .chevronButton {
      padding: 15px;

      &:hover {
        cursor: pointer;
      }
    }
  }

  .scrollingArrow {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 0;
    button {
      height: 100%;
      padding: 0;
      border-radius: 0 10px 0 0;

      :hover {
        background-color: inherit;
      }
    }

    :hover {
      background-color: rgb(var(--color-mid-grey));
    }
  }
}

.tabSelectorContainer, .tabSelectorContainer ul {
  /* Hide scrollbar for Chrome, Safari and Opera */
  ::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

