@import './common';

.modal {
  position: relative;
  z-index: 99999;
  background-color: var(--modal-background-color);
  color: var(--text-primary);
  padding: 25px;
  border-radius: var(--default-border-radius);
  border: 1px solid var(--modal-border-color);
  font-size: var(--font-size-m);
  width: 500px;
  max-width: 90%;
  max-height: 90%;
  overflow-y: auto;
}

.size-auto {
  width: auto;
}

.size-wide {
  width: 1000px;
}

.size-extraWide {
  width: 1150px;
}
.size-vwWide {
  width: 90vw;
}

