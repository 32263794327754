.measurementLabel {
  background-color: white;
  color: var(--panel-background);
  border-radius: 18px;
  padding: 2px 12px;
}

.measurementMarker {
  background: var(--accent-tertiary);
  width: 9px;
  height: 9px;
  border-radius: 50%;
  display: inline-block;
  transform: translate(-50%, -50%);
}
