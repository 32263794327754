:root {
  --value-container-size_normal: 90px;
  --value-container-size_large: 125px;
  --value-container-height_normal: 18px;
  --value-container-height_large: 24px;
  --gap_normal: 14px;
  --gap_large: 24px;
}

.data {
  display: flex;
  padding: var(--gap_normal) 0;
  font-size: var(--font-size-xs);
  line-height: var(--value-container-height_normal);
}

.large .data {
  column-gap: 12px;
  padding: var(--gap_large) 0;
  font-size: var(--font-size-s);
  line-height: var(--value-container-height_large);
}

.valueContainer {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 1px;
  width: var(--value-container-size_normal);
  min-height: var(--value-container-height_normal);
  grid-auto-rows: var(--value-container-height_normal);
  position: relative;
}

.large .valueContainer {
  flex: 0 0 50%;
  width: var(--value-container-size_large);
  min-height: var(--value-container-height_large);
  grid-template-rows: var(--value-container-height_large);
}

.large .valueContainer.historical {
  grid-template-rows: var(--value-container-height_large) var(--value-container-height_large);
}

.valueContainer:after {
  content: '';
  position: absolute;
  top: calc(-1 * var(--gap_normal));
  bottom: calc(-1 * var(--gap_normal));
  width: 1px;
  left: calc(50% - 0.5px);
  background-color: var(--panel-border);
}

.large .valueContainer:after {
  top: calc(-1 * var(--gap_large));
  bottom: calc(-1 * var(--gap_large));
}

.large .data:first-child {
  padding-top: calc(var(--gap_large) * 2);
}

.large .data:first-child .valueContainer:after {
  top: calc(-2 * var(--gap_large));
}

.large .data:last-child {
  padding-bottom: calc(var(--gap_large) * 2);
}

.large .data:last-child .valueContainer:after {
  bottom: calc(-2 * var(--gap_large));
}

.positiveValue .fill {
  display: inline-block;
  height: 100%;
  background-color: var(--chart-positive-value);
}

.positiveValue.historical .fill {
  background-color: var(--chart-positive-value_historical);
}

.negativeValue .fill {
  margin-left: auto;
  display: block;
  height: 100%;
  background-color: var(--chart-negative-value);
}

.negativeValue.historical .fill {
  background-color: var(--chart-negative-value_historical);
}

.legendLabel {
  pointer-events: none;
  user-select: none;
  width: var(--value-container-size_normal);
  text-align: center;
  margin-bottom: 12px;
  font-size: var(--font-size-xs);
}

.large .legendLabel {
  font-size: var(--font-size-xs);
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.labelContainer {
  display: flex;
  align-items: center;
}

.large .labelContainer {
  flex: 1 1 50%;
}

.labelContainer {
  margin-top: calc(-1 * var(--value-container-height_normal));
}

.large .labelContainer {
  margin-top: calc(-1 * var(--value-container-height_large));
}

.large .labelContainer.historical > div > div:nth-child(2),
.large .labelContainer.historical > div > div:nth-child(3) {
  height: var(--value-container-height_large);
  display: flex;
  align-items: center;
}

.fill {
  position: relative;
}

.fill:before {
  visibility: hidden;
  display: block;
  position: absolute;
  content: attr(data-tooltip);
  background-color: var(--panel-background);
  border: 1px solid var(--panel-border);
  padding: 4px 8px;
  border-radius: var(--default-border-radius);
  left: 50%;
  top: 4px;
  transform: translate(-50%, -100%);
  z-index: 10;
  pointer-events: none;
  opacity: 0;
  transition: opacity .12s;
}

.fill:hover::before {
  visibility: visible;
  opacity: 1;
}
