.flippersTableContainer {
  padding-top: 150px;

  .flippersTable {
    margin: auto;
    border-collapse: collapse;

    thead {
      padding-left: 125px;
      width: 100%;
      background-color: var(--panel-dark);

      th {
        &.rotatedHeader {
          font-size: var(--font-size-l);
          white-space: nowrap;

          div {
            margin-right: 20px;
            max-width: 50px;
            transform: rotate(-45deg);
            transform-origin: 0 0;
            padding: 8px;
          }
        }
      }
    }

    thead, tbody {
      display: block;
    }

    tbody {
      max-height: 60vh;
      overflow: auto;

      tr:hover:not(:last-child) {
        background-color: rgba(255, 255, 255, 0.1);
      }

      td {
        font-size: var(--font-size-m);
        padding: 16px;
      }
    }
  }

  .unusedFlipper {
    color: var(--text-ternary);
  }

  tbody tr .allOrgSwitchContainer {
    padding: 10px 0;

    & > div {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 6px;

      & > button {
        padding: 4px 8px;
      }
    }
  }

  .orgNameCell {
    cursor: pointer;
  }
}

.toastNotification {
  min-width: fit-content;
}

.changeLogModal {
  background: var(--panel-mid);
  padding: 10px 20px;
  color: white;
  text-align: center;
  border-radius: var(--default-border-radius);

  .changeLogTableContainer {
    max-height: 700px;
    overflow-y: auto;
    table {
      border-collapse: collapse;

      th {
        min-width: 200px;
      }

      th, td {
        border: 1px solid rgba(255, 255, 255, 0.1);
        padding: 8px 16px;
      }
    }
  }
}
