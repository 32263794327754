.propertySelector {
  width: fit-content;
  position: relative;
  z-index: 2;
}

.treeButton {
  background-color: var(--table-background);
  display: flex;
  align-items: center;
  font-size: var(--font-size-xs);
  font-weight: bold;
  gap: 8px;
  border-radius: var(--default-border-radius);
  border: none;
  color: white;
  padding: 8px;
  cursor: pointer;
}

.treeButton:hover {
  background-color: rgb(45, 56, 58);
}

.treeButton svg{
  width: 22px;
  height: 18px;
  pointer-events: none;
}

.propertyListContainer {
  display: flex;
  position: relative;
}

.propertyList {
  box-shadow: 0 3px 3px rgba(0,0,0,0.2);
  display: none;
  position: absolute;
  min-width: 160px;
  left: 100%;
  top: -2px;
  border-radius: var(--default-border-radius);
  background-color: rgb(var(--color-mid-grey));
  list-style-type: none;
  padding: 8px 0;
}

.propertyList li {
  padding: 4px 12px;
  border-radius: var(--default-border-radius);
  width: 100%;
}

.propertyListTitle {
  display: flex;
  gap: 6px;
  align-items: center;
  width: 100%;
}

.propertyListContainer .propertyListTitle {
  justify-content: space-between;
}

.propertyList.visible {
  display: block;
}

.propertyListItem:hover {
  background-color: rgba(255, 255, 255, 0.1);
}

.dropDownContainer {
  position: absolute;
  box-shadow: 0 3px 3px rgba(0,0,0,0.2);
  top: 100%;
  min-width: fit-content;
  width: 180px;
  font-size: var(--font-size-xs);
  line-height: var(--line-height-s);
  background-color: rgb(var(--color-mid-grey));
  color: white;
  border-radius: var(--default-border-radius);
  padding: 8px 0;
}

.dropDownContainer > div {
  padding: 6px 12px;
  white-space: nowrap;
  cursor: pointer;
  border-radius: var(--default-border-radius);
}
.dropDownContainer > div:hover {
  background-color: rgba(255, 255, 255, 0.075);
  transition: all .25s ease-in-out;
}

.dropDownContainer > div svg {
  width: 16px;
  height: 16px;
}

.disabled > svg, .disabled > div {
  cursor: initial;
  opacity: 0.5;
}

.dropDownContainer > div.disabled:hover {
  background-color: rgb(var(--color-mid-grey));
}

.dropDownContainer > div.propertyListTitle {
  position: relative;
}

.dropDownContainer > div.propertyListTitle:before {
  transform: translateX(-12px);
  position: absolute;
  top: 0;
  content: '';
  width: 100%;
  height: 1px;
  background-color: rgb(91, 113, 121);
}
