.home {
  width: 100vw;
  height: 100vh;
  background: var(--panel-dark);
  display: flex;
  flex-direction: column;
  padding-top: 5%;
  gap: 15%;
}

.main {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.title {
  font-size: 2.75rem;
  font-weight: bold;
  color: white;
  margin: 0;
  text-align: center;
}

.features {
  display: flex;
  width: 835px;
  justify-content: space-between;
  margin-top: 48px;
}

.featureOption {
  position: relative;
  overflow: hidden;
  width: 245px;
  height: 340px;
  border: 2px solid var(--panel-border);
  border-radius: 6px;
  cursor: pointer;
  transform: scale(0.95);
  transition: 0.5s all ease-out;
}

.featureOption:hover {
  transform: scale(1);
}

.featureLabel {
  position: absolute;
  bottom: 20px;
  left: 50%;
  font-size: 20px;
  transform: translateX(-50%);
}

.disabled {
  filter: opacity(0.2);
  pointer-events: none;
}
