.emptyState {
  background-color: var(--page-background);
  color: var(--text-primary);
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: column;
  column-gap: 12px;
}

.backButtonWrapper {
  color: var(--text-primary);
  width: 100%;
  height: 100%;
  align-items: center;
}

.backButtonContainer {
  width: 100%;
  height: 100%;
  margin-right: auto;
  display: flex;
  align-items: center;
}

.backButton {
  border-radius: 5px;
  margin-right: 20px;
  flex: 0 0 34px;
  background-color: var(--analytics-view-selector-background);
  width: 34px;
  height: 34px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  transition: background-color .12s;
  text-decoration: none;
}

.backButton:hover {
  background-color: var(--analytics-view-selector-button_hover);
}

.wrapper {
  color: var(--text-primary);
  background-color: var(--page-background);
  height: 100%;
}

.analytics {
  height: 100%;
  display: grid;
  grid-template-columns: minmax(var(--aside-width), var(--aside-max-width)) 1fr;
}

.section {
  padding-top: 40px;
  height: 100%;
  overflow: hidden;
}

.aside {
  height: 100%;
  border-right: 1px solid var(--panel-border);
  display: flex;
  flex-flow: column;
  padding: 40px 0;
  font-size: var(--font-size-s);
}

.aside > div {
  padding-top: 24px;
  padding-right: 30px;
  padding-left: 30px;
  padding-bottom: 24px;
}

.aside > div:first-child {
  padding-top: 0;
}

.aside > div:last-child {
  padding-bottom: 0;
}

.aside > div + div {
  border-top: 1px solid var(--panel-border);
}

.galleryContainer {
  flex: 1 1 100%;
}

.gallerySpinnerContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}

.navigation {
  list-style: none;
  margin: 0;
  padding: 0;
  font-size: var(--font-size-m);
}

.navigation li + li {
  margin-top: 8px;
}

.navigation li a {
  text-decoration: none;
  display: inline-flex;
  align-items: center;
  column-gap: 8px;
  padding: 6px;
  padding-right: 12px;
  border-radius: var(--default-border-radius);
}

.navigation li a.active {
  background-color: var(--tab-bar_link-background-active);
}

.navigation li a:hover {
  background-color: var(--tab-bar_link-background-hover);
  color: var(--tab-bar_link-text-hover);
}

.navigation li a.inactive {
  opacity: 0.5;
  cursor: not-allowed;
  pointer-events: none;
}

.viewSelector {
  background-color: var(--analytics-view-selector-background);
  border-radius: 7px;
  margin: 24px 0 0 0;
  padding: 6px 12px;
  text-align: center;
  display: flex;
  justify-content: space-between;
}

.viewSelectorButton {
  flex: 0 0;
  appearance: none;
  border: none;
  border-radius: var(--default-border-radius);
  cursor: pointer;
  padding: 6px 12px;
  background-color: rgba(0, 0, 0, 0);
  transition: background-color .12s;
  text-transform: uppercase;
  color: inherit;
  font-size: var(--font-size-xs);
  font-weight: bold;
  display: flex;
  flex-flow: column;
  row-gap: 4px;
  align-items: center;
}

.viewSelectorButton:hover, .viewSelectorButton.active {
  background-color: var(--analytics-view-selector-button_hover);
}

.viewSelectorButton:disabled {
  opacity: 0.5;
  cursor: not-allowed;
  pointer-events: none;
}

.rotatedIcon {
  transform: rotate(90deg);
}

.safetyAlert:after {
  color: var(--text-error);
  content: '!';
  width: 16px;
  height: 16px;
  font-size: var(--font-size-xs);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  border: 1px solid var(--text-error);
}
