.table {
  display: table;
  flex-direction: column;
  min-width: 40%;
  margin: 45px auto;
  font-size: var(--font-size-s);
}

.table.stretch {
  width: 50%;
}

.table > .tableRow {
  display: table-row;
  border-radius: var(--default-border-radius);
  white-space: nowrap;
}

.tableRow > div {
  margin: 0 8px;
  padding: 12px;
  display: table-cell;
}

.tableRow > div:first-child {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

.tableRow > div:last-child {
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}

.tableRow > div.shrinkIcon {
  flex: 0;
}

.tableRow:nth-child(odd) {
  background-color: var(--panel-light);
}

.tableRow.dateTableRow {
  background-color: transparent;
  padding: 16px 0;
}

.tableRow.dateTableRow div:nth-child(3), .tableRow.dateTableRow div:nth-child(4) {
  text-align: right;
  padding-right: 0;
}

.indicator {
  padding-left: 16px;
}

.green {
  color: var(--table-icon-green);
}

.red {
  color: var(--table-icon-red);
}

.valueCell {
  text-align: right;
}