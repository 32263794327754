.container {
  position: relative;
  width: 100%;
  height: 100%;
  background-color: rgb(var(--color-mid-grey));
  overflow: hidden;

  &.hidden {
    display: none;
  }
}

.spinner {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 9999;
}

.canvas {
  position: absolute;
  width: 100%;
  height: 100%;
}

.pointCloudLabel {
  font-size: var(--font-size-s);
  background-color: var(--ruler-label-background);
  color: var(--ruler-label-text-color);
  border-radius: 12px;
  padding: 3px 12px;
  cursor: default;
}
