.resetPassword {
  display: flex;
  width: 100vw;
  height: 100vh;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: var(--panel-dark);
  gap: 80px;
}

.container {
  width: 80%;
  max-width: 350px;
  color: var(--text-primary);
}

.form {
  width: 100%;
  display: grid;
  grid-auto-columns: auto;
  grid-gap: 15px;
  grid-template-columns: 1fr;
}

.controls {
  display: grid;
  grid-template-columns: auto auto;
  justify-content: space-between;
  grid-gap: 15px;
  font-size: var(--font-size-s);
}

.success {
  color: var(--accent-green);
  line-height: 1.3rem;
}

.error {
  color: var(--accent-red);
  margin-bottom: 15px;
}
