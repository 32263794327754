.ul {
  list-style: none;
  padding: 0;
  margin: 0;
  font-size: var(--font-size-m);
}

.ul li + li {
  margin-top: 5px;
}

.navLink {
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 7px 10px;
  border-radius: var(--default-border-radius);
  width: 250px;

  span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.navLink:hover {
  background-color: var(--tab-bar_link-background-hover);
  color: var(--tab-bar_link-text-hover);
}

.navLinkActive {
  background-color: var(--tab-bar_link-background-active);
}

.icon {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 7px;
}
