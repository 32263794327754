.accordionWrapper {
  margin: 6px 0;

  .accordion {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 12px;
    border-radius: 5px;
    background: rgb(60, 71, 75);
    border: 1px solid var(--panel-light);
    color: white;
    align-self: flex-start;
    cursor: pointer;
    width: 100%;
    height: 42px;

    .redDot {
      margin-right: 5px;
      height: 6px;
      width: 6px;
      background-color: var(--accent-red);
      border-radius: 50%;
      display: inline-block;
    }

    .toggleIcon svg {
      width: 18px;
      height: 18px;
      stroke-width: 2px;
    }
  }

  .accordion svg {
    width: 14px;
    height: 14px;
  }

  .accordion div {
    display: flex;
    align-items: center;
    gap: 8px;
  }

  .accordionTitle {
    margin: 0;
    font-style: normal;
    font-weight: 600;
    font-size: var(--font-size-s);
    line-height: 1.125rem;
  }

  .panel {
    max-height: 0;
    overflow: hidden;
    opacity: 0;
  }

  section.open .panel {
    max-height: 1000px;
    opacity: 100;
    color: white;
    font-size: var(--font-size-s);
    background: rgb(43, 51, 56);
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
    overflow: visible;
    transition-property: max-height, opacity;
    transition-duration: .3s;
    transition-timing-function: ease-in-out;
  }

  .scrollContainer {
    overflow-y: scroll;
    height: 290px;
    overflow-x: hidden;
  }

  .scrollContainer::-webkit-scrollbar {
    display: none;
  }
}
