.sliderOuterWrapper {
  display: flex;
  margin: 4px 0;
}

.disabled {
  opacity: 0.2;
  pointer-events: none;
}

.lineWrapper {
  display: flex;
  flex: 1;
  align-items: center;
  padding: 0 16px;
}

.line {
  position: relative;
  height: 4px;
  flex: 1;
  background: linear-gradient(90deg, #058B62, #21C576, #FFBA0A, #F47703, #E01830);;
}

.highlight {
  position: absolute;
  top: 0;
  height: 100%;
  background: transparent;
}

.inactive {
  position: absolute;
  top: 0;
  height: 100%;
  background: rgba(0, 0, 0, .8);
}

.line.colored {
  background: var(--accent-green);
}

.line.colored .highlight {
  background: var(--accent-red);
}

.line.monochrome {
  background: white;
}

.inactive.monochrome {
  background: var(--color-alternate-dark-grey);
}

.knobWrapper {
  position: absolute;
  z-index: 12;
  top: 50%;
  transform: translate(-50%, -50%);
  user-select: none;
}

.knob {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  transform: translateY(-30%);
  cursor: pointer;
  opacity: 1;
  border: none;
  margin-top: -18px;
  background: white;

  &.lowOpacity {
    opacity: 0.3;
  }

  .knobLabel {
    position: absolute;
    top: 25px;
    left: 50%;
    transform: translateX(-50%);
    font-size: var(--font-size-xs);

    &.moving {
      font-size: var(--font-size-m);
      padding: 2px 4px;
      background: var(--table-background);
      transition: all 0.2s ease-in-out;
    }
  }

  &:after {
    content: '';
    position: absolute;
    bottom: -50%;
    left: 0;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 12px 6px 0 6px;
    border-color: white transparent transparent transparent;
  }
}

.labelLeft {
  position: absolute;
  top: 6px;
  left: 0;
  opacity: 0.5;
}

.labelRight {
  position: absolute;
  top: 6px;
  right: 0;
  opacity: 0.5;
}
