@import 'src/measurement/Measurement.module';

.measurementMarker {
  pointer-events: none;
}

.arrowButton {
  border: none;
  border-radius: 10%;
  background: transparent;
  cursor: pointer;
  color: rgba(255, 255, 255, .8);
  margin-bottom: 50%;
  pointer-events: auto;

  &:hover {
    background: rgba(255, 255, 255, .2);
  }

  &:disabled {
    cursor: default;
  }

  svg {
    width: 70px;
    height: 70px;
  }
}
