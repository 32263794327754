.columnSelectorModal {
    background: rgb(var(--color-mid-grey)) !important;
    min-width: 250px;
    max-height: min(300px, 33vh);
}

.firstPageContainer::-webkit-scrollbar-track {
    background-color: rgb(var(--color-mid-grey));
}

.secondPageContainer::-webkit-scrollbar-track {
    background-color: rgb(var(--color-mid-grey));
}

.firstPageContainer {
    position: relative;
    margin: 2px 0;
    overflow-y: auto;
    height: fit-content;
    max-height: 300px;
    min-width: 330px;

    .title {
        text-transform: uppercase;
        font-weight: 600;
        color: var(--modal-property-label-color);
        font-size: var(--font-size-xs);
        margin: 8px 0 0 14px;
    }
}

.firstPageContainer ul {
    margin: 0;
    padding: 8px 0;
}

.activeFilter {
    list-style-type: none;
    line-height: 1.125rem;
    width: 100%;
    padding: 5px 12px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: var(--font-size-s);
    cursor: grab;
}

.activeFilter:hover {
    background-color: var(--analytics-view-selector-button_hover);
}

.activeFilterText {
    gap: 10px;
    display: flex;
    align-items: center;
}

.cancelButton {
    visibility: hidden;
    width: 20px;
    height: 20px;
}

.activeFilter:hover > .cancelButton {
    visibility: visible;
    cursor: pointer;
}

.addColumnButton {
    cursor: pointer;
    line-height: 1.125rem;
    background: rgb(var(--color-mid-grey));
    border: none;
    display: flex;
    align-items: center;
    padding: 10px 14px;
    color: white;
    font-size: var(--font-size-s);
    gap: 2px;
    width: 100%;
    position: sticky;
}

.firstPageContainer .addColumnButton {
    bottom: 0;
    border-top: 1px solid var(--panel-border);
}

.secondPageContainer .addColumnButton {
    top: 0;
}

.addColumnButton svg {
    width: 18px;
    height: 18px;
}

.addColumnButton:hover {
    background-color: rgb(70, 80, 84);
}

.secondPageContainer {
    overflow-y: scroll;
    height: fit-content;
    max-height: 300px;
}

.secondPageContainer ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
}

.propertyListTitle {
    user-select: none;
    text-transform: uppercase;
    font-weight: 600;
    color: var(--modal-property-label-color);
    font-size: var(--font-size-xs);
    line-height: var(--font-size-xs);
    padding: 10px 0;
    margin-left: 14px;
    &:hover {
        cursor: pointer;
    }
    &:hover + ul li {
        background-color: var(--analytics-view-selector-button_hover);
    }
}

.propertyListContainer {
    border-bottom: 1px solid var(--panel-border);
    width: 100%;
}
.propertyListContainer:not(:nth-child(2)) .propertyListTitle {
    padding-top: 21px !important;
}

.propertyListContainer:last-child {
    border: none;
}

.secondPageContainer ul li {
    font-size: var(--font-size-xs);
    line-height: 1.125rem;
    width: 100%;
    padding: 8px 15px;
    cursor: pointer;
    align-items: center;
    display: flex;
    gap: 15px;
}

.secondPageContainer ul li svg {
    width: 18px;
    height: 18px;
}

.placeholder {
    width: 18px;
}

.activeProperty {
    background-color: var(--analytics-view-selector-button_hover);
}

.secondPageContainer ul li:hover {
    background-color: var(--analytics-view-selector-button_hover);
}
