:root {
  --main-font: 'Inter', sans-serif;

  --accent-primary_rgb: 255, 196, 16;
  --accent-primary: rgb(var(--accent-primary_rgb));
  --accent-primary_faded: rgba(var(--accent-primary_rgb), 0.2);

  --accent-primary-border: rgb(170, 112, 0);
  --accent-red-border: rgb(172, 15, 15);
  --accent-green-border: rgb(0, 132, 84);

  --accent-red_rgb: 255, 69, 94;
  --accent-red: rgb(var(--accent-red_rgb));
  --accent-red_faded: rgba(var(--accent-red_rgb), 0.2);

  --accent-green_rgb: 0, 189, 76;
  --accent-green: rgb(var(--accent-green_rgb));
  --accent-green_faded: rgba(var(--accent-green_rgb), 0.2);

  --accent-blue_rgb: 37, 165, 254;
  --accent-blue: rgb(var(--accent-blue_rgb));
  --accent-blue_faded: rgba(var(--accent-blue_rgb), 0.2);

  --accent-purple_rgb: 234, 106, 255;
  --accent-purple: rgb(var(--accent-purple_rgb));
  --accent-purple_faded: rgba(var(--accent-purple_rgb), 0.2);

  --accent-tertiary: rgba(255, 183, 0);
  --accent-tertiary-color: 255, 183, 0;

  --color-transparent-white: rgba(255, 255, 255, 0.8);

  --color-default-green: 5, 139, 98;
  --color-mid-grey: 49, 61, 65;
  --color-alternate-grey: rgba(56, 62, 66, 1);
  --color-alternate-dark-grey: rgba(31, 40, 44, 1);
  --color-error: 255, 87, 78;

  --panel-background: rgb(34, 44, 47);
  --panel-background-hex: #222c2f;
  --panel-dark_rgb: 41, 51, 54;
  --panel-dark: rgb(41, 51, 54);
  --panel-dark-new-details: rgb(36, 45, 48);
  --panel-mid: rgb(var(--color-mid-grey));
  --panel-light: rgb(58, 72, 77);
  --panel-border: rgb(91, 113, 121);
  --panel-border_transparent: rgb(91, 113, 121, 0.6);
  --panel-border-hover: rgb(255, 255, 255);
  --panel-row_background_rgb: 65, 80, 85;
  --panel-row_background: rgb(65, 80, 85);
  --panel-border-radius: 12px;

  --pointcloud-grid: #405b63;

  --text-primary: rgb(255, 255, 255);
  --text-secondary: rgba(255, 255, 255, 0.6);
  --text-ternary: rgba(161, 167, 170, 0.5);
  --text-alternate: rgba(58, 72, 77, 1);
  --text-faded: rgb(206, 215, 212);
  --text-error: #ff483e;
  --text-success: #008454;

  --icon-active_rgb: 255, 255, 255;
  --icon-active: rgb(var(--icon-active_rgb));
  --icon-active_faded: rgba(var(--icon-active_rgb), 0.4);
  --icon-disabled: rgba(255, 255, 255, 0.2);

  --legacy-nav-bar-height: 56px;
  --nav-bar-height: 72px;

  --page-background: rgb(34, 45, 49);
  --page-max-width: 1120px;
  --page-width: 90%;

  --common-button_background-hover: rgb(60, 73, 78);

  --tab-bar_link-background-active: rgb(60, 73, 78);
  --tab-bar_link-background-hover: rgb(65, 80, 85);
  --tab-bar_link-text-hover: var(--text-primary);

  --font-size-xxl: 2rem; /* 32px */
  --font-size-xl: 1.5rem; /* 24px */
  --font-size-l: 1rem; /* 16px */
  --font-size-m: 0.9rem; /* 14px */
  --font-size-s: 0.82rem; /* 13px */
  --font-size-xs: 0.75rem; /* 12px */
  --font-size-xxs: 0.625rem; /* 10px */

  --line-height-s: 1.125rem;

  --modal-overlay-background-color: rgba(0, 0, 0, 0.7);
  --modal-background-color: rgb(34, 44, 47);
  --modal-border-color: rgb(91, 113, 121);
  --modal-property-label-color: rgb(42, 179, 133);

  --input-border: rgb(91, 113, 121);
  --input-select-background: var(--page-background);
  --input-select-option_focus-background: rgba(255, 255, 255, 0.05);
  --input-select-option_hover-background: rgb(58, 72, 77);
  --input-select-option_active-background: rgb(var(--color-mid-grey));

  --input-label-color: rgba(91, 113, 121);

  --button-primary-background: rgba(var(--color-default-green), 1);
  --button-primary-background_hover: rgb(5, 128, 91);
  --button-primary-text-color: rgb(255, 255, 255);
  --button-primary-background_disabled: rgba(161, 167, 170, 0.5);
  --button-primary-text-color_disabled: rgb(34, 44, 47);

  --button-secondary-background: rgb(58, 72, 77);
  --button-secondary-text-color: rgb(255, 255, 255);

  --button-tertiary-background: var(--accent-tertiary);
  --button-tertiary-text-color: rgba(var(--color-mid-grey));
  --button-tertiary-background_disabled: rgba(161, 167, 170, 0.5);
  --button-tertiary-text-color_disabled: rgb(34, 44, 47);

  --organization-logo-shadow: 0 2px 4px rgb(58, 72, 77);
  --auth-panel-shadow: 0px 2px 4px rgb(58, 72, 77);

  --tag-secondary-background: rgb(206, 212, 215);
  --tag-secondary-text: rgba(var(--color-mid-grey), 1);

  --inactive-row-background: hsl(194, 14%, 21%);

  --chip-background: rgba(42, 179, 133, 0.25);

  --property-selector-property-background: rgb(65, 80, 85);
  --property-selector-property-background_hover: rgb(89, 108, 115);
  --property-selector-property-add-background: var(--accent-tertiary);
  --property-selector-property-add-color: var(--text-alternate);

  --tree-namecard-background: rgb(65, 80, 85);
  --tree-namecard-border-color: rgb(91, 113, 121);

  --tree-marker-border-color: rgb(237, 244, 242);
  --tree-marker-selected-border-color: rgb(42, 179, 133);
  --tree-marker-selected-box-shadow-color: rgba(42, 179, 133, 0.3);
  --tree-marker-disabled-color: rgb(206, 215, 212);
  --tree-marker-disabled-border-color: rgb(91, 113, 121);
  --tree-marker-matching-background-color: rgb(39, 185, 136);
  --tree-marker-different-background-color: rgb(240, 139, 45);


  --table-even-row-background: rgb(46, 58, 61);
  --table-cell-border-color: rgb(66, 83, 87);
  --table-selected-row-background: var(--accent-primary_faded);
  --table-pinned-row-background: rgba(0, 0, 0, 0.2);
  --table-selected-row-border-color: rgb(var(--color-default-green));
  --table-hover-background: rgb(var(--color-mid-grey));
  --table-new-selected-row-background: rgb(var(--color-mid-grey));
  --table-background: rgb(34, 45, 49);
  --table-pinned-background: rgb(30, 40, 44);
  --table-border-color: rgb(69, 85, 91);
  --table-border-non-transparent: rgb(69, 85, 91);

  --table-icon-green: rgb(39, 185, 136);
  --table-icon-red: rgb(255, 87, 78);

  --table-pin-color: rgb(42, 179, 133);

  --aside-width: 10vw;
  --aside-max-width: 260px;

  --chart-positive-value: rgba(42, 179, 133, 1);
  --chart-positive-value_historical: rgba(181, 223, 91, 1);
  --chart-negative-value: rgba(240, 139, 45, 1);
  --chart-negative-value_historical: rgba(244, 185, 129, 1);
  --chart-alternate-value: rgba(181, 223, 91, 1);
  --chart-hover: rgba(255, 255, 255, 0.05);

  --analytics-view-selector-background: rgba(255, 255, 255, 0.05);
  --analytics-view-selector-button_hover: rgba(255, 255, 255, 0.1);

  --details-view-selector-background: rgba(255, 255, 255, 0.05);
  --details-view-selector-button_hover: rgba(255, 255, 255, 0.1);

  --switch-track-background: rgba(91, 113, 121, 1);
  --switch-track-background_active: rgba(42, 179, 133, 0.2);
  --switch-handle-background: rgba(255, 255, 255, 0.8);
  --switch-handle-background_active: rgba(42, 179, 133, 1);

  --ruler-label-background: rgb(237, 244, 242);
  --ruler-label-text-color: rgb(var(--color-mid-grey));

  --slider-color: rgba(240, 139, 45, 1);
  --slider-background: rgba(42, 179, 133, 1);

  --dropdown-button-padding: 5px 10px;
  --dropdown-container-padding: 7px 0;

  --default-border-radius: 5px;

  --accordion-background_hover: rgba(255, 255, 255, 0.1);
  --accordion-background_open: rgba(255, 255, 255, 0.05);

  --filter-input-background: rgba(34, 45, 49, 0.5);

  --text-grey: rgba(206, 215, 212, 1);

  --workspace-modal-border: rgba(255, 255, 255, 0.3);

  --support-sucess: rgba(36, 161, 69, 1);
  --support-failed: rgba(255, 122, 122, 1)

}
