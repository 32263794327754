.container {
  > * {
    margin-bottom: 30px;
  }

  .form {
    width: 400px;
    max-width: 100%;
    border-top: 2px solid rgba(255, 255, 255, 0.15);
  }

  .success {
    color: var(--text-success);
  }

  .error {
    color: var(--text-error);
  }

  .inputError {
    border-color: var(--accent-red-border);
  }
}
