.propertyLabel {
  font-size: var(--font-size-m);
}

.rangeRow {
  display: flex;
  justify-content: space-between;
  padding: 5px 0;
  opacity: 0.4;
  &:hover {
    cursor: pointer;
  }
  &.selectedRow {
    opacity: 1;
  }
  .circle {
    width: 15px;
    height: 15px;
    border-radius: 50%;
    display: block;
  }
  .circleAndLabel {
    display: flex;
    gap: 10px;
    align-items: center;
  }
  .minMaxContainer {
    display: flex;
    justify-content: space-between;
  }
  .capitalizeFirstLetter:first-letter {
    text-transform: capitalize;
  }
}
