.label {
  margin-bottom: 8px;
  font-size: var(--font-size-xs);
  line-height: 1.5;
  text-transform: uppercase;
  display: block;
}

.placeholderWithIcon {
  display: flex;
  align-items: center;
  text-overflow: ellipsis;
  white-space: nowrap;
}
