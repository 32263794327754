.container {
  background-color: var(--modal-background-color);
  color: var(--text-primary);
  padding: 16px;
  border-radius: var(--default-border-radius);
  border: 1px solid var(--modal-border-color);
  font-size: var(--font-size-m);
  max-width: 80%;
  width: 800px;
  margin-left: auto;
  margin-top: 6px;
}

.title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 12px;
  margin-bottom: 12px;
  border-bottom: 1px solid var(--modal-border-color);
}

.titleText {
  font-size: var(--font-size-l);
}

.fields {
  display: grid;
  grid-template-columns: 1fr 1fr 1.25fr;
  grid-gap: 16px;
}

.fieldset {
  border: none;
  margin: 0;
  padding: 0;
}

.fieldset label {
  text-transform: capitalize;
}

.fieldsetTitle {
  margin-bottom: 12px;
  text-transform: uppercase;
  font-weight: bold;
  font-size: var(--font-size-s);
}

.hideStatuses {
  margin-top: 12px;
}

.hideStatuses label {
  text-transform: none;
  margin-top: 12px;
}
