.container {
  position: relative;
  width: 100%;
  height: 100%;
  background-color: var(--page-background);
}

.mapAndControlsContainer {
  position: relative;
  height: 100%;
  flex: 1 1 100%;
  overflow-y: hidden;
  z-index: 0;
}

.treeInfoContainer {
  top: -30px;
  transition: left .12s ease-in-out, bottom .12s ease-in-out;
  position: absolute;
}

.mapLoader {
  position: absolute;
  right: 50px;
  top: 50px;
  z-index: 9999;
}

.legacySpinner {
  position: absolute;
  right: 25px;
  top: calc(var(--legacy-nav-bar-height) + 25px);
  z-index: 9999;
}

.managedAreaSearchContainer {
  width: 320px;
  max-width: 100%;
}

.mapContainer {
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  position: absolute;
  transition: bottom .12s ease-in-out;
}

:local .mapContainer :global(.mapboxgl-ctrl-bottom-left) a {
  position: absolute;
  left: 5px;
  bottom: 5px;
}

:global .mapboxgl-ctrl-attrib {
  display: none;
}

.mapContainer.seeking canvas {
  cursor: url('images/panorama-cursor.png') 13 70, auto !important;
}

.treeSearchContainer {
  padding-left: 25px;
  width: 320px;
  max-width: 100%;
}

.managedAreaSearchContainer > div > div,
.treeSearchContainer > div > div,
.treeSearchContainer > label {
  background-color: var(--panel-background);
}

.filterControls {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.filterControlButton {
  appearance: none;
  border-radius: 50px;
  padding: 4px 8px;
  background: transparent;
  color: var(--button-secondary-text-color);
  font-size: var(--font-size-xs);
  text-transform: uppercase;
  font-weight: bold;
  cursor: pointer;
  border: 1px solid transparent;
}

.filterControlButton + .filterControlButton {
  margin-left: 4px;
}

.filterControlButton:hover {
  border: 1px solid var(--button-secondary-background);
}

.filterControlButton.active {
  border: 1px solid var(--button-secondary-background);
  background: var(--button-secondary-background);
}

.filterOption {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.filterOption span {
  display: block;
}

.propertyLegendContainer {
  position: absolute;
  top: calc(var(--legacy-nav-bar-height) + 30px);
  z-index: 1;
  left: 50%;
  transform: translateX(-50%);
}

.navSearchButton svg {
  width: 24px;
  height: 24px;
}

.navSearchButton {
  padding: 8px;
}

.navSearchButton svg path {
  fill: transparent !important;
}

.navHamburgerMenu{
  padding: 5px;
}

.navHamburgerMenu svg {
  width: 24px;
  height: 24px;
}


.mapFilterControls {
  position: absolute;
  right: 30px;
  bottom: 30px;
  height: 90px;
  display: flex;
  align-items: center;
  z-index: 1;
}

.mapControls {
  position: absolute;
  left: 0;
  bottom: 0;
  right: 0;
  top: 0;
  transition: bottom .12s ease-in-out, left .12s ease-in-out;
}

.treeListTableContainer {
  position: absolute;
  left: 0;
  right: 0;
  z-index: 20;
  transition: bottom .12s ease-in-out;
}

.barScale {
  position: absolute;
  bottom: 5px;
  right: 20px;
  z-index: 1;
  color: white;
  opacity: 80%;
  font-size: 0.6rem;
}

.carbonBarScale {
  bottom: 30px !important;
}

.barScale .barScaleValues {
  display: flex;
  justify-content: space-between;
}

.barScale .barScaleValues div {
  flex: 1;
}

.barScale .barScaleValues div:nth-child(2) {
  text-align: center;
}

.barScale .barScaleValues div:nth-child(3) {
  text-align: right;
  position: relative;
}

.barScale .barScaleValues div:nth-child(3) span {
  position: absolute;
  transform: translateX(-50%);
}

.barScale .barScaleIndicator {
  min-height: 4px;
  min-width: 100px;

  display: flex;
  justify-content: space-around;

  border: 1px solid rgba(255, 255, 255, 0.4);
  border-radius: 1px;
}

.barScaleIndicator div {
  flex-grow: 1;
}

.barScaleIndicator div:first-child {
  border-radius: 1px 0 0 1px;
  background: white;
}

.barScaleIndicator div:last-child {
  border-radius: 0 1px 1px 0;
  background: #222D31;
}

.ghLogo {
  width: 69.37px;
  height: 20px;
  position: absolute;
  left: 50%;
  bottom: 9%;
  transform: translateX(-50%);
}

.dashboardDescription {
  font-size: var(--font-size-m);
}

.navLink {
  text-decoration: none;
  padding: 8px 15px;
  border-radius: var(--default-border-radius);

  &:hover {
    background-color: rgba(255, 255, 255, 0.075);
    transition: all .25s ease-in-out;
  }

  &.active {
    color: var(--accent-tertiary);
  }
}
