.enumSelectorMenu {
  font-size: var(--font-size-xs);
  padding: 3px;
  overflow-x: visible !important;
  max-width: 100%;
  max-height: 200px;
  flex-direction: column;
  align-items: flex-start !important;
  border: none;
  border-radius: 5px;
  &::-webkit-scrollbar {
    display: none;
  }
  .dropdownItem {
    text-overflow: ellipsis;
    overflow: hidden;
  }
}

.enumSelectorPlaceholder {
  border-radius: 5px;
  background-color: var(--filter-input-background)
}
.enumSelectorField {
  border: none;
  div {
    color: white;
  }
}

.selectedRowsContainer > div:first-child {
  padding-top: 10px;
}
.selectedRowsContainer > div:last-child {
  padding-bottom: 5px;
}
.selectedRow {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: var(--font-size-xs);
  padding: 2px 0;
  .selectedRowItem {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
  button {
    padding: 3px;
  }
}

.error {
  margin-top: 10px;
  color: rgb(var(--color-error));
  font-size: var(--font-size-xs);
}
