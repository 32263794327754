.legend {
  display: flex;
  justify-content: space-between;
  padding: 45px 120px 30px 15px;
}

.printableLegend {
  padding: 24px 120px 5px 15px;
}

.absolute {
  display: flex;
  gap: 20px;
}

.legendItem {
  font-size: var(--font-size-xs);
  display: flex;
  gap: 5px;
}

.coloredMarker {
  margin: auto;
  width: 8px;
  height: 8px;
  border-radius: 50%;
}
