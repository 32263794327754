.mapWrapper {
  position: relative;

  .mapContainer {
    width: 100%;
    height: 256px;
    border: 1px solid var(--modal-border-color);
    border-radius: 7px;
  }

  .resetButtonContainer {
    z-index: 999;
    position: absolute;
    right: 16px;
    bottom: 110px;
  }
}

.mapStyleSelector {
  position: absolute;
  right: 70px;
  bottom: 15px;
  z-index: 999;
}

.styleSelectorButton {
  border: 0;
  border-radius: var(--default-border-radius);
  font-size: var(--font-size-s);
  padding: 8px 12px;
  background-color: var(--table-background);
  color: var(--button-secondary-text-color);
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;

  > svg {
    width: 16px;
    height: 16px;
  }
}

.tileButton {
  border: 0;
  border-radius: var(--default-border-radius);
  width: 38px;
  height: 38px;
  font-size: var(--font-size-xs);
  background-color: var(--table-background);
  color: var(--button-secondary-text-color);
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;

  > svg {
    width: 24px;
    height: 24px;
  }

  &.active {
    color: var(--button-secondary-background);
    background-color: var(--button-secondary-text-color);

    &:hover {
      background-color: rgb(222, 211, 211);
    }
  }

  &:not(.active):hover {
    background-color: rgb(30, 27, 27);
  }
}
