@import 'src/measurement/Measurement.module';

.container {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;

  > canvas {
    border-radius: var(--panel-border-radius);
  }
}

.measurementMarker {
  pointer-events: none;
}
