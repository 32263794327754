.header {
  margin-bottom: 40px;
}

.chartContainer {
  display: flex;
}

.chartContainer > div {
  width: 50%;
}

:global(.legendContainer) {
  width: 50%;
  padding-left: 50px;
}

:global(.legendContainer ul) {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  margin: 0;
  padding: 0;
}

:global(.legendContainer ul li) {
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  margin-left: 10px;
  padding-bottom: 10px;
}

:global(.legendContainer ul li span) {
  display: inline-block;
  height: 8px;
  width: 8px;
  margin-right: 10px;
  border-radius: 50%;
}

:global(.legendContainer ul li .textContainer) {
  font-size: var(--font-size-l);
  margin: 0;
  padding: 0;
  opacity: 100%;
  transition: opacity .12s;
}

:global(.legendContainer ul li.passive .textContainer) {
  opacity: 60%;
}

:global(.legendContainer ul li.passive .percentageContainer) {
  opacity: 60%;
}

:global(.legendContainer ul li .percentageContainer) {
  font-size: var(--font-size-l);
  margin: 0;
  padding: 0;
}
