.riskSliderContainer {
  margin-top: 15px;

  .labelContainer {
    color: #CBD4D6;
    display: flex;
    gap: 4px;

    span {
      color: white;
      text-transform: capitalize;
    }
  }

  input[type="range"] {
    -webkit-appearance: none;
    appearance: none;
    width: 80%;
    height: 8px;
    padding: 0 5%;
    background: url("sliderBackground.svg");
    background-size: 100% 100%;
    cursor: pointer;
    outline: none;
    margin-top: 15px;
  }

  /* Track: webkit browsers */
  input[type="range"]::-webkit-slider-runnable-track {
    border-radius: 16px;
  }

  /* Track: Mozilla Firefox */
  input[type="range"]::-moz-range-track {
    border-radius: 16px;
  }

  /* Thumb: webkit */
  input[type="range"]::-webkit-slider-thumb {
    /* removing default appearance */
    -webkit-appearance: none;
    appearance: none;
    /* creating a custom design */
    background-color: #fff;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    border: 4px solid rgba(227, 232, 234, 1);
  }

  /* Thumb: Firefox */
  input[type="range"]::-moz-range-thumb {
    background-color: #fff;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    border: 4px solid rgba(227, 232, 234, 1);
  }
}
