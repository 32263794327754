.container {
  display: flex;
  width: 100vw;
  height: 100vh;
  flex-direction: column;
}

.content {
  position: relative;
  width: 100%;
  height: calc(100% - var(--legacy-nav-bar-height));
  flex: 1;
}
