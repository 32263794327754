.logoContainer {
  display: flex;
  column-gap: 15px;
  max-width: 400px;
  width: 100%;
}

.logo {
  flex: 0 0 100px;
  width: 100px;
  height: 100px;
  background-size: contain;
  background-color: #fff;
  border: 1px solid var(--panel-border);
  border-radius: 50%;
  overflow: hidden;
  background-position: center center;
}

.urlEditContainer {
  max-width: 400px;
  margin-top: 50px;
}

.scriptContainer {
  margin-top: 50px;
  max-width: 400px;

  h3 {
    white-space: nowrap;
  }

  .toggleContainer {
    padding: 10px;
    border-radius: 5px;
    transform: translateX(-10px);
    &:hover {
      background: rgba(255, 255, 255, 0.1);
    }
  }

  .description {
    opacity: 0.7;
    font-size: var(--font-size-s);
    line-height: 20px;
    margin-bottom: 10px;
    width: 480px;
  }

  h5 {
    text-transform: uppercase;
    margin-top: 45px;
    margin-bottom: 15px;
  }

  .label {
    opacity: 0.7;
    display: flex;
    align-items: center;
    gap: 5px;
    font-size: var(--font-size-s);
    margin-top: 0;
    margin-bottom: 0;
    width: 480px;
  }

  i {
    display: block;
    margin-bottom: 12px;
  }
}

.managedAreaResult {
  margin-bottom: 20px;
}

.scriptButton {
  border: none;
  background-color: rgb(var(--color-default-green));
  padding: 10px 15px;
  margin: 15px 15px 10px 0;
  cursor: pointer;
  border-radius: var(--default-border-radius);
  color: white;
  font-weight: bold;
  font-size: var(--font-size-l);

  &.migration {
    width: 220px;
  }

  &.disabled {
    opacity: 0.5;
  }
}

.scriptButton:hover {
  opacity: .8;
}

.errorMessage {
  margin-top: 10px;
  color: rgba(var(--color-error), 1);
  font-size: var(--font-size-m);
}

.flipperContainer {
  transform: translateX(-10px);
  padding: 10px;
  border-radius: 5px;

  &:hover {
    background: rgba(255, 255, 255, 0.1);
  }
}

.checkbox {
  margin: 10px 0 5px 0;
}

.jobsTable {
  border-collapse: collapse;
  border: none;
  width: max-content;

  thead {
    font-size: var(--font-size-m);

    th {
      padding: 8px;
    }
  }

  tbody {
    font-size: var(--font-size-s);

    td {
      padding: 5px 10px;
    }
  }

  .error {
    color: rgba(var(--color-error), 1);
    padding-top: 0;
  }
}
