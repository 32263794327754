@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  src: url(./fonts/Inter-Regular.ttf) format('truetype');
}
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  src: url(./fonts/Inter-Medium.ttf) format('truetype');
}
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  src: url(./fonts/Inter-SemiBold.ttf) format('truetype');
}
