.titleContainer {
  display: grid;
  grid-template-columns: 1fr auto;
}

.titleControls {
  display: flex;
  align-items: flex-start;
  column-gap: 15px;
  margin-top: 4px;
}

.filters {
  display: flex;
  column-gap: 15px;
  margin-bottom: 30px;
}

.search {
  flex: 1 1 100%;
}
