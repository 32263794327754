.sigmaRowContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: var(--font-size-xs);
  padding: 2px 7px;
  margin: 2px 0;
  border: none;
  background-color: transparent;
  cursor: pointer;
  width: 100%;
  color: white;
  border-radius: var(--default-border-radius);

  &:hover {
    background-color: rgba(255, 255, 255, 0.2) !important;
  }

  &.selectedRow {
    background-color: rgba(255, 255, 255, 0.1);
  }

  .circleAndLabel {
    align-items: center;
    gap: 6px;
    display: flex;
    justify-content: space-between;
  }
  & svg {
    width: 16px;
    height: 16px;
  }
}

.circle {
  width: 7px;
  height: 7px;
  border-radius: 50%;
}
