.filterContainer.open {
  background-color: rgba(255, 255, 255, 0.075);
  border-radius: 5px;
  padding-bottom: 15px;
}

.filterContainer.open .functionButtonContainer {
  visibility: visible;
  transition: visibility .25s ease-in-out;
}

.filterContainer.open .selectedFilter {
  color: white;
  display: block;
  transition: display .25s ease-in-out;
}

.filterRow {
  color: var(--text-secondary);
  justify-content: space-between;
  font-size: var(--font-size-s);
  display: flex;
  gap: 12px;
  align-items: center;
  padding: 12px;
  /*width: 100%;*/
  /*transform: translateX(-3%);*/
  border-radius: 5px;
  cursor: pointer;
}

.filterLabel {
  display: flex;
  gap: 12px;
  align-items: center;
}

.filterRow svg {
  width: 16px;
  height: 16px;
}

.filterContainer.open .filterRow {
  color: white;
}

.filterContainer:not(.open) .filterRow:hover {
  background-color: rgba(255, 255, 255, 0.075);
  transition: all .25s ease-in-out;
}

.functionButtonContainer {
  visibility: hidden;
  display: flex;
  gap: 20px;
}

.selectedFilter {
  display: none;
}

.speciesContainer {
  padding: 10px 26px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.propertiesContainer {
  padding: 5px 26px;
}

.speciesTitle, .propertiesTitle {
  font-size: var(--font-size-xs);
  font-weight: bold;
}

.species {
  font-size: var(--font-size-m);
  font-weight: normal;
  margin-top: 10px;
}

.propertyTitle {
  margin-bottom: 5px;
  font-size: var(--font-size-s);
  font-weight: normal;
}

.propertyItem {
  font-size: var(--font-size-xs);
  padding: 5px 0;
}

.propertyValueContainer {
  display: flex;
  font-size: var(--font-size-xs);
  justify-content: space-between;
}

.propertyScaleType {
  font-weight: 500;
}

.modal {
  width: 300px;
  border: none;
  padding: 30px;
  border-radius: 9px;
  background-color: rgb(var(--color-mid-grey));
}

.modalTitle {
  font-size: var(--font-size-l);
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.modalTitle button {
  padding: 3px;
}

.modalTitle svg {
  width: 18px;
  height: 18px;
}

.modalDescription {
  font-size: var(--font-size-s);
  margin: 15px 0 20px 0;
}

.deleteModalControls {
  display: flex;
  justify-content: space-between;
  font-size: var(--font-size-s);
}

.deleteModalControls button {
  color: white;
  border: none;
  border-radius: 5px;
  padding: 5px 10px;
  background-color: transparent;
}

.deleteModalControls button:hover {
  background-color: rgba(255, 255, 255, 0.1);
  cursor: pointer;
}

.deleteModalControls .deleteButton {
  color: rgb(var(--color-error));
  display: flex;
  align-items: center;
  gap: 8px;
}

.deleteButton svg {
  width: 16px;
  height: 16px;
}

.rangeRow {
  display: flex;
  font-size: var(--font-size-xs);
  justify-content: space-between;
  padding: 5px 0;
  .circle {
    width: 12px;
    height: 12px;
    border-radius: 50%;
    display: block;
  }
  .circleAndLabel {
    display: flex;
    gap: 10px;
    align-items: center;
  }
  .minMaxContainer {
    display: flex;
    justify-content: space-between;
  }
  .capitalizeFirstLetter:first-letter {
    text-transform: capitalize;
  }
}
