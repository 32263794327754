.container {
  text-align: center;

  .table {
    display: flex;
    justify-content: center;

    thead {
      position: sticky;
      top: 0;
      font-size: var(--font-size-l);
      background-color: #408F71;
      display: table-row-group;
    }

    table {
      border-collapse: collapse;
      text-align: center;
      margin-bottom: 35px;

      th, td, .th {
        border: 1px solid var(--table-border-color);
        padding: 10px;
        display: table-cell;
        font-weight: normal;
      }

      tbody td {
        font-size: var(--font-size-m);
      }
    }
  }

}
