.verticalDotsButton {
  border: none;
  background: none;
  color: white;
  cursor: pointer;

  svg {
    vertical-align: bottom;
  }
}

.modal {
  border: 0;
  border-radius: var(--default-border-radius);
  font-size: var(--font-size-s);
  padding: 5px 0;
  background-color: rgba(56, 62, 66, 1);
  color: var(--button-secondary-text-color);
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.3);


  button {
    display: block;
    background: none;
    border: none;
    color: white;
    padding: 5px 20px 5px 10px;
    width: 100%;
    text-align: start;
    cursor: pointer;
    line-height: 18px;

    &:hover {
      background-color: rgba(91, 113, 121, 1);
    }
  }

  .optionRow {
    display: flex;
    align-items: center;
    gap: 6px;

    &.viewAllRow {
      svg {
        opacity: 1;
        path {
          fill: white;
        }
      }
    }
  }
}
