.boundariesContainer {
  display: flex;
  justify-content: center;
  padding: 5px 0;
  flex-wrap: wrap;
}

.sigmaLabel {
  padding: 5px 20px;
  display: flex;
  gap: 5px;
  align-items: center;
  transition: opacity 250ms ease;
  cursor: pointer;
}

.circle {
  width: 8px;
  height: 8px;
  border-radius: 50%;
}
