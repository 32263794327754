.inputContainer {
  display: flex;
  gap: 5px;
  .filterInputInner {
    font-size: var(--font-size-xs);
    background-color: var(--filter-input-background);
    border: none;
    input {
      background-color: transparent;
    }
  }
  .filterValueInput {
    display: none;
    &.visible {
      display: block;
    }
  }
}
.dropdown {
  align-items: center;
  border: none;
  width: 100%;
  padding: 10px;
  background-color: var(--filter-input-background);
  border-radius: 5px;
  margin-bottom: 5px;
  svg {
    width: 20px;
  }
}

.dropdownMenu {
  border: none;
  width: 100%;
  padding: 10px;
  border-radius: 5px;
  flex-direction: column;
}

.error {
  margin-top: 10px;
  color: rgb(var(--color-error));
  font-size: var(--font-size-xs);
}
