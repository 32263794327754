.wrapper {
  position: relative;
  .tableWrapper {
    overflow-x: auto;
    width: calc(100% - 110px);
    margin-left: 110px;

    colgroup col {
      border-top: 2px solid transparent;
    }

    .table {
      border-collapse: collapse;
      thead {
        background-color: rgb(31, 40, 44);
        th {
          border-bottom: 1px solid rgba(255, 255, 255, 0.2);
          border-right: 1px solid rgba(255, 255, 255, 0.2);
          color: var(--table-icon-green);
          font-size: var(--font-size-s);
          font-weight: bold;

          &.settled {
            cursor: not-allowed;
          }

          &.firstColumn {
            min-width: 70px;
            max-width: 70px;
            border-top-left-radius: 10px;
            margin-left: 40px;
            cursor: default;
            background-color: rgb(31, 40, 44);
            position: absolute;
            left: 0;
          }
        }
      }

      tbody {
        td {
          border-right: 1px solid rgba(255, 255, 255, 0.2);
          text-align: center;
          vertical-align: middle;
          font-size: var(--font-size-s);

          &.settled {
            cursor: not-allowed;
          }

          &.firstColumn {
            min-width: 70px;
            max-width: 70px;
            cursor: default;
            position: absolute;
            left: 40px;
            background-color: rgb(31, 40, 44);
            overflow: visible;

            .eyeSwitch {
              user-select: none;
              cursor: pointer;
              position: absolute;
              left: 0;
              transform: translateX(-150%);
            }
          }
        }
      }

      th, td {
        padding: 8px;
        min-width: 110px;
        max-width: 110px;

        &:last-of-type {
          border-right: none;
        }
      }

      td, .ellipsis {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }

      .hoveredColumn {
        background-color: rgba(255, 255, 255, 0.05);
      }

      .selectedColumn {
        background-color: rgba(255, 255, 255, 0.05);
        border-top: 2px solid var(--accent-green-border);
      }
    }
  }
}
