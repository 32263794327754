.displayModes {
  transform: translateY(-50%);
  min-width: 300px;
  max-width: 400px;
  border: none;
  padding: 25px 25px 40px 25px;
  border-radius: 9px;
  background-color: rgb(var(--color-mid-grey));
  color: var(--text-primary);

  .modalTitle {
    font-size: var(--font-size-l);
    font-weight: bold;
    display: flex;
    justify-content: space-between;
    align-items: center;
    button {
      padding: 5px;
      svg {
        width: 20px;
        height: 20px;
      }
    }
  }
  .rowContainer {
    margin-top: 20px;
    > div:not(:first-child) {
      padding: 8px 0 ;
      font-size: var(--font-size-s);
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }
  .displayTreeContainer {
    display: flex;
    white-space: nowrap;
    align-items: center;
    font-size: var(--font-size-s);
    justify-content: space-between;
    padding: 8px 0;

    .dropdownPrefix {
      font-weight: bold;
      margin-right: 20px;
    }

    .dropdown {
      width: fit-content;
      padding: 0;
    }

    .dropdownField {
      align-items: center;
      gap: 8px;
      border: none;
      padding: 4px 0;
      width: fit-content;
      svg {
        width: 20px;
      }
    }
    .dropdownMenu {
      flex-direction: column;
      border: none;
      width: fit-content;
      padding: 5px 0;
      border-radius: 5px;
      div {
        border-radius: 5px;
        padding: 5px 10px;
      }
    }
  }
  .displayModesToggle {
    padding: 8px 0;
    font-weight: bold;
    font-size: var(--font-size-s);
  }
}
