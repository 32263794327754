.animateSearchModal {
    animation: animateModal .240s ease-in-out;
}

@keyframes animateModal {
    0% {
        opacity: 0;
        transform: translateY(10px);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

.searchBar {
    position: absolute;
    top: 36px;
    left: calc(50% - 570px / 2);
    max-width: none;
    max-height: none;
    background-color: var(--text-alternate);;
    color: var(--text-primary);
    border-radius: var(--default-border-radius);
    border: 1px solid var(--panel-border);
    padding: 0;
    font-size: var(--font-size-m);
    height: fit-content;
    overflow: visible;
    width: 570px;
}

.separator {
    border: none;
    height: 2px;
    margin-left: 10px;
    background: repeating-linear-gradient(90deg,var(--text-ternary),var(--text-ternary) 2px,transparent 2px,transparent 6px);
}

.searchHintsContainer {
    max-height: 500px;
    overflow: scroll;
    background-color: var(--modal-background-color);

    &:empty {
        display: none;
    }
}
