.dataPanelContainer {
  flex-grow: 1;
  height: 100%;
  display: flex;
  flex-direction: column;

  .content {
    margin-top: 20px;
    background-color: rgb(var(--color-mid-grey));
    border-radius: var(--panel-border-radius);

    .dataPanelBottomSection {
      padding-left: 38px;
      padding-right: 18px;
    }

    &::-webkit-scrollbar-track {
      margin-top: 61px;
    }

    &::-webkit-scrollbar {
      background-color: var(--panel-dark);
    }
  }

  .open {
    overflow-y: auto;
    height: 100%;
    width: 100%;
    padding-bottom: 20px;
  }

  ::-webkit-scrollbar {
    width: 12px;
    height: 12px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: rgb(var(--color-mid-grey));
    background-clip: padding-box;
    border-right: 6px solid var(--text-grey);
  }

  ::-webkit-scrollbar-track-piece {
    border-right: 5px solid rgb(var(--color-mid-grey));
    border-bottom: 8px solid rgb(var(--color-mid-grey));
    border-top: 5px solid rgb(var(--color-mid-grey));
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    //border: 3px solid rgba(255, 255, 255, 0);
    background: var(--text-grey);
    background-clip: padding-box;
    border-radius: 0;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: var(--text-grey);
    background-clip: padding-box;
  }

  ::-webkit-scrollbar-corner {
    background: rgba(0,0,0,0);
  }
}
