.button {
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  -webkit-appearance: none;
  border-radius: var(--default-border-radius);
  cursor: pointer;
  text-decoration: none;

  background: var(--accent-primary);
  color: var(--text-alternate);

  &:hover {
    opacity: 0.8;
  }
}

.button [data-style="icon"] svg path {
  fill: var(--text-alternate);
}

.button:disabled {
  opacity: 0.5;
}

.functionButton:disabled {
  background: transparent;
  color: #5e6769;
}

.functionButton {
  background-color: transparent;
  color: white;
  display: flex;
  column-gap: 10px;
  flex-shrink: 0;
  border-radius: var(--default-border-radius);
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 4px;
}

.functionButton:hover {
  background-color: rgba(255, 255, 255, 0.075);
  transition: all .25s ease-in-out;
  color: white;
}

[data-style="icon"] {
  display: flex;
  align-items: center;
  justify-content: center;
}

[data-style="icon"] svg {
  width: 15px;
  height: 15px;
}

.primary {
  background: var(--button-primary-background);
  color: var(--button-primary-text-color);
  font-size: var(--font-size-m);
  font-weight: bold;
}

.primary:disabled {
  background: var(--button-primary-background_disabled);
  color: var(--button-primary-text-color_disabled);
}

.primary:disabled.buttonWithIcon svg path {
  fill: var(--button-primary-text-color_disabled);
}

.primary.buttonWithIcon svg path {
  fill: var(--button-primary-text-color);
}

.success {
  background: var(--accent-green);
}

.success:disabled {
  background: var(--accent-green_faded);
}

.secondary {
  background: transparent;
  border: 1px solid var(--panel-border);
  color: var(--text-primary);

  &:hover {
    opacity: 1;
    background-color: rgba(255, 255, 255, 0.05);
  }
}

.secondaryFilled {
  background-color: var(--table-background);
  color: var(--text-primary);

  &:disabled {
    background-color: var(--table-background);
    color: var(--text-primary);
    opacity: 0.7;
  }
}

.secondaryFilled:hover {
  background-color: rgb(30, 27, 27);
  transition: all .25s ease-in-out;
}

.rounded {
  background: transparent;
  border: 1px solid var(--panel-border);
  color: var(--text-primary);
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: row;

  &:hover {
    opacity: 1;
    background: transparent;
  }
}

.secondary:disabled {
  opacity: 0.7;
}

button[disabled] {
  pointer-events: none;
  cursor: not-allowed;
}

.fullWidth {
  width: 100%;
}

.sizeS {
  padding: 2px 3px;
}

.sizeM {
  padding: 8px 15px;
}

.sizeL {
  padding: 16px 24px;
}

.tertiary {
  background: var(--button-tertiary-background);
  color: var(--button-tertiary-text-color);
  border: 1px solid transparent;
}

.tertiary [data-style="icon"] svg path {
  fill: none;
}
