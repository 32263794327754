.cancel {
  position: absolute;
  top: 20px;
  right: 20px;
  padding: 5px;
}

.cancel svg {
  width: 20px;
  height: 20px;
}

.header {
  border-bottom: 1px solid rgba(255, 255, 255, 0.1)
}

.explore {
  font-size: 1.125rem;
  color: white;
  line-height: 1rem;
  margin-top: 28px;
  margin-left: 30px;
}

.orgName {
  margin-bottom: 28px;
  font-size: var(--font-size-m);
  line-height: var(--font-size-m);
  color: white;
  margin-left: 30px;
}

.areaContainer {
  padding: 20px 20px 20px 30px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1)
}

.areaSecondPageContainer {
  background-color: #242B2D;
}

.areaFirstPage {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.areaLabelContainer {
  display: flex;
  gap: 12px;
  align-items: center;
}

.areaLabel {
  display: flex;
  flex-direction: column;
  color: white;
  gap: 6px;
}

.areaLabel span:nth-child(1) {
  font-size: var(--font-size-l);
}

.areaLabel span:nth-child(2) {
  font-size: var(--font-size-xxs);
  display: flex;
  align-items: center;
  opacity: .6;
}

.areaLabel span:nth-child(2) svg {
  color: white;
}

.areaFirstPage svg:first-of-type {
  color: var(--modal-property-label-color);
  width: 24px;
  height: 24px;
}

.areaFirstPage svg:last-child {
  color: white;
}

.toSecondPage {
  border: none;
  cursor: pointer;
  padding: 5px;
  background-color: transparent;
}

.areaFirstPage .toSecondPage svg {
  color: white;
  width: 20px;
  height: 20px;
}

.selectedAreasList {
  padding-left: 36px;
}

.selectedAreasList::-webkit-scrollbar {
  display: none;
}

.selectedAreasList li:first-child {
  margin-top: 12px;
}

.selectedArea {
  display: flex;
  justify-content: space-between;
  padding: 0;
  color: white;
  font-size: var(--font-size-m);
  align-items: center;
}

.selectedArea button {
  padding: 8px;
}

.selectedArea svg {
  width: 18px;
  height: 18px;
}

.areaSecondPage {
  color: white;
}

.areaSecondPageHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  white-space: nowrap;
  margin-bottom: 5px;
}

.selectAll {
  background-color: transparent;
  color: white;
  border-radius: var(--default-border-radius);
  font-size: var(--font-size-s);
  padding: 5px 10px;
  cursor: pointer;
  border: none;

  &:hover {
    background-color: rgba(255, 255, 255, 0.075);
    transition: all .25s ease-in-out;
    color: white;
  }
}

.flexBox {
  align-items: center;
  display: flex;
  gap: 10px;
}

.toFirstPage {
  padding: 5px;
}

.toFirstPage svg {
  width: 22px;
  height: 22px;
}

.managedAreas {
  height: fit-content;
}

.managedAreaRow {
  display: flex;
  align-items: center;
  border-radius: 5px;
  cursor: pointer;
  padding: 8px;
  justify-content: flex-start;
  gap: 8px;
  font-size: var(--font-size-s);
}

.managedAreaRow svg {
  width: 16px;
  height: 16px;
}

.checkContainer {
  width: 16px;
  height: 16px;
}

.managedAreaRow.selectedManagedArea {
  background-color: rgba(255, 255, 255, 0.075);
  transition: all .25s ease-in-out;
}

.managedAreaRow:hover {
  background-color: rgba(255, 255, 255, 0.075);
  transition: all .25s ease-in-out;
}

.searchInputContainer {
  background-color: #222D31;
  position: sticky;
  top: 0;
  margin: 8px;
}
