.mapSettingsContainer {
  background-color: var(--table-background);
  position: relative;
  overflow: visible;
  cursor: pointer;
  border-radius: var(--default-border-radius);
  margin: 10px;
  display: flex;
  color: white;
  white-space: nowrap;

  .dropupContainer {
    overflow: hidden;
    border-radius: var(--default-border-radius);
    position: absolute;
    background-color: rgb(var(--color-mid-grey));
    top: -8px;
    left: -100px;
    transform: translateY(-100%);
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.2);
    min-width: 220px;
    max-width: 200px;
    padding: 5px;

    .displayTreesAs {
      padding: 0 12px;
      cursor: default;
    }

    .viewOption {
      display: flex;
      align-items: center;
      gap: 8px;
      padding: 8px 12px;
      border: none;
      background-color: transparent;
      color: white;
      cursor: pointer;
      width: 100%;
      border-radius: var(--default-border-radius);
      font-size: var(--font-size-xs);
      &:hover {
        background-color: rgba(255, 255, 255, 0.1);
      }
      &.disabled {
        opacity: .4;
      }
      svg {
        width: 16px;
        height: 16px;
      }
    }

    .rowContainer {
      .displayTreeContainer {
        display: flex;
        white-space: nowrap;
        align-items: center;
        font-size: var(--font-size-xs);
        justify-content: space-between;
        padding: 8px 0;

        .dropdown {
          width: fit-content;
          padding: 0;
          margin-right: 10px;
        }

        .dropdownField {
          align-items: center;
          gap: 8px;
          border: none;
          padding: 4px 0;
          width: fit-content;
          svg {
            width: 20px;
          }
        }
        .dropdownMenu {
          flex-direction: column;
          border: none;
          width: fit-content;
          padding: 5px 0;
          border-radius: 5px;
          div {
            border-radius: 5px;
            padding: 5px 10px;
          }
        }
      }
    }
  }

  .greenTitle {
    text-transform: uppercase;
    font-weight: 600;
    color: var(--modal-property-label-color);
    font-size: var(--font-size-xxs);
    padding: 10px 10px 5px;
    cursor: default;
  }

  .settingsButton {
    cursor: pointer;
    display: flex;
    gap: 8px;
    align-items: center;
    height: 100%;
    margin: auto;
    color: white;
    padding: 10px;
    background-color: transparent;
    border: none;
    font-size: var(--font-size-xs);
    border-radius: var(--default-border-radius);
    svg {
      width: 20px;
      height: 20px;
    }
    &:hover {
      background-color: #434c50;
      transition: background-color .2s ease-in;
    }
  }
}
