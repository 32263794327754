.filterContainer {
  padding: 20px 20px 20px 30px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  max-height: 40vh;
  overflow-y: scroll;
}

.filterContainer::-webkit-scrollbar {
  display: none;
}

.cancel {
  position: absolute;
  top: 20px;
  right: 20px;
  padding: 5px;
}

.cancel svg {
  width: 20px;
  height: 20px;
}

.header {
  border-bottom: 1px solid rgba(255, 255, 255, 0.1)
}

.explore {
  font-size: 1.125rem;
  color: white;
  line-height: 1rem;
  margin-top: 28px;
  margin-left: 30px;
}

.orgName {
  margin-bottom: 28px;
  font-size: var(--font-size-m);
  line-height: var(--font-size-m);
  color: white;
  margin-left: 30px;
}

.areaContainer {
  padding: 20px 20px 20px 30px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1)
}

.areaSecondPageContainer {
  background-color: #242B2D;
  height: 95%;
}

.firstPage {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.labelContainer {
  display: flex;
  gap: 12px;
  align-items: center;
}

.label {
  display: flex;
  flex-direction: column;
  color: white;
  gap: 6px;
}

.label span:nth-child(1) {
  font-size: var(--font-size-l);
}

.label span:nth-child(2) {
  font-size: var(--font-size-xxs);
  display: flex;
  align-items: center;
  opacity: .6;
}

.label span:nth-child(2) svg {
  color: white;
}

.firstPage svg:first-of-type {
  color: var(--modal-property-label-color);
  width: 24px;
  height: 24px;
}

.firstPage svg:last-child {
  color: white;
}

.toSecondPage {
  border: none;
  cursor: pointer;
  padding: 5px;
  background-color: transparent;
}

.firstPage .toSecondPage svg {
  color: white;
  width: 20px;
  height: 20px;
}

.selectedFilterList {
  padding-left: 36px;
  overflow-y: scroll;
  max-height: 400px;
}

.filterList::-webkit-scrollbar {
  display: none;
}

.filterList li:first-child {
  margin-top: 12px;
}

.toFirstPage {
  padding: 5px;
}

.toFirstPage svg {
  width: 22px;
  height: 22px;
}
