@use '@carbon/styles/scss/theme';
@use '@carbon/styles/scss/themes';
@use '@carbon/react/scss/components/button/tokens' with (
  $button-primary: (fallback: #00916F, values: ()),
  $button-primary-hover: (fallback: #006B52, values: ()),
  $button-primary-active: (fallback: #03624E, values: ()),
  $button-secondary: (fallback: #6F6F6F, values: ()),
  $button-secondary-hover: (fallback: #474747, values: ()),
  $button-secondary-active: (fallback: #C6C6C6, values: ()),
  $button-tertiary: (fallback: #00916F, values: ()),
  $button-tertiary-hover: (fallback: #006B52, values: ()),
  $button-tertiary-active: (fallback: #03624E, values: ()),
  $button-danger-primary: (fallback: #DA211E, values: ()),
  $button-danger-secondary: (fallback: #DA211E, values: ()),
  $button-danger-hover: (fallback: #B81C19, values: ()),
  $button-danger-active: (fallback: #75100E, values: ()),
  $button-separator: (fallback: #E0E0E0, values: ()),
);

@forward '@carbon/react/scss/config';
@forward '@carbon/react/scss/spacing';
@forward '@carbon/react/scss/colors' hide $white;
@forward '@carbon/react/scss/motion';
@forward '@carbon/react/scss/type';
@forward '@carbon/react/scss/themes';
@forward '@carbon/react/scss/theme';

// @use '@carbon/react/scss/reset';
@forward '@carbon/react/scss/fonts';
@forward '@carbon/react/scss/grid';
@forward '@carbon/react/scss/layer';
@forward '@carbon/react/scss/layout';
//@forward '@carbon/react/scss/zone';
@use '@carbon/react/scss/components';
@use '@carbon/react/scss/config';
@use '@carbon/react/scss/utilities/custom-property';

@use '@carbon/layout';
@use '@carbon/type';
@use '@carbon/themes/scss/utilities';

body {
  @if config.$css--body == true {
    background-color: custom-property.get-var('background', #ffffff);
    color: custom-property.get-var('text-primary', #161616);
  }

  line-height: 1;
}

$custom-white-colors: (
  focus: #00916F,
  interactive: #00916F,
  link-primary: #00916F,
  link-primary-hover: #007C62,
  border-interactive: #00916F,
  inspection-column: #FCCD27,
  pruning-column: #FFC3C2,
  watering-column: #B8DCFF,
  assessment-column: #D5C7FF,
  spraying-column: #74E791,
  button-disabled: #C6C6C6 !important
) !default;

$custom-g10-colors: (
  focus: #00916F,
  interactive: #00916F,
  link-primary: #00916F,
  link-primary-hover: #007C62,
  border-interactive: #00916F,
  inspection-column: #FCCD27,
  pruning-column: #FFC3C2,
  watering-column: #B8DCFF,
  assessment-column: #D5C7FF,
  spraying-column: #74E791,
  button-disabled: #C6C6C6 !important
) !default;

$custom-g90-colors: (
  focus: #2FD8A7,
  interactive: #2FD8A7,
  link-primary: #2FD8A7,
  link-primary-hover: #0ABF91,
  border-interactive: #2FD8A7,
  inspection-column: #806000,
  pruning-column: #C2211E,
  watering-column: #0075FF,
  assessment-column: #683DD6,
  spraying-column: #74E791,
  button-tertiary: #FFFFFF !important,
  button-tertiary-hover: #F4F4F4 !important,
  button-tertiary-active: #C6C6C6 !important,
  button-disabled: #6F6F6F !important
) !default;

$custom-g100-colors: (
  focus: #2FD8A7,
  interactive: #2FD8A7,
  link-primary: #2FD8A7,
  link-primary-hover: #0ABF91,
  border-interactive: #2FD8A7,
  inspection-column: #806000,
  pruning-column: #C2211E,
  watering-column: #0075FF,
  assessment-column: #683DD6,
  spraying-column: #74E791,
  button-tertiary: #FFFFFF !important,
  button-tertiary-hover: #F4F4F4 !important,
  button-tertiary-active: #C6C6C6 !important,
  button-disabled: #525252 !important
) !default;

:root[data-carbon-theme='white'] {
  @include theme.theme(utilities.merge(themes.$white, $custom-white-colors));
}

:root[data-carbon-theme='g10'] {
  @include theme.theme(utilities.merge(themes.$g10, $custom-g10-colors));
}

:root[data-carbon-theme='g90'] {
  @include theme.theme(utilities.merge(themes.$g90, $custom-g90-colors));
}

:root[data-carbon-theme='g100'] {
  @include theme.theme(utilities.merge(themes.$g100, $custom-g100-colors));
}
