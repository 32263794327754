.checkboxContainer {
  display: flex;
  width: fit-content;
  align-items: center;
  cursor: pointer;
}

.checkboxContainer + .checkboxContainer {
  margin-top: 7px;
}

.disabled {
  opacity: 50%;
}

.checkbox {
  display: flex;
  flex: 0 0 18px;
  width: 18px;
  height: 18px;
  align-items: center;
  justify-content: center;
  border: 1px solid var(--panel-border);
  margin-right: 6px;
  background: transparent;
  border-radius: 3px;
  color: var(--text-primary);
}

.checked {
  border-color: var(--panel-border);
  background: var(--text-primary);
  color: var(--panel-dark);
}

.hidden {
  position: absolute;
  left: -100vw;
}
