.columnSelectorModal {
  background: rgb(var(--color-mid-grey)) !important;
  height: 300px;
}

.listTitle {
  text-transform: uppercase;
  font-weight: 600;
  color: var(--modal-property-label-color);
  font-size: var(--font-size-xs);
  line-height: var(--font-size-xs);
  padding: 10px;
  margin-left: 14px;
}

.propertyListContainer {
  border-bottom: 1px solid var(--panel-border);
  width: 100%;

  top: 0;
  overflow-y: auto;
  height: fit-content;
  max-height: 300px;

  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;

    li {
      font-size: var(--font-size-xs);
      line-height: 1.125rem;
      width: 100%;
      padding: 10px 20px 10px 15px;
      cursor: pointer;
      align-items: center;
      display: flex;
      gap: 15px;

      &:hover {
        background-color: rgba(255, 255, 255, 0.1);
      }

      svg {
        width: 18px;
        height: 18px;
      }
    }

    .activeProperty {
      background-color: rgba(255, 255, 255, 0.05);
    }

    .disabledProperty {
      opacity: 0.3;
      cursor: not-allowed;
    }
  }

  &::-webkit-scrollbar-track {
    background-color: rgb(var(--color-mid-grey));
  }
}
.propertyListContainer:not(:nth-child(2)) .propertyListTitle {
  padding-top: 21px !important;
}

.propertyListContainer:last-child {
  border: none;
}

.placeholder {
  width: 18px;
}
