.wrapper {
  display: flex;
  width: 100vw;
  height: 100vh;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: var(--panel-dark);
  gap: 80px;
}
