.modal {
  padding: 0;
  min-width: 650px;
  width: 800px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;

  .modalHeader {
    width: 100%;
    position: sticky;
    z-index: 999;
    background-color: var(--modal-background-color);
    top: 0;

    h3 {
      font-size: var(--font-size-xl);
      margin: 16px 0;
    }

    .closeButton {
      position: absolute;
      right: 16px;
      top: 16px;
      padding: 5px;

      svg {
        width: 22px;
        height: 22px;
        stroke-width: 2px;
      }
    }
  }

  .chartsWrapper {
    padding: 0 25px 25px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: 30px;

    li {
      white-space: nowrap;
      display: flex;
      align-items: center;
      gap: 16px;
      position: relative;

      h4 {
        width: 150px;
        white-space: break-spaces;
      }

      .chartContainer {
        height: 170px;
        width: 450px;
      }

      .currentValue {
        position: absolute;
        right: 0;
        transform: translateX(100%);
        color: var(--accent-tertiary);
        font-weight: 500;
      }
    }
  }
}
