.checkboxContainer {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;

  input[type="checkbox"] {
    cursor: pointer;
    position: relative;
    width: 40px;
    height: 20px;
    -webkit-appearance: none;
    background: var(--switch-track-background);
    outline: none;
    border-radius: 20px;
    transition: 0.7s;
  }

  input:checked[type="checkbox"] {
    background: rgb(var(--color-default-green));
  }

  input[type="checkbox"]:before {
    content: '';
    position: absolute;
    width: 20px;
    height: 20px;
    border-radius: 20px;
    top: 0;
    left: 0;
    background: var(--switch-handle-background);
    transform: scale(1.2);
    transition: .3s ease-in-out;
  }

  input:checked[type="checkbox"]:before {
    left: 20px;
  }

  &.disabled {
    pointer-events: none;
    opacity: 0.5;
  }
}
