.confirmationControls {
  display: flex;
  justify-content: space-between;
  font-size: var(--font-size-s);
  max-width: 70px;
}

.confirmationControls button {
  display: flex;
  align-items: center;
  gap: 8px;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 5px 10px;
  background-color: transparent;

  &.red {
    color: rgb(var(--color-error));
  }
}

.confirmationControls button:hover {
  background-color: rgba(255, 255, 255, 0.1);
  cursor: pointer;
}

