.container {
  position: relative;
  width: calc(100% - 24px);
  height: calc(100% - 24px);
  background-color: rgb(var(--color-mid-grey));
  overflow: hidden;
  margin: 12px;
  border-radius: 5px;

  &.hidden {
    display: none;
  }
}

.resizableContainer {
  width: 100%;
  height: 100%;
  position: relative;
}

.spinner {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 9999;
}

.canvas {
  position: absolute;
  width: 100%;
  height: 100%;
}

.pointCloudLabel {
  font-size: var(--font-size-s);
  background-color: var(--ruler-label-background);
  color: var(--ruler-label-text-color);
  border-radius: 12px;
  padding: 3px 12px;
  cursor: default;
}

.roadKerbLabel {
  font-weight: bold;
}

.marker {
  border: 1px solid white;
  height: 16px;
  width: 16px;
  border-radius: 50%;

  .innerCircle {
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    height: 8px;
    width: 8px;
    border-radius: 50%;
  }
}
