.button {
  appearance: none;
  color: inherit;
  background: transparent;
  border: none;
  outline: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  text-align: left;
}

.button svg path {
  stroke: var(--text-faded);
}

.icons {
  margin-left: 10px;
}

.icons div + div {
  margin-top: -6px;
  margin-left: -0.5px;
}
