.tag {
  padding: 3px 10px;
  border-radius: 100px;
  display: inline-block;
}

.tag + .tag {
  margin-left: 6px;
}

.secondary {
  background: var(--tag-secondary-background);
  color: var(--tag-secondary-text);
}
