.reportsContainer {
  margin: auto;
  width: 210mm;
}

@media print {
  .pagebreak {
    page-break-before: always;
    position: relative;
  }
  .pagebreak .footer {
    position: absolute;
    bottom: -28mm;
  }
}

.pagebreak {
  margin-bottom: 100px;
  width: 210mm;
  height: 277mm;
}

.pagebreak > :nth-child(2) {
  border-bottom: none;
}

.header {
  padding: 15px 70px 30px 15px;
}

.footer {
  padding: 15px;
}

.header {
  border-bottom: 1px solid rgb(206, 215, 212);
}

.header .reportDescription .organization {
  display: flex;
  align-items: center;
  margin-bottom: 1.5rem;
  font-size: 1.25rem;
}

.header .reportDescription .organization img {
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  margin-right: 0.75rem;
}

.header .reportDescription .description {
  display: flex;
  justify-content: space-between;
  font-size: var(--font-size-xs);
}

.header .reportDescription .description .namesContainer {
  flex-basis: 36%;
  overflow: hidden;
}

.header .reportDescription .names svg {
  margin-right: 0.625rem;
  flex-shrink: 0;
}

.header .reportDescription .names {
  display: flex;
  align-items: center;
  margin-bottom: 0.25rem;
}

.header .reportDescription .names > div {
  display: flex;
  align-items: center;
  text-overflow: ellipsis;
}

.header .reportDescription .names strong {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.header .reportDescription .label strong {
  font-size: 0.563rem;
  text-transform: uppercase;
  display: block;
}

.header .reportDescription .label span {
  display: block;
  font-size: var(--font-size-xs);
}

.header .reportDescription .label {
  display: flex;
  flex-direction: column;
  gap:  9px;
}

.footer {
  display: flex;
  justify-content: space-between;
  font-size: var(--font-size-xs);
  width: 100%;
  color: rgb(91, 113, 121);
}

.footer a {
  text-decoration: none;
}