.passwordErrors {
  font-size: var(--font-size-s);
  margin-bottom: 15px;
}

.passwordErrorList {
  list-style: none;
  margin: 0;
  padding: 0;
}

.passwordErrorList li + li {
  margin-top: 5px;
}

.passwordErrorListIcon {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin-right: 6px;
}

.passwordErrorListIcon svg path {
  fill: var(--text-primary);
}

.passwordErrorListIconError svg path {
  fill: var(--text-error);
}
