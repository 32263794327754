.container {
  padding: 18px 18px 0 8px;
  background-color: rgb(var(--color-mid-grey));
  border-radius: var(--panel-border-radius);

  &.overFlowHidden {
    overflow: hidden;
  }

  .arrowIcon {
    vertical-align: middle;
    margin-right: 10px;
  }

  h2 {
    margin: 0;
    padding-bottom: 18px;
    cursor: pointer;
    padding-left: 5px;
    font-weight: 600;

    span.activeHeader {
      color: var(--accent-tertiary);
    }
  }

  .open {
    overflow-y: auto;
    max-height: calc(100% - 80px);
    width: 100%;
    padding-bottom: 20px;
  }

  .closed {
    overflow-y: hidden;
  }

  .contentContainer {
    padding-left: 32px;

    &::-webkit-scrollbar-track {
      margin-bottom: 55px;
    }
  }
}

.header {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 10px;
  padding: 0 20px 20px 10px;

  .tmsCategory {
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px;
    width: 40px;
    background-color: var(--accent-tertiary);
    color: black;
    border-radius: 50%;
  }

  &.collapsedView {
    grid-template-columns: 1fr 1fr;

    @media only screen and (max-width: 1460px) {
      grid-template-columns: 1fr;
    }
  }

  @media only screen and (max-width: 1220px) {
    grid-template-columns: 1fr 1fr;
  }

  p {
    margin: 10px 0;
  }

  h4 {
    font-size: var(--font-size-xs);
    color: var(--text-grey);
    line-height: normal;
    font-weight: normal;
    text-transform: uppercase;
    margin: 0;
    white-space: nowrap;
  }

  &.lessPadding {
    padding: 0 20px 0 0;
  }
}

.content {
  padding: 0 25px 40px 10px;
}

.inventoryContent {
  max-height: 0;
  opacity: 0;
  transition: max-height .3s ease-in-out;
  display: none;

  &.openContent {
    display: block;
    overflow: visible;
    padding: 0 25px 40px 10px;
    opacity: 1;
    max-height: 1500px;
  }

  .groupHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 0;
    font-size: var(--font-size-l);

    .eyeContainer {
      margin-left: 5px;
      display: flex;
      align-items: center;
      cursor: pointer;

      svg {
        width: 22.5px;
        height: 15px;
      }
    }

    .editableTitle {
      height: 30px;
      display: flex;
      align-items: center;
      font-size: var(--font-size-l);
    }

    .cancelAndSave {
      display: flex;
      align-items: center;
      gap: 8px;

      button {
        width: 65px;
        height: 30px;
        font-size: 13px;
        font-weight: 700;
      }
    }
  }
}

.flexContainer {
  display: flex;
  flex-wrap: wrap;
  width: 100%;

  .singleColumn {
    width: 100%;
    display: block;
  }

  .accordionPairsContainer {
    display: flex;
    width: 100%;
    gap: 30px;
  }

  .doubleColumns {
    &.dataGroupGrid {
      width: 50%;
      display: block;
    }
  }

  .header {
    flex-shrink: 0;
    width: 100%;
  }
}

.healthAndVitalityContainer {
  margin: 0 10px;
}

.dataGroup {
  margin: 30px 0;

  .observationHeader {
    font-size: var(--font-size-l);
  }

  .dropdownField {
    padding: 5px;
    min-width: 80px;
    margin-left: auto;
    width: fit-content;
    background-color: var(--panel-light);
  }

  .menuClassname {
    background-color: var(--panel-dark-new-details);
    margin-left: auto;
    width: fit-content;
    min-width: 80px;
    left: unset;
    right: 0;
    padding-left: 0;
    padding-right: 0;
    border-top: 1px solid var(--panel-border);

    > :hover {
      background-color: var(--panel-border);
    }
  }

  .openClassname {
    background-color: var(--panel-dark-new-details);
  }

  .dataGroupGrid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 30px;

    &.singleColumn {
      grid-template-columns: 1fr;
    }

    &.benefitsData {
      @media only screen and (max-width: 1280px) {
        grid-template-columns: 1fr;
      }
    }

    .berlin {
      div.valueContainer {
        width: 100%;
        margin-left: 0;
      }
    }

    .field {
      border-bottom: 1px solid var(--panel-border_transparent);
      display: flex;
      gap: 12px;
      justify-content: space-between;
      align-items: center;
      padding: 5px 0;

      &.noBorder {
        border-bottom: none;
      }

      div.splittedInput {
        display: flex;
        flex-direction: column;
        gap: 8px;
      }

      &.environmentInputField {
        border-bottom: none;
        padding: 4px 10px;
      }

      span.unit {
        width: 24px;
        display: flex;
        align-items: center;
        font-size: var(--font-size-s);
        margin-left: 3px;
      }

      .valueContainer {
        display: flex;
        gap: 8px;
        width: 50%;
        justify-content: end;
        align-items: center;

        &.clickable {
          cursor: pointer;
        }

        p {
          display: block;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          max-width: 10vw;
        }

        .button {
          padding: 5px;
          height: fit-content;
          align-self: center;
        }

        .editingInput {
          padding: 4px 6px;
          background-color: var(--panel-light);

          input {
            text-align: end;
          }

          /* Chrome, Safari, Edge, Opera */
          input::-webkit-outer-spin-button,
          input::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
          }

          /* Firefox */
          input[type=number] {
            -moz-appearance: textfield;
          }

          &.safetyFactor {
            width: 80px;
          }
        }

        .editingNumberInput {
          width: 80px;
          display: flex;
        }
      }
    }

    p {
      margin: 8px 0;
      font-size: var(--font-size-s);
    }

    p.label {
      color: var(--text-grey);
      white-space: nowrap;
    }

    p.value {
      display: flex;
      justify-content: center;
      min-width: 30px;
    }

    p.fixedWith {
      width: 60px;
    }

    p:not(.label) {
      text-align: end;
      font-weight: 600;
    }

    .valueContent {
      position: relative;
    }

    .chartContainer {
      position: fixed;
      display: none;
      padding: 15px;
      background-color: rgb(58, 72, 77);
      width: 230px;
      max-height: 170px;
      box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.25);
      border-radius: 7px;
      z-index: 9999;

      h5 {
        margin: 0 0 15px 0;
        text-align: center;
        font-weight: 600;
      }
    }

    .valueContent:hover .chartContainer {
      display: block;
    }
  }
}


span.noData {
  font-size: var(--font-size-s);
  font-weight: unset;
  color: var(--text-ternary);
}

.diseases {
  font-weight: 400;
  padding: 12px;
}

.lightBackground {
  color-scheme: dark;
  background-color: var(--panel-light);

  > span {
    padding: 5px;
  }
}

.eyeIcon {
  opacity: 0.6;

  &:hover {
    opacity: 1;
  }
}

.benefitsSubtitle {
  margin-top: 32px;
  margin-bottom: 6px;
  font-size: var(--font-size-l);
  font-weight: 500;
}
